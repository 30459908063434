import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import axios from 'axios';
import General from 'helper/general.js';
import GridItem from 'components/Grid/GridItem.jsx';
import GridContainer from 'components/Grid/GridContainer.jsx';
import Card from 'components/Card/Card.jsx';
import CardBody from 'components/Card/CardBody.jsx';
import Loader from 'components/loader.jsx';
import { Redirect } from 'react-router';
import { Button, Snackbar, TextField, Typography } from '@material-ui/core/';
import ContactUsStyle from 'assets/jss/contactUs.jsx';

const validateEmail = (email) => {
  let re = /\S+@\S+\.\S+/;
  return !re.test(String(email).toLowerCase());
}


class User extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      loading: true,
      password: '',
      password_confirmation: '',
      mode: 'login',
      snackbar_open: false,
      snackbar_message: '',
      redirect: false,
      password_reset_allow: true,
      password_reset_email: null,
      previousRoute: null
    };
  }

  componentDidMount() {
    const brand = General.getBrandData();
    axios({
      method: "GET",
      url:
        General.serverURL +
        "address_validator/api/user_count?brand_id=" +
        brand.id,
    }).then((result) => {
      this.setState({
        loading: false,
        mode: result.data ? "login" : "create",
        previousRoute: General.localStorageAvailable()
          ? localStorage.getItem("address_validator_previous_route")
          : "/order_history",
      });
    });
  }

  login(e) {
    e.preventDefault();

    let primaryUser = undefined;

    if (General.localStorageAvailable() && this.state.previousRoute === "/settings") {
      primaryUser = localStorage.getItem("address_validator_primary_user");

      if (primaryUser !== this.state.email) {
        this.setState({
          snackbar_open: true,
          snackbar_message: "Please enter primary user email",
        });

        return;
      }
    }

    this.setState({ loading: true });
    const brand = General.getBrandData();
    axios({
      method: 'POST',
      url: General.serverURL + 'address_validator/api/user_login',
      data: {
        brand_id: + brand.id,
        email: this.state.email,
        password: this.state.password
      }
    })
      .then((result) => {
        General.storeAVjwt(result.data);

        if (this.state.previousRoute === "/settings") {
          this.setState({ loading: false, mode: "create", email: '', password: '' });
        } else {
          this.setState({ loading: false, redirect: true });
        }
      })
      .catch((err) => this.setState({ loading: false, password: '', snackbar_open: true, snackbar_message: 'Invalid email/password' }))
  }

  create(e) {
    e.preventDefault();
    this.setState({ loading: true });
    const brand = General.getBrandData();
    axios({
      method: 'POST',
      url: General.serverURL + 'address_validator/api/user_create',
      data: {
        brand_id: + brand.id,
        email: this.state.email,
        password: this.state.password
      }
    })
    .then((result) => {
      General.storeAVjwt(result.data);
      this.setState({ loading: false, redirect: true });
    })
    .catch((err) => this.setState({ loading: false, password: '', snackbar_open: true, snackbar_message: 'Please try again' }))
  }

  resetPassword() {
    if (!this.state.email) return;

    const brand = General.getBrandData();
    this.setState({ password_reset_allow: false });

    axios({
      method: 'POST',
      url: General.serverURL + 'address_validator/api/send_user_password_link',
      data: {
        id: brand.id,
        name: brand.name,
        email: this.state.email
      }
    })
    .then((result) => this.setState({ password_reset_email: result.data }))
    .catch((err) => this.setState({ loading: false, password: '', snackbar_open: true, snackbar_message: 'Please try again' }))
  }

  snackBar() {
    return (
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={this.state.snackbar_open}
        autoHideDuration={3000}
        onClose={() => this.setState({ snackbar_open: false })}
        message={<span id='message-id'>{this.state.snackbar_message}</span>}
      />
    );
  }

  render() {
    const { classes } = this.props;

    if(this.state.loading)
      return <Loader />;
    else if(this.state.redirect)
      return <Redirect to={this.state.previousRoute} />;
    else if(this.state.mode === 'login')
      return (
        <GridContainer justifyContent='center'>
          {this.snackBar()}
          <GridItem md={6} lg={4} xl={3} style={{ marginTop: 50 }}>
            <Card>
              <CardBody className={classes.center}>
                <Typography variant='h6' className={classes.headingCapitalize}>Please login</Typography>
                {
                  this.state.previousRoute === '/settings' ?
                    <p>as primary user to create account</p>
                    :
                    null
                }
                <form onSubmit={(e) => this.login(e)}>
                  <GridItem>
                    <TextField
                      id='email'
                      type='email'
                      label='Email'
                      value={this.state.email}
                      onChange={(event) => this.setState({ email: event.target.value })}
                      margin='normal'
                      fullWidth={true}
                    />
                  </GridItem>
                  <GridItem>
                    <TextField
                      id='password'
                      type='password'
                      label='Password'
                      value={this.state.password}
                      onChange={(event) => this.setState({ password: event.target.value })}
                      margin='normal'
                      fullWidth={true}
                    />
                  </GridItem>
                  <br />
                  <GridItem>
                    <Button type='submit' variant='contained' color='primary' disabled={validateEmail(this.state.email) || this.state.password === ''} onClick={(e) => this.login(e)} fullWidth={true}>
                      Login
                    </Button>
                    {
                      this.state.password_reset_allow
                      ?
                      <p onClick={() => this.resetPassword()} style={{ cursor: 'pointer' }}>Forgot your password?</p>
                      :
                      null
                    }
                    {
                      this.state.password_reset_email
                      ?
                      <p>Password reset link has been sent to <b>{this.state.password_reset_email}</b></p>
                      :
                      null
                    }
                    {/* <p onClick={() => this.setState({ mode: 'create' })} style={{ cursor: 'pointer' }}>Create Account</p> */}
                  </GridItem>
                </form>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      );
    else if(this.state.mode === 'create')
      return (
        <GridContainer justifyContent='center'>
          {this.snackBar()}
          <GridItem md={6} lg={4} xl={3} style={{ marginTop: 50 }}>
            <Card>
              <CardBody className={classes.center}>
                <Typography variant='h6' className={classes.headingCapitalize}>Create user account</Typography>
                <p>Required to make changes and to view order history</p>
                <form onSubmit={(e) => this.create(e)}>
                  <GridItem>
                    <TextField
                      id='email'
                      type='email'
                      label='Email'
                      value={this.state.email}
                      onChange={(event) => this.setState({ email: event.target.value })}
                      margin='normal'
                      fullWidth={true}
                    />
                  </GridItem>
                  <GridItem>
                    <TextField
                      id='password'
                      type='password'
                      label='Password'
                      value={this.state.password}
                      onChange={(event) => this.setState({ password: event.target.value })}
                      margin='normal'
                      fullWidth={true}
                    />
                  </GridItem>
                  <GridItem>
                    <TextField
                      id='password_confirmation'
                      type='password'
                      label='Confirm password'
                      value={this.state.password_confirmation}
                      onChange={(event) => this.setState({ password_confirmation: event.target.value })}
                      margin='normal'
                      fullWidth={true}
                    />
                  </GridItem>
                  <br />
                  <GridItem>
                    <Button type='submit' variant='contained' color='primary' disabled={validateEmail(this.state.email) || this.state.password === '' || this.state.password_confirmation === '' || this.state.password !== this.state.password_confirmation} onClick={(e) => this.create(e)} fullWidth={true}>
                      Create account
                    </Button>
                    {/* <p onClick={() => this.setState({ mode: 'login' })} style={{ cursor: 'pointer' }}>Log in</p> */}
                  </GridItem>
                </form>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      );
  }
}

export default withStyles(ContactUsStyle)(User);
