import React from 'react';
import { NavLink } from 'react-router-dom';
import withStyles from '@material-ui/core/styles/withStyles';
import _ from 'lodash';
import axios from 'axios';
import General from 'helper/general.js';
import GridItem from 'components/Grid/GridItem.jsx';
import GridContainer from 'components/Grid/GridContainer.jsx';
import Card from 'components/Card/Card.jsx';
import CardHeader from 'components/Card/CardHeader.jsx';
import CardBody from 'components/Card/CardBody.jsx';
import { Fab, Paper, TextField, Typography } from '@material-ui/core/';
import ChangeIcon from '@material-ui/icons/Refresh';
import PauseIcon from '@material-ui/icons/Pause';
import ResumeIcon from '@material-ui/icons/PlayArrow';
import SettingsStyles from 'assets/jss/settings.jsx';
import Data from 'helper/festive_decorations_data.js';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab/';
import AdminInfo from '../../components/adminInfo.jsx';
import Snackbar from '../../components/snackBar.jsx';
import Loader from 'components/loader.jsx';

let updateCustomTextTimeout;


class Settings extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      brand: null
    };
  }

  componentDidMount() {
    const brand = General.getFDBrandData();
    this.setState({ brand: brand, loading: false });
  }

  saveChanges(title, brand) {
    axios({
      method: 'PUT',
      url: General.serverURL + 'festive_decorations/api/brand_attributes',
      data: brand,
      headers: { Authorization: `Bearer ${General.getJWT()}` }
    })
    .then((result) => {
      this.setState({ open: true, alert_type: 'success' });
      General.storeFDBrandData(brand);
    })
    .catch((err) => this.setState({ open: true, alert_type: 'error' }));
  }

  setBrandAttribute(title, attribute, value) {
    let brand = {...this.state.brand};
   
    if(brand[attribute] !== value) {
      brand[attribute] = value;
      this.setState({ brand });

      if(attribute === 'subdomain') {
        clearTimeout(updateCustomTextTimeout);
        updateCustomTextTimeout = setTimeout(() => this.saveChanges(title, brand), 1500);
      }
      else 
       this.saveChanges(title, brand); 
    }
  }

  createToggle(inputData, title, key, description) {
    return (
      <GridItem xs={10} sm={10} md={4}>
        <Card>
          <CardHeader color='primary'>
            <h3 className={this.props.classes.cardTitleWhite}>{title}</h3>
          </CardHeader>
          <CardBody>
            <p className={this.props.classes.cardDescription}>{description}</p>
            <div className={this.props.classes.toggleContainer}>
              <ToggleButtonGroup exclusive size='small'>
                {
                  _.map(inputData, (data) => (
                    <ToggleButton 
                      key={data.value} 
                      value={data.value} 
                      selected={this.state.brand[key] === data.value} 
                      onClick={() => this.setBrandAttribute(title + ' updated', key, data.value)}
                    >
                      {data.display}
                    </ToggleButton>
                  ))
                }
              </ToggleButtonGroup>
            </div>
            {
              this.state.brand.home_page_only === null && key === 'home_page_only'
              ?
              <div>
                <p className={this.props.classes.cardDescription}>What page do you want to display the animations? Specify the subdomain or XXXXXX in http://{this.state.brand.domain}/<b>XXXXXX</b>.</p>
                <TextField
                  id='subdomainInputField'
                  label='Subdomain'
                  value={this.state.brand.subdomain || ''}
                  placeholder={'Subdomain'}
                  onChange={(event) => this.setBrandAttribute('Subdomain', 'subdomain', event.target.value)}
                  className={this.props.classes.textField}
                  margin='normal'
                  fullWidth={true}
                />
              </div>
              :
              null
            }
          </CardBody>
        </Card>
      </GridItem>
    );
  }

  renderIcons() {
    if(this.state.brand.symbol) {
      return (
        <div>
          {_.map(this.state.brand.symbol.split(';'), (symbol) => (
            symbol.indexOf('.png') !== -1 
            ? 
            <img key={symbol} height={40} src={`https://d38mokmrp3q1x6.cloudfront.net/icons/${symbol}`} alt={symbol} />
            :
            <i key={symbol} className={ 'fa fa-3x fa-' + symbol } style={{ color: this.state.brand.color }} />
          ))}
        </div>
      )
    }
  }


  render() {
    if(this.state.loading)
      return <Loader />;
    else
    return (
      <div>
        <Snackbar
          alert_type={this.state.alert_type}
          close={() => this.setState({ open: false, alert_type: null })}
          open={this.state.open}
        />
        <AdminInfo 
          cb={(type) => this.setState({ open: true, alert_type: type })}
          brandData={General.getFDBrandData()}
          app='festive_decorations'
        />
        {
          this.state.brand.number_symbols > 0
          ?
          <GridContainer justify='center'>
            {this.createToggle(Data.number_symbols, 'Number of icons', 'number_symbols', 'How many icons do displayed at once?')}
            {this.createToggle(Data.speed, 'Animation speed', 'speed', 'How fast do you want the animations?')}
            {this.createToggle(Data.delay, 'Delay until start of animation', 'delay', 'Specify the amount of delay before the animations start.')}
            {this.createToggle(Data.font_size, 'Icon size', 'font_size', 'Specify how big the icons should be.')}
            {this.createToggle(Data.duration, 'Duration of animation', 'duration', 'Specify how long the animations should last before they stop.')}
            {this.createToggle(Data.home_page_only, 'Show animation', 'home_page_only', 'Specify which pages the animations should appear on.')}
            <GridItem xs={12} sm={12} md={12}>
              <div className={this.props.classes.center}>
                <Typography align='center' variant='h5' paragraph={true}>Currently selected icons:</Typography>
                {this.renderIcons()}
              </div>
            </GridItem>
            <GridItem xs={12} sm={12} md={12}>
              <div className={this.props.classes.center} style={{ marginTop: 20 }}>
                <NavLink to='/festive_decorations_icons'>
                  <Fab variant='extended' aria-label='Change' color='primary' className={this.props.classes.button}>
                    <ChangeIcon className={this.props.classes.extendedIcon} />
                    Change Icons
                  </Fab>
                </NavLink>
                <Fab variant='extended' aria-label='Pause' color='secondary' className={this.props.classes.button} onClick={() => this.setBrandAttribute('Animations paused', 'number_symbols', 0)}>
                  <PauseIcon className={this.props.classes.extendedIcon} />
                  Pause Animations
                </Fab>
              </div>
            </GridItem>
          </GridContainer>
          :
          <GridContainer justify='center'>
            <GridItem xs={10} sm={10} md={5}>
              <Paper className={this.props.classes.pausedHero}>
                <Typography align='center' variant='h4' paragraph={true}>Animations have been paused</Typography>
                <div className={this.props.classes.center}>
                  <Fab variant='extended' aria-label='Resume' color='primary' className={this.props.classes.button} onClick={() => this.setBrandAttribute('Animations resumed', 'number_symbols', 50)}>
                    <ResumeIcon className={this.props.classes.extendedIcon} />
                    Resume animations
                  </Fab>
                </div>
              </Paper>
            </GridItem>
          </GridContainer>
        }
      </div>
    );
  }
}

export default withStyles(SettingsStyles)(Settings);
