import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';


class Loader extends React.Component {
  render() {
    return (
      <div style={{ marginTop: this.props.marginTop || 150, marginBottom: this.props.marginBottom || 0, textAlign: 'center' }}>
        <CircularProgress size={85} />
        <h3>{this.props.message || 'Loading...'}</h3>
      </div>
    );
  }
};


export default Loader;