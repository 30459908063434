import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import _ from 'lodash';
import General from 'helper/general.js';
import GridItem from 'components/Grid/GridItem.jsx';
import GridContainer from 'components/Grid/GridContainer.jsx';
import { Checkbox, FormControlLabel, Switch, TextField } from '@material-ui/core/';
import Card from 'components/Card/Card.jsx';
import CardHeader from 'components/Card/CardHeader.jsx';
import CardBody from 'components/Card/CardBody.jsx';
import Loader from 'components/loader.jsx';
import FormsStyles from 'assets/jss/forms.jsx';
import MoreInfo from '../../components/moreInfo.jsx';
import Snackbar from '../../components/snackBar.jsx';
import FormButton from '../../components/formButton.jsx';
import ColorPicker from '../../components/colorPicker.jsx';
import DescriptionBox from 'components/descriptionBox.jsx';


const hideFieldCSS = '#address_display > table > tbody > tr:nth-child(2) { display: none; } #address_form > div:nth-child(2) { display: none; }';

const generalLabels = [
  { key: 'formSubtitle', label: 'Form subtitle', placeholder: 'Current shipping address:' },
  { key: 'formFooter', label: 'Form footer', placeholder: 'Please reach out to us if you need anything else!' },
  { key: 'formConfirmedMessage', label: 'Address confirmed message', placeholder: 'Order has been confirmed!' },
  { key: 'formUpdatedMessage', label: 'Address updated message', placeholder: 'Order has been updated!' },
  { key: 'update_link_email', label: 'Contact email for customers with questions', placeholder: '' },
  { key: 'formConfirmationButton', label: 'Customize text for Go to Order Confirmation page button', placeholder: '' }
];

const updateLabels = [
  { key: 'formUpdateTitle', label: 'Update form title', placeholder: 'Please update shipping address' },
  { key: 'formUpdateBackButton', label: 'Update form back button', placeholder: 'Go back' },
  { key: 'formUpdateProceedButton', label: 'Update form proceed button', placeholder: 'Update shipping address' }
];

const invalidLabels = [
  { key: 'formInvalidTitle', label: 'Invalid address form title', placeholder: 'Is this shipping address correct?' },
  { key: 'formInvalidUpdateButton', label: 'Invalid address form update button', placeholder: 'No, I will update address' },
  { key: 'formInvalidConfirmButton', label: 'Invalid address form confirm button', placeholder: 'Yes, address is correct' }
];

const missingLabels = [
  { key: 'formMissingAptTitle', label: 'Missing apt # form title', placeholder: 'Does the shipping address have an apt or unit #?' },
  { key: 'formMissingUpdateButton', label: 'Missing apt # form update button', placeholder: 'Yes, I will specify unit #' },
  { key: 'formMissingConfirmButton', label: 'Missing apt # form confirm button', placeholder: 'No, address is correct' }
];

const modalLabels = [
  { key: 'modalTitle', label: 'Modal title', placeholder: 'Are you sure the address is correct?' },
  { key: 'modalUpdateButton', label: 'Modal update button', placeholder: 'No, I will update it' },
  { key: 'modalConfirmButton', label: 'Modal confirm button', placeholder: 'Yes, it is correct' }
];

const addressFields = [
  { key: 'fieldCompany', label: 'company label', placeholder: 'company' },
  { key: 'fieldAddress1', label: 'address_1 label', placeholder: 'address_1' },
  { key: 'fieldAddress2', label: 'address_2 label', placeholder: 'address_2' },
  { key: 'fieldCity', label: 'city label', placeholder: 'city' },
  { key: 'fieldState', label: 'state label', placeholder: 'state' },
  { key: 'fieldZip', label: 'zip label', placeholder: 'zip' },
  { key: 'fieldCountry', label: 'country label', placeholder: 'country' }
];


class Forms extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      customization: null,
      openDialog: false,
      customize_email_link: false,
      loading: true
    };
  }

  componentDidMount() {
    const brand = General.getBrandData();
    this.setState(Object.assign(
      { loading: false, form_css: brand.form_css || '' },
      brand.customization, 
      _.pick(brand, ['customEmailLink', 'customize_email_link', 'update_link_email']),
    ));
  }

  updateState(key, value) {
    let newState = this.state;
    newState[key] = value;
    newState.open = false;
    this.setState(newState);
  }

  changeText(event, key, label) {
    this.updateState(key, event.target.value);

    General.changeText(
      event,
      key,
      (type) => this.setState({ open: true, alert_type: type })
    );
  }

  toggleOption(key, type) {
    this.updateState(key, !this.state[key]);

    General.setBrandValue(
      key,
      this.state[key], 
      (type) => this.setState({ open: true, alert_type: type })
    );
  }

  changeColor(color, key) {
    this.updateState(key, color.hex);
    General.changeColor(color, key, (type) => this.setState({ open: true, alert_type: type }));
  }

  hideShowAptLine(value) {
    let currentCSS = this.state.form_css;
    let newCSS = value.target.checked ? currentCSS + hideFieldCSS : currentCSS.replace(hideFieldCSS, '');
    this.setState({ form_css: newCSS });
    this.changeText({ target: { value: newCSS }}, 'form_css');
  }


  render() {
    const { classes } = this.props;

    if(this.state.loading)
      return <Loader />;
    else
    return (
      <div>
        <Snackbar
          alert_type={this.state.alert_type}
          close={() => this.setState({ open: false, alert_type: null })}
          open={this.state.open}
        />
        <GridContainer justifyContent='center'>
          <GridItem xs={12} md={10}>
            <DescriptionBox 
              classes={classes}
              content='These forms allow customers to update the shipping address <i>after</i> checkout when they proceed with addresses that cannot be validated. They are accessible via the Thank You page after checkout or on the email notifications.'
              title='What are these forms?'
            />
          </GridItem>
          <GridItem xs={12} md={10} lg={4}>
            <Card>
              <CardHeader color='primary'>
                <h3 className={classes.cardTitleWhite}>Customizations</h3>
              </CardHeader>
              <CardBody>
                <GridContainer justifyContent='center'>
                  <GridItem xs={12}>
                    <p className={classes.cardDescription}>Color of title bar</p>
                    <ColorPicker 
                      color={this.state.formTitleBlockColor || '#377dff'} 
                      onChangeComplete={ (color) => this.changeColor(color, 'formTitleBlockColor') } 
                    />
                  </GridItem>
                  <GridItem xs={12}>
                    <p className={classes.cardDescription}>Message to display when the link has expired</p>
                    <TextField
                      value={this.state.formExpiredMessage || 'Please contact the store directly if you need to make changes to the order.'}
                      onChange={(event) => this.changeText(event, 'formExpiredMessage')}
                      className={classes.textField}
                      margin='normal'
                      fullWidth={true}
                    />
                  </GridItem>
                  <GridItem xs={12}>
                    <div className={classes.center}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={this.state.form_css.includes(hideFieldCSS)}
                            onChange={(value) => this.hideShowAptLine(value)}
                            name='hideAptField'
                            color='primary'
                          />
                        }
                        label='Hide apartment line field'
                      />
                    </div>
                  </GridItem>
                  <GridItem xs={12}>
                    <p className={classes.cardDescription}>Need some additional customization? Specify the custom CSS code here:</p>
                    <TextField
                      rows={5}
                      multiline={true}
                      InputLabelProps={{ shrink: true }}
                      placeholder={`Example: \n* {\n  font-family: Arial, Helvetica, sans-serif;\n  color: #32CD32;\n}`}
                      value={this.state.form_css || ''}
                      onChange={(event) => this.changeText(event, 'form_css')}
                      className={this.props.classes.textField}
                      margin='normal'
                      fullWidth={true}
                    />
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>
          </GridItem>
          <GridItem xs={12} md={10} lg={3}>
            <Card>
              <CardHeader color='primary'>
                <h3 className={classes.cardTitleWhite}>Button to open form</h3>
              </CardHeader>
              <CardBody>
                <GridContainer justifyContent='center'>
                  <GridItem xs={12}>
                    <p className={classes.cardDescription}>
                      The button color is inherited from the <i>Address Prompts</i> tab.
                    </p>
                  </GridItem>
                  <GridItem xs={12}>
                    <TextField
                      label='Button to open address correction form'
                      InputLabelProps={{ shrink: true }}
                      placeholder='Update or confirm address'
                      value={this.state.updateConfirmButton || ''}
                      onChange={(event) => this.changeText(event, 'updateConfirmButton', 'Button to open address correction form')}
                      className={this.props.classes.textField}
                      margin='normal'
                      fullWidth={true}
                    />
                  </GridItem>
                  <GridItem xs={12}>
                    <p className={classes.cardDescription}>
                      Would you like to use your own form?
                      <MoreInfo note='Please ensure the link starts with http:// or https://' />
                    </p>
                    <div className={classes.center}>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={this.state.customize_email_link}
                            onChange={(value) => this.toggleOption('customize_email_link', 'Confirm/update link')}
                            color='primary'
                          />
                        }
                        label={this.state.customize_email_link ? 'Yes' : 'No'}
                      />
                      {
                        this.state.customize_email_link
                        ?
                        <TextField
                          id={alert.labelValue}
                          label={`Customize confirm/update link`}
                          value={this.state.customEmailLink || ''}
                          onChange={(event) => this.changeText(event, 'customEmailLink', 'Email confirm/update link changed to ')}
                          className={classes.textField}
                          margin='normal'
                          fullWidth={true}
                        />
                        :
                        null
                      }
                    </div>
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>
          </GridItem>
          <GridItem xs={12} md={10} lg={3}>
            <Card>
              <CardHeader color='primary'>
                <h3 className={classes.cardTitleWhite}>Preview</h3>
              </CardHeader>
              <CardBody>
                <div className={classes.center}>
                  <p className={classes.cardDescription}>Be sure to refresh the page to see the latest changes</p>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={this.state.testType}
                        onChange={(value) => this.setState({ testType: !this.state.testType })}
                        color='primary'
                      />
                    }
                    label={this.state.testType ? 'Missing apt/suite #' : 'Invalid address'}
                  />
                  <br />
                  <FormButton type={this.state.testType} buttonText={this.state.updateConfirmButton} />
                </div>
              </CardBody>
            </Card>
          </GridItem>
          {
            !this.state.customize_email_link
            ?
            <GridItem xs={12} md={10}>
              <Card>
                <CardHeader color='primary'>
                  <h3 className={classes.cardTitleWhite}>General Fields & Labels</h3>
                </CardHeader>
                <CardBody>
                  <GridContainer justifyContent='center'>
                    {
                      _.map(generalLabels, (data) => (
                        <GridItem xs={12} lg={6} xl={4} key={data.key}>
                          <TextField
                            id={'suggested-' + data.key}
                            label={data.label}
                            multiline={true}
                            rows={1}
                            InputLabelProps={{ shrink: true }}
                            placeholder={data.placeholder}
                            value={this.state[data.key] || ''}
                            onChange={(event) => this.changeText(event, data.key, data.label)}
                            className={this.props.classes.textField}
                            margin='normal'
                            fullWidth={true}
                          />
                        </GridItem>
                      ))
                    }
                  </GridContainer>
                </CardBody>
              </Card>
            </GridItem>
            :
            null
          }
          {
            !this.state.customize_email_link
            ?
            <GridItem xs={12} md={10}>
              <Card>
                <CardHeader color='primary'>
                  <h3 className={classes.cardTitleWhite}>Address Fields</h3>
                </CardHeader>
                <CardBody>
                  <GridContainer justifyContent='center'>
                    {
                      _.map(addressFields, (data) => (
                        <GridItem xs={12} lg={6} xl={4} key={data.key}>
                          <TextField
                            id={'suggested-' + data.key}
                            label={data.label}
                            multiline={true}
                            rows={1}
                            InputLabelProps={{ shrink: true }}
                            placeholder={data.placeholder}
                            value={this.state[data.key] || ''}
                            onChange={(event) => this.changeText(event, data.key, data.label)}
                            className={this.props.classes.textField}
                            margin='normal'
                            fullWidth={true}
                          />
                        </GridItem>
                      ))
                    }
                  </GridContainer>
                </CardBody>
              </Card>
            </GridItem>
            :
            null
          }
          {
            !this.state.customize_email_link
            ?
            <GridItem xs={12} md={10}>
              <Card>
                <CardHeader color='primary'>
                  <h3 className={classes.cardTitleWhite}>Update Form</h3>
                </CardHeader>
                <CardBody>
                  <GridContainer justifyContent='center'>
                    {
                      _.map(updateLabels, (data) => (
                        <GridItem xs={12} lg={6} xl={4} key={data.key}>
                          <TextField
                            id={'suggested-' + data.key}
                            label={data.label}
                            multiline={true}
                            rows={1}
                            InputLabelProps={{ shrink: true }}
                            placeholder={data.placeholder}
                            value={this.state[data.key] || ''}
                            onChange={(event) => this.changeText(event, data.key, data.label)}
                            className={this.props.classes.textField}
                            margin='normal'
                            fullWidth={true}
                          />
                        </GridItem>
                      ))
                    }
                    <GridItem xs={12} lg={6} xl={4}>
                      Color of form back button
                      <ColorPicker 
                        color={this.state.backButtonColor || '#77838f'} 
                        onChangeComplete={ (color) => this.changeColor(color, 'backButtonColor') } 
                      />
                    </GridItem>
                    <GridItem xs={12} lg={6} xl={4}>
                      Color of form proceed button
                      <ColorPicker 
                        color={this.state.proceedButtonColor || '#377dff'} 
                        onChangeComplete={ (color) => this.changeColor(color, 'proceedButtonColor') } 
                      />
                    </GridItem>
                  </GridContainer>
                </CardBody>
              </Card>
            </GridItem>
            :
            null
          }
          {
            !this.state.customize_email_link
            ?
            <GridItem xs={12} md={10}>
              <Card>
                <CardHeader color='primary'>
                  <h3 className={classes.cardTitleWhite}>Invalid Address Form</h3>
                </CardHeader>
                <CardBody>
                  <GridContainer justifyContent='center'>
                    {
                      _.map(invalidLabels, (data) => (
                        <GridItem xs={12} lg={6} xl={4} key={data.key}>
                          <TextField
                            id={'suggested-' + data.key}
                            label={data.label}
                            multiline={true}
                            rows={1}
                            InputLabelProps={{ shrink: true }}
                            placeholder={data.placeholder}
                            value={this.state[data.key] || ''}
                            onChange={(event) => this.changeText(event, data.key, data.label)}
                            className={this.props.classes.textField}
                            margin='normal'
                            fullWidth={true}
                          />
                        </GridItem>
                      ))
                    }
                    <GridItem xs={12} lg={6} xl={4}>
                      Invalid address form update button
                      <ColorPicker 
                        color={this.state.updateButtonColor || '#77838f'} 
                        onChangeComplete={ (color) => this.changeColor(color, 'updateButtonColor') } 
                      />
                    </GridItem>
                    <GridItem xs={12} lg={6} xl={4}>
                      Invalid address form confirm button
                      <ColorPicker 
                        color={this.state.acceptButtonColor || '#377dff'} 
                        onChangeComplete={ (color) => this.changeColor(color, 'acceptButtonColor') } 
                      />
                    </GridItem>
                  </GridContainer>
                </CardBody>
              </Card>
            </GridItem>
            :
            null
          }
          {
            !this.state.customize_email_link
            ?
            <GridItem xs={12} md={10}>
              <Card>
                <CardHeader color='primary'>
                  <h3 className={classes.cardTitleWhite}>Missing Apt # Form</h3>
                </CardHeader>
                <CardBody>
                  <GridContainer justifyContent='center'>
                    {
                      _.map(missingLabels, (data) => (
                        <GridItem xs={12} lg={6} xl={4} key={data.key}>
                          <TextField
                            id={'suggested-' + data.key}
                            label={data.label}
                            multiline={true}
                            rows={1}
                            InputLabelProps={{ shrink: true }}
                            placeholder={data.placeholder}
                            value={this.state[data.key] || ''}
                            onChange={(event) => this.changeText(event, data.key, data.label)}
                            className={this.props.classes.textField}
                            margin='normal'
                            fullWidth={true}
                          />
                        </GridItem>
                      ))
                    }
                    <GridItem xs={12} lg={6} xl={4}>
                      Missing apt # form update button
                      <ColorPicker 
                        color={this.state.updateButtonColor || '#77838f'} 
                        onChangeComplete={ (color) => this.changeColor(color, 'updateButtonColor') } 
                      />
                    </GridItem>
                    <GridItem xs={12} lg={6} xl={4}>
                      Missing apt # form confirm button
                      <ColorPicker 
                        color={this.state.acceptButtonColor || '#377dff'} 
                        onChangeComplete={ (color) => this.changeColor(color, 'acceptButtonColor') } 
                      />
                    </GridItem>
                  </GridContainer>
                </CardBody>
              </Card>
            </GridItem>
            :
            null
          }
          {
            !this.state.customize_email_link
            ?
            <GridItem xs={12} md={10}>
              <Card>
                <CardHeader color='primary'>
                  <h3 className={classes.cardTitleWhite}>Confirmation Modal</h3>
                </CardHeader>
                <CardBody>
                  <GridContainer justifyContent='center'>
                    {
                      _.map(modalLabels, (data) => (
                        <GridItem xs={12} lg={6} xl={4} key={data.key}>
                          <TextField
                            id={'suggested-' + data.key}
                            label={data.label}
                            multiline={true}
                            rows={1}
                            InputLabelProps={{ shrink: true }}
                            placeholder={data.placeholder}
                            value={this.state[data.key] || ''}
                            onChange={(event) => this.changeText(event, data.key, data.label)}
                            className={this.props.classes.textField}
                            margin='normal'
                            fullWidth={true}
                          />
                        </GridItem>
                      ))
                    }
                    <GridItem xs={12} lg={6} xl={4}>
                      Modal update button
                      <ColorPicker 
                        color={this.state.modalUpdateBtnColor || '#77838f'} 
                        onChangeComplete={ (color) => this.changeColor(color, 'modalUpdateBtnColor') } 
                      />
                    </GridItem>
                    <GridItem xs={12} lg={6} xl={4}>
                      Modal confirm button
                      <ColorPicker 
                        color={this.state.modalConfirmBtnColor || '#377dff'} 
                        onChangeComplete={ (color) => this.changeColor(color, 'modalConfirmBtnColor') } 
                      />
                    </GridItem>
                  </GridContainer>
                </CardBody>
              </Card>
            </GridItem>
            :
            null
          }
        </GridContainer>
      </div>
    );
  }
}

export default withStyles(FormsStyles)(Forms);