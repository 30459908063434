import { darkGrayColor, lightGrayColor, cardCategoryWhite, cardTitleWhite } from 'assets/jss/theme.jsx';


const ContactUsStyle = theme => ({
  cardCategoryWhite,
  cardTitleWhite,
  linkBoxes: {
    '&:hover': {
      backgroundColor: lightGrayColor
    }
  },
  cardTitle: {
    color: darkGrayColor,
    fontSize: '16px',
    fontWeight: 'bold',
    textAlign: 'left',
    paddingTop: '21.5px',
    paddingLeft: '120px'
  },
  center: {
    textAlign: 'center'
  },
  headingCapitalize: {
    textTransform: "capitalize",
  }
});

export default ContactUsStyle;