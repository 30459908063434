import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import GridItem from 'components/Grid/GridItem.jsx';
import GridContainer from 'components/Grid/GridContainer.jsx';
import Card from 'components/Card/Card.jsx';
import CardHeader from 'components/Card/CardHeader.jsx';
import CardBody from 'components/Card/CardBody.jsx';
import AppCard from 'components/AppCard/AppCard';
import { 
  cardCategoryWhite, 
  cardTitleWhite
} from 'assets/jss/theme.jsx';
import General from 'helper/general.js';
import axios from 'axios';
import _ from 'lodash';
import Loader from 'components/loader.jsx';

const PowerUpsStyle = theme => ({
  cardCategoryWhite,
  cardTitleWhite
})

const pageFlyAppObj = {
  assets: {
    app_logo_path: 'https://cdn.shopify.com/app-store/listing_images/f85ee597169457da8ee70b6652cae768/icon/CKmsycCOx_YCEAE=.png'
  },
  description: 'Craft sales-boosting pages, no code needed',
  header_text: 'Craft sales-boosting pages, no code needed',
  hrefs: {
    app_store_path: 'https://pagefly.link/grsMtcRW',
    direct_install_link_path: 'https://pagefly.link/grsMtcRW',
    lp_path: 'https://pagefly.io/',
    review_path: 'https://apps.shopify.com/pagefly?#modal-show=ReviewListingModal'
  },
  is_installed: false,
  key: 'pagefly',
  name: 'PageFly Landing Page Builder',
  pricing_description_text: 'Free plan available',
  reviews_count: 7844,
  reviews_rating: '4.9',
  setup_time: '30 minutes',
  weight: 6.5
};


class PowerUps extends React.Component {
  constructor(props) {
    super(props);
    this.state = { loading: true, appObjects: null, appNames: null, appsInstalled: null }
  }

  async componentDidMount() {
    const brand = General.getBrandData();
    const shopData = await axios.get(`https://bigbear.conversionbear.com/shop?shopify_domain=${brand.name}.myshopify.com`);
    const appsData = await axios.get(`https://bigbear.conversionbear.com/app_list?shopify_domain=${brand.name}.myshopify.com&origin_app=address_validator`);

    const appNames = _.keys(appsData.data);
    const appsInstalled = _.keys(shopData.data.installed_apps);
    let appObjects = [];

    appNames.forEach((name) => {
      if(!appsInstalled.includes(name))
        appObjects.push(appsData.data[name])
    })

    appObjects.push(pageFlyAppObj);

    this.setState({
      loading: false,
      appObjects: _.orderBy(appObjects, ['weight'], ['desc']),
    });
  }

  generateAppCard(appObj) {
    return (
      <GridItem sm={12} key={appObj.key}>
        <AppCard data={appObj} />
      </GridItem>
    );
  }

  render() {
    const { classes } = this.props;

    if(this.state.loading)
      return <Loader />;
    else
      return (
        <GridContainer justifyContent='center'>
          <GridItem xs={12} md={10}>
            <Card>
              <CardHeader color='primary'>
                <h3 className={classes.cardTitleWhite}>
                  Power-Ups
                </h3>
              </CardHeader>
              <CardBody className={classes.center}>
                <GridContainer>
                  {this.state.appObjects.map((app) => this.generateAppCard(app))}
                </GridContainer>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      );
  }
}


PowerUps.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(PowerUpsStyle)(PowerUps);