import React from 'react';
import { Snackbar } from '@material-ui/core/';
import { Alert } from '@material-ui/lab/';


class SnackBar extends React.Component {
  message() {
    if(this.props.alert_type === 'success')
      return <Alert severity='success'>{this.props.success_msg || 'Changes saved!'}</Alert>;
    else if(this.props.alert_type === 'error')
      return <Alert severity='error'>{this.props.error_msg || 'Session expired. Please refresh this page and try again.'}</Alert>;
    else
      return null;
  }

  render() {
    return (
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={this.props.open}
        autoHideDuration={4000}
        onClose={() => this.props.close()}
      >
        {this.message()}
      </Snackbar>
    );
  }
};


export default SnackBar;