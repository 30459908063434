import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import _ from 'lodash';
import axios from 'axios';
import { ChromePicker } from 'react-color';
import General from 'helper/general.js';
import GridItem from 'components/Grid/GridItem.jsx';
import GridContainer from 'components/Grid/GridContainer.jsx';
import Card from 'components/Card/Card.jsx';
import CardHeader from 'components/Card/CardHeader.jsx';
import CardBody from 'components/Card/CardBody.jsx';
import { Divider, Typography } from '@material-ui/core/';
import IconsStyles from 'assets/jss/icons.jsx';
import Data from 'helper/festive_decorations_data.js';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab/';
import Snackbar from '../../components/snackBar.jsx';
import Loader from 'components/loader.jsx';


class Icons extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      brand: null,
      selectedBucket: '',
      loading: true
    };
  }

  componentDidMount() {
    const brand = General.getFDBrandData();
    this.setState({ brand: brand, loading: false });
  }

  saveChanges(title, brand) {
    axios({
      method: 'PUT',
      url: General.serverURL + 'festive_decorations/api/brand_attributes',
      data: brand,
      headers: { Authorization: `Bearer ${General.getJWT()}` }
    })
    .then((result) => {
      this.setState({ open: true, alert_type: 'success' });
      General.storeFDBrandData(brand);
    })
    .catch((err) => this.setState({ open: true, alert_type: 'error' }));
  }

  setBrandAttribute(title, attribute, value) {
    let brand = {...this.state.brand};
    if(attribute === 'symbol') {
      let symbolCurrent = brand.symbol.split(';');
      const symbolIndex = symbolCurrent.indexOf(value);

      if(symbolCurrent.length === 1 && symbolIndex !== -1) {}
      else {
        if(symbolIndex !== -1)
          symbolCurrent.splice(symbolIndex, 1);
        else
          symbolCurrent.push(value);
        brand[attribute] = symbolCurrent.join(';');
        this.setState({ brand });
        this.saveChanges(title, brand);
      }
    }
    else {
      if(brand[attribute] !== value) {
        brand[attribute] = value;
        this.setState({ brand });
        this.saveChanges(title, brand);
      }
    }
  }

  determineIconClass(obj) {
    if(this.state.brand.symbol.split(';').indexOf(obj.key.replace('fa-', '')) !== -1)
      return this.props.classes.iconImageActive
    else
      return this.props.classes.iconImage;
  }

  generateIcon(obj) {
    return (
      <GridItem xs={4} sm={4} md={1} key={obj.key} onClick={() => this.setBrandAttribute('Icons updated', 'symbol', obj.key.replace('fa-', ''))}>
        <div className={this.determineIconClass(obj)}>
          <i className={ 'fa fa-3x ' + obj.key } style={{ color: this.state.brand.color }} />
          <br />
          <small>{obj.name}</small>
        </div>
      </GridItem>
    );
  }

  generateImage(obj) {
    return (
      <GridItem xs={4} sm={4} md={1} key={obj.key} onClick={() => this.setBrandAttribute('Icons updated','symbol', obj.key)}>
        <div className={this.determineIconClass(obj)}>
          <img height={40} src={`https://d38mokmrp3q1x6.cloudfront.net/icons/${obj.key}`} alt={obj.key} />
          <br />
          <small>{obj.key.replace('.png', '').replace('image', '_').replace(/_/g, ' ')}</small>
        </div>
      </GridItem>
    );
  }

  filterSortIcons(objs) {
    let icons = _.sortBy(objs, 'bucket');

    if(this.state.selectedBucket)
      icons = _.filter(icons, (img) => img.bucket === this.state.selectedBucket);

    return icons;
  }


  render() {
    if(this.state.loading)
      return <Loader />;
    else
    return (
      <div>
        <Snackbar
          alert_type={this.state.alert_type}
          close={() => this.setState({ open: false, alert_type: null })}
          open={this.state.open}
        />
        <GridContainer justify='center'>
          <GridItem xs={12} sm={12} md={11}>
            <Card>
              <CardHeader color='primary'>
                <h3 className={this.props.classes.cardTitleWhite}>Select multiple icons to spice up your store!</h3>
                <p className={this.props.classes.cardCategoryWhite}>Can't find what you are looking for? Send us an email with the .png file and we'll add it for you!</p>
              </CardHeader>
              <CardBody>
                <GridContainer justify='center'>
                  <GridItem xs={12} sm={12} md={12}>
                    <div className={this.props.classes.toggleContainer}>
                      <ToggleButtonGroup exclusive size='small'>
                        <ToggleButton 
                          key='all' 
                          value={''} 
                          selected={this.state.selectedBucket === ''} 
                          onClick={() => this.setState({ selectedBucket: '' })}
                        >
                          All
                        </ToggleButton>
                        {_.map(_.uniq(_.map(Data.imgs, 'bucket')).sort(), (bucket) => <ToggleButton selected={this.state.selectedBucket === bucket} key={bucket} value={bucket} onClick={ () => this.setState({ selectedBucket: this.state.selectedBucket === bucket ? '' : bucket }) }>{bucket}</ToggleButton>)}
                      </ToggleButtonGroup>
                    </div>
                  </GridItem>
                </GridContainer>
                <GridContainer justify='center'>
                  <GridItem xs={12} sm={12} md={12}>
                    <Typography align='center' variant='h5' paragraph={true}>Multicolored Icons</Typography>
                  </GridItem>
                  {_.map(this.filterSortIcons(Data.imgs), (obj) => this.generateImage(obj))}
                </GridContainer>
                <Divider className={this.props.classes.divider} />
                <GridContainer justify='center'>
                  <GridItem xs={12} sm={12} md={12}>
                    <Typography align='center' variant='h5' paragraph={true}>Monochromatic Icons</Typography>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={3}>
                    <ChromePicker color={this.state.brand.color} disableAlpha={true} onChangeComplete={ (color) => this.setBrandAttribute('Colors updated to ' + color.hex, 'color', color.hex) } />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={9}>
                    <GridContainer justify='center'>
                      {_.map(this.filterSortIcons(Data.icons), (obj) => this.generateIcon(obj))}
                    </GridContainer>
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

export default withStyles(IconsStyles)(Icons);
