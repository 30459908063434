import React from 'react';
import { AppBar, Toolbar, Typography } from '@material-ui/core/';
import General from 'helper/general.js';


class Notification extends React.Component {
  constructor(props) {
    super(props);
    this.state = {}
  }

  componentDidMount() {
    const brand = General.getBrandData();
    this.setState({ display: brand.activated && !brand.configured && brand.shopify_plus });
  }

  render() {
    const styles = {
      display: {
        textAlign: 'center', 
        flexGrow: 1
      }
    };

    if(this.state.display)
      return (
        <AppBar position='relative' style={{ backgroundColor: '#f44336', cursor: 'pointer' }} onClick={() => this.props.openInstructions()}>
          <Toolbar>
            <Typography variant='h6' color='inherit' style={styles.display}>
              <span role='img' aria-label='Alert'>&#128680;</span> Finalize setup by inserting code snippet - Click here to see instructions <span role='img' aria-label='Alert'>&#128680;</span>
            </Typography>
          </Toolbar>
        </AppBar>
      );
    else
      return null;
  }
};


export default Notification;