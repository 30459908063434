import { cardCategoryWhite, cardTitleWhite } from 'assets/jss/theme.jsx';


const FormsStyle = theme => ({
  cardCategoryWhite, cardTitleWhite, cardDescription: {
    fontSize: '16px',
    marginBottom: '0px',
    textAlign: 'center'
  },
  center: {
    textAlign: 'center'
  },
  colorTitle: {
    fontSize: '16px',
    marginTop: '20px',
    textAlign: 'center'
  }
});

export default FormsStyle;