import { cardCategoryWhite, cardTitleWhite } from 'assets/jss/theme.jsx';


const EmailsStyle = theme => ({
  cardCategoryWhite,
  cardTitleWhite,
  cardDescription: {
    fontSize: '16px',
    marginBottom: '0px'
  },
  center: {
    textAlign: 'center'
  },
  chip: {
    marginRight: 5
  }
});

export default EmailsStyle;