import { 
  defaultFont, 
  primaryColor 
} from 'assets/jss/theme.jsx';


const headerLinksStyle = theme => ({
  linkText: {
    zIndex: '4',
    ...defaultFont,
    fontSize: '14px',
    fontWeight: 400,
    margin: '0px',
    marginLeft: '5px'
  },
  buttonLink: {
    [theme.breakpoints.down('xs')]: {
      display: 'flex',
      margin: '10px 15px 0',
      color: 'darkGrayColor',
      width: '-webkit-fill-available',
      '& svg': {
        width: '24px',
        height: '30px',
        marginRight: '15px',
        marginLeft: '-15px'
      },
      '& .fab,& .fas,& .far,& .fal,& .material-icons': {
        fontSize: '24px',
        lineHeight: '30px',
        width: '24px',
        height: '30px',
        marginRight: '15px',
        marginLeft: '-15px'
      },
      '& > span': {
        justifyContent: 'flex-start',
        width: '100%'
      }
    },
    color: 'white',
    '&:hover': {
      color: primaryColor
    },
    padding: 10
  }
});

export default headerLinksStyle;
