import React from 'react';
import ReactDOM from 'react-dom';
import { createBrowserHistory } from 'history';
import { Router, Route, Switch } from 'react-router-dom';
import { unregister as unregisterServiceWorker } from './serviceWorker';
import FestiveDecorations from 'app/festive_decorations.jsx';
import AddressValidatorApp from 'app/address_validator.jsx';
import { StripeProvider } from 'react-stripe-elements';
import { Provider } from '@shopify/app-bridge-react';
import 'assets/css/main.css';
import * as FullStory from '@fullstory/browser';

const DEV = process.env.NODE_ENV === "development"; // localstorage is only available in development since it cannot be used within an iframe

unregisterServiceWorker();

window['_fs_is_outer_script'] = true;
FullStory.init({ orgId: 'o-1GYZ1S-na1' }, ({ sessionUrl }) => console.log(`Started session: ${sessionUrl}`));

// redirect to new address verification page
if(/verify_address/.test(window.location.search))
    window.location = 'https://verify.addressvalidator.com/verify_address' + window.location.search.replace('verify_address/', '');

// determine which app on development
else if(DEV)
  ReactDOM.render(
    <StripeProvider apiKey='pk_test_xwSbJSzSCVYKo1E5NYxgRYMt00TUDVYJtc'>
      <Router history={createBrowserHistory()}>
        <Switch>
          <Route path='/' component={/festive_decorations/.test(window.location.pathname) ? FestiveDecorations : AddressValidatorApp} />
        </Switch>
      </Router>
    </StripeProvider>
  , document.getElementById('root'));

// determine which app on production
else {
  const searchParams = new URLSearchParams(window.location.search);
  const config = {
    apiKey: /festive_decorations/.test(window.location.pathname) ? 'c10fb895fc9c04ead5c4b3a224092efb' : '47694bcc580db734557159405f8a95e2',
    host: searchParams.get('host'),
    forceRedirect: true
  };

  ReactDOM.render(
    <Provider config={config}>
      <StripeProvider apiKey='pk_live_LcmDMb6q6SSQ9tlCyiSztGpC00LT536xmm'>
        <Router history={createBrowserHistory()}>
          <Switch>
            <Route path='/' component={/festive_decorations/.test(window.location.pathname) ? FestiveDecorations : AddressValidatorApp} />
          </Switch>
        </Router>
      </StripeProvider>
    </Provider>
  , document.getElementById('root'));
}


// http://localhost:3001/address_validator?c2hvcD1sZXR1c3NlbGxpdCZhcHA9YWRkcmVzc192YWxpZGF0b3Imand0PWV5SmhiR2NpT2lKSVV6STFOaUlzSW5SNWNDSTZJa3BYVkNKOS5leUpwWkNJNk16RXhMQ0pwWVhRaU9qRTJOekE1T1RZNU56TXNJbVY0Y0NJNk1UWTNNVE0wTWpVM00zMC41czRpenpkLS1rZ0ZlV2xLRTdPTklLREJCUDhrNjRnZ21EV1VHV1p5SzY0Jmhvc3Q9YkdWMGRYTnpaV3hzYVhRdWJYbHphRzl3YVdaNUxtTnZiUzloWkcxcGJnJmFwaT00NzY5NGJjYzU4MGRiNzM0NTU3MTU5NDA1ZjhhOTVlMg
// http://localhost:3001/address_validator?c2hvcD1sZXR1c3NlbGxpdCZhcHA9YWRkcmVzc192YWxpZGF0b3Imand0PWV5SmhiR2NpT2lKSVV6STFOaUlzSW5SNWNDSTZJa3BYVkNKOS5leUpwWkNJNk16RXhMQ0pwWVhRaU9qRTJOREUwTWpnMU1URXNJbVY0Y0NJNk1UY3dORFUwTXpjeE1YMC5IcjdLWXFuMjRyd0ZWVHk5QlJDZWszNXY5MVZEV3BuZFE1ZDd6MExnN3Aw
// http://localhost:3001/address_validator?c2hvcD1sZXR1c3NlbGxpdCZhcHA9YWRkcmVzc192YWxpZGF0b3Imand0PWV5SmhiR2NpT2lKSVV6STFOaUlzSW5SNWNDSTZJa3BYVkNKOS5leUpwWkNJNk16RXhMQ0pwWVhRaU9qRTFPVGM0TnpVek9EZ3NJbVY0Y0NJNk1UWXlPVFF6TWprNE9IMC51U0lrNXlMT3plMnF2MEM3T1ZyY3JqZnUtYldtSk5TRnJKU1l0WldKRHdz
// http://localhost:3001/festive_decorations?c2hvcD1sZXR1c3NlbGxpdCZhcHA9ZmVzdGl2ZV9kZWNvcmF0aW9ucyZqd3Q9ZXlKaGJHY2lPaUpJVXpJMU5pSXNJblI1Y0NJNklrcFhWQ0o5LmV5SnBaQ0k2TkRVekxDSnBZWFFpT2pFMU9UZzVPRFkxT0RFc0ltVjRjQ0k2TVRVNU9UTXpNakU0TVgwLjdoV2RkV3hlQXBZV1kzSHN1WmFDLTBndmpucnNLNzlBYzVtdlk2eTNXb1U=
// http://localhost:3001/festive_decorations?c2hvcD1sZXR1c3NlbGxpdCZhcHA9ZmVzdGl2ZV9kZWNvcmF0aW9ucyZqd3Q9ZXlKaGJHY2lPaUpJVXpJMU5pSXNJblI1Y0NJNklrcFhWQ0o5LmV5SnBaQ0k2TkRVekxDSnBZWFFpT2pFMU9UYzVNRFk1TWpNc0ltVjRjQ0k2TVRZeU9UUTJORFV5TTMwLnBxN1FfTjJ6bDh2cHJMY1l2RnhIb053bnJ5bVRRN1F0bXRyMFlrZ1NnWUU=
// http://localhost:3001/address_validator?verify_address/YnJhbmRfaWQ9MzExJmlkPTUwNjcxMzcxNzAmZW1haWw9dGVzdGluZ0BnbWFpbC5jb20=