/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import { Switch, Route, Redirect } from 'react-router-dom';
import withStyles from '@material-ui/core/styles/withStyles';
import Routes from 'routes/address_validator.jsx';
import axios from 'axios';
import { convertFromRaw, EditorState } from 'draft-js';
import Header from '../components/header.jsx';
import Loader from '../components/loader.jsx';
import Sidebar from '../components/sidebar.jsx';
import Instructions from '../components/instructions.jsx';
import Notification from '../components/notification.jsx';
import General from '../helper/general.js';
import { Helmet } from 'react-helmet';
import moment from 'moment';
import mainStyle from 'assets/jss/main.jsx';
import Prompts from 'views/address_validator/Prompts.jsx';
import Settings from 'views/address_validator/Settings.jsx';
import * as FullStory from '@fullstory/browser';
import AddressRules from 'views/address_validator/AddressRules.jsx';
import ValidationRules from 'views/address_validator/ValidationRules.jsx'

const addressValidatorPage = 'https://addressvalidatorapp.com';


class Main extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      brand: null,
      loading: true,
      mobileOpen: false,
      showInstructions: false,
      checkoutValidationEnabled: 'checkout.liquid',
      view: "Checkout Liquid"
    };
    this.resizeFunction = this.resizeFunction.bind(this);
    this.changeView = this.changeView.bind(this);
  }
  
  resizeFunction() {
    if(window.innerWidth >= 960)
      this.setState({ mobileOpen: false });
  }

  changeView(evt, view) {
    this.setState({ view });
  }

  pullBrandData(shop_name) {
    axios({
      method: 'GET',
      url: General.serverURL + 'address_validator/api/admin_home?shop=' + shop_name,
      headers: { Authorization: `Bearer ${General.getJWT()}` }
    })
    .then((result) => {
      const brand = result.data.brand;

      // Set rich text editor
      brand.custom_email_message = brand.custom_email_message ? EditorState.createWithContent(convertFromRaw(JSON.parse(brand.custom_email_message))) : EditorState.createEmpty();
      
      // Determine if store is Shopify Plus
      brand.shopify_plus = General.shopifyPlus(brand.plan_name) || ['letussellit'].includes(brand.name);
      // brand.shopify_plus = true;

      // Store merchant data
      General.storeBrandData(brand);

      this.setState({ 
        checkoutValidationEnabled: result.data.checkoutValidationEnabled,
        view: result.data.checkoutValidationEnabled === "checkout_ui_extension"
          ? "Checkout UI Extension"
          : "Checkout Liquid",
        paused: brand.disable_thank_you_validate && brand.thank_you_popup,
        showReCharge: !brand.email.includes('@shopify.com') && !['staff_business', 'staff'].includes(brand.plan_name) && brand.subscription_products !== false
      });

      FullStory.identify(`${brand.name}.myshopify.com_addressvalidator`, {
        displayName: brand.store_name,
        email: brand.email,
        status_str: 'active',
        shopId_str: brand.name,
        planName_str: brand.plan_name,
        platform_str: 'shopify',
        appHubId_str: 'roboturk',
        appName_str: 'addressvalidator'
      });

      // Start Intercom
      window.intercomSettings = Object.assign(_.pick(brand, ['admin_email', 'admin_name', 'admin_phone', 'configured', 'ignore', 'review_request_date', 'reviewed', 'scope', 'plan_name']), {
        api_base: 'https://api-iam.intercom.io',
        app_id: 'honm34jo',
        apphub_id: 'address_validator',
        name: brand.store_name,
        email: brand.email,
        company: brand.domain,
        user_id: "AV_" + brand.name,
        created_at: moment(brand.created_at).unix(), 
        moved_prompts: "AV_" + brand.precheckout_popup || "AV_" + brand.validate_pre_checkout,
        type: 'Shopify'
      });

      this.setState({ loading: false, brand: brand });
    })
    .catch((err) => {
      console.log(err);
      console.log('brand fetch error');
      window.location = addressValidatorPage;
    });
  }

  componentDidMount() {
    if(process.env.NODE_ENV === 'development') {
      const shop = 'letussellit';
      const jwt = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MzExLCJpYXQiOjE2NzE2NjIzMjQsImV4cCI6MTczNDc3NzUyNH0.PeVFhMuIUEZIkIsRMBDuMiu4sqs2XVhfPK0iLGg5daw'; // jwt for letussellit with 2 year expiry
      General.setJWT(jwt, shop);
      this.pullBrandData(shop);
    }

    else if(this.props.location.search) {
      const searchParams = new URLSearchParams(window.location.search);
      const shop = searchParams.get('shop').replace('.myshopify.com', '');
      const hmac = searchParams.get('hmac');

      axios({
        method: 'GET',
        url: General.serverURL + 'address_validator/api/fetch_dashboard_jwt?shop=' + shop + '.myshopify.com&hmac=' + hmac,
      })
      .then((result) => {
        const jwt = result.data;

        if(window.top === window.self && window.location.host !== 'localhost:3001')
          window.location = `https://${shop}.myshopify.com/admin/apps/address-validator`;
        else {
          General.setJWT(jwt, shop);
          this.pullBrandData(shop);
        }
      })
      .catch((err) => {
        console.log(err);
        console.log('brand fetch error');
        window.location = addressValidatorPage;
      });
    }
    
    else {
      console.log('no brand data and bad URL');
      window.location = addressValidatorPage;
    }
    
    window.addEventListener('resize', this.resizeFunction);
  }

  componentDidUpdate(e) {
    if (e.history.location.pathname !== e.location.pathname) {
      this.refs.mainPanel.scrollTop = 0;
      if (this.state.mobileOpen) {
        this.setState({ mobileOpen: false });
      }
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resizeFunction);
  }

  render() {
    const { classes, ...rest } = this.props;

    if(this.state.loading)
      return (
        <div className={classes.wrapper}>
          <Helmet>
            <title>Address Validator</title>
          </Helmet>
          <Loader />
        </div>
      );
    else
      return (
        <div className={classes.wrapper}>
          <Helmet>
            <title>Address Validator</title>
          </Helmet>
          <Notification openInstructions={() => this.setState({ showInstructions: true })} />
          <Sidebar
            routes={Routes}
            handleDrawerToggle={() => this.setState({ mobileOpen: !this.state.mobileOpen })}
            closeDrawer={() => this.setState({ mobileOpen: false })}
            open={this.state.mobileOpen}
            brand={this.state.brand}
            app='address_validator'
            paused={this.state.paused}
            togglePause={() => this.setState({ paused: !this.state.paused })}
            showReCharge={this.state.showReCharge}
            {...rest}
          />
          <div className={classes.mainPanel} ref='mainPanel'>
            <Header
              routes={Routes}
              handleDrawerToggle={() => this.setState({ mobileOpen: !this.state.mobileOpen })}
              brand={this.state.brand}
              app='address_validator'
              openInstructions={() => this.setState({ showInstructions: true })}
              {...rest}
            />
            <div className={classes.content}>
              <Switch>
                {Routes.map((prop, key) => (
                  (prop.redirect)
                  ?
                  <Redirect from={prop.path} to={prop.to} key={key} />
                  :
                  (
                    prop.path === '/settings'
                    ?
                    <Route path={prop.path} render={() => <Settings view={this.state.view} changeView={this.changeView} toggleReChargeView={() => this.setState({ showReCharge: !this.state.showReCharge })} />} key={key} />
                    :
                    (
                      prop.path === '/address_prompts'
                      ?
                      <Route path={prop.path} render={() => <Prompts paused={this.state.paused} view={this.state.view} togglePause={() => this.setState({ paused: !this.state.paused })} />} key={key} />
                      :
                      (prop.path === '/validation_rules' ?
                        <Route path={prop.path} render={() => <ValidationRules view={this.state.view} />} key={key} />
                        :
                        <Route path={prop.path} component={prop.component} key={key} />
                      )
                    )
                  )
                ))}
              </Switch>
            </div>
          </div>
          <Instructions view={this.state.view} open={this.state.showInstructions} close={() => this.setState({ showInstructions: false })} />
        </div>
      );
  }
}

Main.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(mainStyle)(Main);
