import { cardCategoryWhite, cardTitleWhite } from 'assets/jss/theme.jsx';


const IconsStyle = theme => ({
  cardCategoryWhite,
  cardTitleWhite,
  cardDescription: {
    fontSize: '16px',
    marginBottom: '0px'
  },
  center: {
    textAlign: 'center'
  },
  toggleContainer: {
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: `${theme.spacing(1)}px 0`,
    background: theme.palette.background.default,
  },
  button: {
    margin: theme.spacing(1),
  },
  groupSelector: {
    textAlign: 'center',
    marginBottom: '20px'
  },
  divider: {
    marginBottom: '20px'
  },
  iconImage: {
    border: '1px solid white',
    borderRadius: '5px',
    cursor: 'pointer',
    marginBottom: '5px',
    padding: '2px',
    textAlign: 'center',
    whiteSpace: 'nowrap',
    overflowX: 'hidden',
    '&:hover': {
      borderColor: '#F05F40'
    }
  },
  iconImageActive: {
    backgroundColor: '#f7af9f',
    border: '1px solid #F05F40',
    borderRadius: '5px',
    cursor: 'pointer',
    marginBottom: '5px',
    padding: '2px',
    textAlign: 'center',
    whiteSpace: 'nowrap',
    overflowX: 'hidden',
    '&:hover': {
      borderColor: '#F05F40'
    }
  }
});

export default IconsStyle;
