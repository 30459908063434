import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import _ from "lodash";
import General from "helper/general.js";
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Fab,
  FormControlLabel,
  Grid,
  IconButton,
  Paper,
  Step,
  StepLabel,
  Stepper,
  Switch,
  Typography,
} from "@material-ui/core/";
import { ChevronLeft, ChevronRight, Close } from "@material-ui/icons/";
import AlertsStyles from "assets/jss/recharge.jsx";
import Loader from "components/loader.jsx";
import Snackbar from "../../components/snackBar.jsx";
import CardForm from "components/cardForm";
import { Elements } from "react-stripe-elements";
import axios from "axios";
import InputChips from "../../components/inputChips";

const genInstructions = (step) => {
  switch (step) {
    case 0:
      return (
        <span>
          <b>Step 1</b>: Navigate to the Apps section of on your Shopify
          dashboard and select <i>ReCharge</i>
        </span>
      );
    case 1:
      return (
        <span>
          <b>Step 2</b>: Select Checkout under Storefront
        </span>
      );
    case 2:
      return (
        <span>
          <b>Step 3</b>: Scroll down, toggle{" "}
          <i>Enable Javascript on page 1 of checkout</i>, and paste in the code
          snippet below.
        </span>
      );
    case 3:
      return (
        <span>
          <b>Step 4</b>: Scroll down and click <i>Save</i>
        </span>
      );
    default:
      return null;
  }
};

class ReCharge extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeStep: 0,
      loading: true,
      error_message: null,
      success_message: null,
      open: false,
      showInstructions: false,
      receipt_email: "",
      receipt_email_ipt: "",
      stripe_preferences_id: null,
    };

    this.updateReceiptEmail = this.updateReceiptEmail.bind(this);
    this.changeReceiptEmailState = this.changeReceiptEmailState.bind(this);
  }

  componentDidMount() {
    axios({
      method: "GET",
      url: General.serverURL + "address_validator/api/pricing",
      headers: { Authorization: `Bearer ${General.getJWT()}` },
    }).then((result) => this.setState({ price: result.data?.price }));

    axios({
      method: "GET",
      url: General.serverURL + "address_validator/api/stripe_preferences",
      headers: { Authorization: `Bearer ${General.getJWT()}` },
    }).then((result) => {
      if (result.data) {
        this.setState({
          receipt_email: result.data.receipt_email,
          stripe_preferences_id: result.data.id,
        });
      }
    });

    const brand = General.getBrandData();
    this.setState(
      Object.assign(
        _.pick(brand, [
          "recharge_configured",
          "recharge_enabled",
          "recharge_jwt",
          "recharge_stripe_token",
        ]),
        { loading: false }
      )
    );

    // need to set timeout for scrolling with keyboard + will override keyboard navigation on general instructions modal
    setTimeout(
      () =>
        (document.onkeydown = (e) => {
          if (this.state.showInstructions)
            if (e.keyCode === 37)
              this.setState({
                activeStep: Math.min(Math.max(this.state.activeStep - 1, 0), 3),
              });
            else if (e.keyCode === 39)
              this.setState({
                activeStep: Math.min(Math.max(this.state.activeStep + 1, 0), 3),
              });
        }),
      1000
    );
  }

  updateReceiptEmail(input) {
    const { stripe_preferences_id } = this.state;

    let data = {
      receipt_email: input,
    };

    if (stripe_preferences_id) {
      data.id = stripe_preferences_id;
    }

    return axios({
      method: "PUT",
      url:
        General.serverURL +
        "address_validator/api/stripe_preference_receipt_email",
      headers: { Authorization: `Bearer ${General.getJWT()}` },
      data: data,
    });
  }

  changeReceiptEmailState(event) {
    this.setState({ receipt_email_ipt: event.target.value });

    this.updateReceiptEmail(event.target.value).then((result) => {
      this.setState({
        receipt_email: result.data.receipt_email,
        receipt_email_ipt: "",
        open: true,
        alert_type: "success",
      });
    });
  }

  toggleAlert(key, type) {
    let newState = this.state;
    newState[key] = !newState[key];
    newState["open"] = false;
    this.setState(newState);

    General.setBrandValue(key, newState[key], (type) =>
      this.setState({ open: true, alert_type: type })
    );
  }

  copyCode() {
    const elm = document.getElementById("codeBox");

    if (document.body.createTextRange) {
      // for Internet Explorer
      const range = document.body.createTextRange();
      range.moveToElementText(elm);
      range.select();
      document.execCommand("Copy");
    } else if (window.getSelection) {
      // other browsers
      const selection = window.getSelection();
      const range = document.createRange();
      range.selectNodeContents(elm);
      selection.removeAllRanges();
      selection.addRange(range);
      document.execCommand("Copy");
    }

    this.setState({
      open: true,
      alert_type: "success",
      success_message: "Code copied",
    });
  }

  render() {
    const { classes } = this.props;

    if (this.state.loading) return <Loader />;
    else
      return (
        <div>
          <Snackbar
            alert_type={this.state.alert_type}
            close={() =>
              this.setState({
                open: false,
                alert_type: null,
                success_message: null,
              })
            }
            success_msg={this.state.success_message}
            error_msg={this.state.error_message}
            open={this.state.open}
          />
          <Dialog
            open={this.state.showInstructions}
            onClose={() =>
              this.setState({ activeStep: 0, showInstructions: false })
            }
            aria-labelledby="instructions-title"
            aria-describedby="instructions-content"
            fullWidth={true}
            maxWidth="md"
          >
            <DialogTitle id="instructions-title">
              <Stepper activeStep={this.state.activeStep}>
                {[
                  "<i>ReCharge</i> app",
                  "Select <i>Checkout</i>",
                  "Insert code",
                  "Save",
                ].map((label, index) => (
                  <Step key={label}>
                    <StepLabel>
                      <div dangerouslySetInnerHTML={{ __html: label }} />
                    </StepLabel>
                  </Step>
                ))}
              </Stepper>
              <IconButton
                aria-label="close"
                className={classes.closeButton}
                onClick={() =>
                  this.setState({ activeStep: 0, showInstructions: false })
                }
              >
                <Close />
              </IconButton>
            </DialogTitle>
            <DialogContent className={classes.center} dividers>
              <Grid container justifyContent="center">
                <GridItem xs={1} sm={1} md={1}>
                  <GridContainer
                    justifyContent="center"
                    alignItems="center"
                    style={{ height: "100%" }}
                  >
                    <div className={classes.sideStepContainer}>
                      <Fab
                        size="medium"
                        color="default"
                        aria-label="Previous"
                        className={classes.margin}
                        disabled={this.state.activeStep === 0}
                        onClick={() =>
                          this.setState({
                            activeStep: this.state.activeStep - 1,
                          })
                        }
                      >
                        <ChevronLeft fontSize="large">Previous</ChevronLeft>
                      </Fab>
                    </div>
                  </GridContainer>
                </GridItem>
                <GridItem xs={12} sm={12} md={10}>
                  <p className={classes.cardDescription}>
                    {genInstructions(this.state.activeStep)}
                  </p>
                  {this.state.activeStep === 2 ? (
                    <div
                      className={classes.center}
                      style={{ marginBottom: 20 }}
                    >
                      <pre id="codeBox" className={classes.codeBox}>
                        {`;var r_av_t='${this.state.recharge_jwt}';var a=document.createElement("script");a.src="https://d3ox8tdk614ykk.cloudfront.net/recharge.js",document.head.appendChild(a);`}
                      </pre>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => this.copyCode()}
                        className={classes.button}
                      >
                        Copy code to clipboard
                      </Button>
                    </div>
                  ) : null}
                  <img
                    src={`https://d3eci0d3sspd26.cloudfront.net/address_validator_admin_assets/recharge_step_${
                      this.state.activeStep + 1
                    }.png`}
                    alt={`step_${this.state.activeStep + 1}`}
                    className={classes.imageObj}
                  />
                  {this.state.activeStep === 2 ? (
                    <p>
                      <b>
                        If you do not see this option,{" "}
                        <a
                          href="https://support.rechargepayments.com/hc/en-us/requests/new"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          please contact ReCharge support
                        </a>{" "}
                        and ask them to enable Javascript on the first step of
                        checkout for your account
                      </b>
                      .
                    </p>
                  ) : null}
                </GridItem>
                <GridItem xs={1}>
                  <GridContainer
                    justifyContent="center"
                    alignItems="center"
                    style={{ height: "100%" }}
                  >
                    <div className={classes.sideContainer}>
                      <Fab
                        size="medium"
                        color="default"
                        aria-label="Previous"
                        className={classes.margin}
                        disabled={this.state.activeStep === 3}
                        onClick={() =>
                          this.setState({
                            activeStep: this.state.activeStep + 1,
                          })
                        }
                      >
                        <ChevronRight fontSize="large">Next</ChevronRight>
                      </Fab>
                    </div>
                  </GridContainer>
                </GridItem>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button
                disabled={this.state.activeStep === 0}
                size="large"
                onClick={() =>
                  this.setState({ activeStep: this.state.activeStep - 1 })
                }
                className={classes.button}
              >
                <ChevronLeft fontSize="large">Back</ChevronLeft>
                Back
              </Button>
              {this.state.activeStep < 3 ? (
                <Button
                  variant="contained"
                  size="large"
                  color="primary"
                  onClick={() =>
                    this.setState({ activeStep: this.state.activeStep + 1 })
                  }
                  className={classes.button}
                  disabled={this.state.activeStep === 3}
                >
                  Next
                  <ChevronRight fontSize="large">Next</ChevronRight>
                </Button>
              ) : (
                <Button
                  variant="contained"
                  size="large"
                  color="primary"
                  onClick={() =>
                    this.setState({ activeStep: 0, showInstructions: false })
                  }
                  className={classes.button}
                >
                  Finished
                </Button>
              )}
            </DialogActions>
          </Dialog>

          <GridContainer justifyContent="center">
            <GridItem xs={12} md={10}>
              <Card>
                <CardHeader color="primary">
                  <h3 className={classes.cardTitleWhite}>
                    What are ReCharge orders?
                  </h3>
                </CardHeader>
                <CardBody className={classes.center}>
                  <p className={classes.cardDescription}>
                    These are subscription orders processed by{" "}
                    <a
                      href="https://rechargepayments.com/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      ReCharge
                    </a>
                    . <i>Address Validator</i> does not process these orders by default because they do not go through the standard Shopify checkout process. This tab allows you to enable{" "} <i>Address Validator</i> for these subscription orders.
                  </p>
                </CardBody>
              </Card>
            </GridItem>
            <GridItem xs={12} md={10}>
              <Card>
                <CardHeader color="primary">
                  <h3 className={classes.cardTitleWhite}>
                    Activate address validation for ReCharge subscription orders
                  </h3>
                </CardHeader>
                <CardBody>
                  <Paper style={{ marginTop: 10, padding: 10 }}>
                    <GridContainer>
                      <GridItem xs={12} md={8}>
                        <Typography variant="h6">
                          Step 1: Enable Address Validation for ReCharge orders
                        </Typography>
                      </GridItem>
                      <GridItem xs={12} md={4}>
                        <FormControlLabel
                          control={
                            <Switch
                              checked={this.state.recharge_enabled}
                              onChange={(value) =>
                                this.toggleAlert(
                                  "recharge_enabled",
                                  "enable ReCharge"
                                )
                              }
                              color="primary"
                            />
                          }
                          label={this.state.recharge_enabled ? "Yes" : "No"}
                          style={{ float: "right" }}
                        />
                      </GridItem>
                    </GridContainer>
                  </Paper>
                  <Paper style={{ marginTop: 20, padding: 10 }}>
                    <GridContainer>
                      <GridItem xs={12} md={10}>
                        <Typography variant="h6">
                          Step 2: Insert code snippet into ReCharge checkout
                        </Typography>
                      </GridItem>
                      <GridItem xs={12} md={2}>
                        <Typography variant="h6" className={classes.done_label}>
                          {this.state.recharge_configured ? "DONE" : ""}
                        </Typography>
                      </GridItem>
                      <GridItem xs={12} style={{ textAlign: "center" }}>
                        {this.state.recharge_configured ? (
                          <p
                            className={classes.cardDescription}
                            style={{ textAlign: "left" }}
                          >
                            <span
                              style={{ color: "blue", cursor: "pointer" }}
                              onClick={() =>
                                this.setState({ showInstructions: true })
                              }
                            >
                              Click here
                            </span>{" "}
                            to show instructions to re-insert code snippet into
                            the ReCharge checkout
                          </p>
                        ) : (
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={() =>
                              this.setState({ showInstructions: true })
                            }
                            className={classes.button}
                          >
                            Show Instructions
                          </Button>
                        )}
                      </GridItem>
                    </GridContainer>
                  </Paper>
                  <Paper style={{ marginTop: 20, padding: 10 }}>
                    <GridContainer>
                      <GridItem xs={12} md={10}>
                        <Typography variant="h6">
                          Step 3: Add credit card
                        </Typography>
                      </GridItem>
                      <GridItem xs={12} md={2}>
                        <Typography variant="h6" className={classes.done_label}>
                          {this.state.recharge_stripe_token ? "DONE" : ""}
                        </Typography>
                      </GridItem>
                      <GridItem xs={12} style={{ textAlign: "center" }}>
                        <p
                          className={classes.cardDescription}
                          style={{ textAlign: "left" }}
                        >
                          <i>Address Validator</i> charges ${this.state.price}{" "}
                          per <i>ReCharge</i> subscription order processed to
                          the specified credit card. You will only be charged
                          for the first checkout per subscription order.
                          Non-subscription orders that go through the standard
                          Shopify checkout process will be added to your Shopify
                          bill.
                        </p>
                      </GridItem>
                      {
                        this.state.recharge_stripe_token 
                        ? 
                        <GridItem xs={12} style={{ textAlign: "center" }}>
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={() => this.setState({ recharge_stripe_token: null })}
                            className={classes.button}
                          >
                            Change card
                          </Button>
                        </GridItem>
                        : 
                        <GridItem xs={12}>
                          <Elements>
                            <CardForm
                              successFunc={() =>
                                this.setState({
                                  open: true,
                                  alert_type: "success",
                                  success_message: "Card added to account",
                                  recharge_stripe_token: "DONE",
                                })
                              }
                              errorFunc={(msg) =>
                                this.setState({
                                  open: true,
                                  alert_type: "error",
                                  error_message: msg,
                                })
                              }
                            />
                          </Elements>
                        </GridItem>
                      }
                    </GridContainer>
                  </Paper>
                </CardBody>
              </Card>
            </GridItem>
            <GridItem xs={12} md={10}>
              <Card>
                <CardHeader color="primary">
                  <h3 className={classes.cardTitleWhite}>
                    Change receipt email address
                  </h3>
                </CardHeader>
                <CardBody>
                  {/* <TextField
                  label="Email Address"
                  value={this.state.receipt_email_ipt || this.state.receipt_email}
                  onChange={(event) => this.setState({ receipt_email_ipt: event.target.value })}
                  // onKeyPress={this.updateReceiptEmail}
                  margin='normal'
                  fullWidth={true}
                  InputProps={{
                    endAdornment: (
                      <Button
                        variant='contained' color='primary' size='small'
                        onClick={this.updateReceiptEmail}
                      >
                        Add
                      </Button>
                    ),
                  }}
                /> */}
                  <InputChips
                    list={
                      this.state.receipt_email_ipt || this.state.receipt_email
                    }
                    label="Email address"
                    onChange={(value) => this.changeReceiptEmailState(value)}
                  />
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        </div>
      );
  }
}

export default withStyles(AlertsStyles)(ReCharge);
