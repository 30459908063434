import React from 'react';
import Card from './Card/Card.jsx';
import CardHeader from './Card/CardHeader.jsx';
import CardBody from './Card/CardBody.jsx';


class DescriptionBox extends React.Component {
  render() {
    return (
      <Card>
        <CardHeader color='primary'>
          <h3 className={this.props.classes.cardTitleWhite}>{this.props.title}</h3>
        </CardHeader>
        <CardBody style={{ textAlign: 'center' }}>
          <p className={this.props.classes.cardDescription} dangerouslySetInnerHTML={{ __html: this.props.content }} />
        </CardBody>
      </Card>
    );
  }
};


export default DescriptionBox;