const InstructionsStyle = theme => ({

  title: {
    flexGrow: 1, 
    textAlign: 'center'
  },
  root: {
    flex: 1,
    textAlign: 'center',
    marginTop: 25,
    justifyContent: 'center'
  },
  paperParent: { alignItems: 'flex-start' },
  paperChild: { maxHeight: 'calc(100% - 120px)' },
  chevronContainer: {
    height: '100%',
    paddingTop: '190px',
    // [theme.breakpoints.down('xs')]: {
    //   // height: '40px',
    //   paddingTop: '0px'
    // }
  },
  gridContainer: {
    position: 'relative',

    "& $sideStepContainer": {
      position: 'absolute',
    },
  },
  instructions: {
    marginTop: '15px',
    textAlign: 'center',
    fontSize: '18px'
  },
  codeBox: {
    whiteSpace: 'pre-wrap', 
    fontSize: 14, 
    backgroundColor: '#f5f5f5', 
    borderRadius: 5,
    border: '2px solid black', 
    textAlign: 'center', 
    padding: 5,
    wordWrap: 'break-word'
  },
  images: {
    marginBottom: '15px',
    marginTop: '15px',
    textAlign: 'center'
  },
  imageObj: {
    maxWidth: '100%', 
    maxHeight: '500px', 
    height: 'auto'
  },
  imageObjSmall: {
    maxWidth: '50%',
    height: 'auto',
  },
  imageObjExtraSmall: {
    maxWidth: '25%',
    height: 'auto',
  },
  center: {
    textAlign: 'center'
  },
  help: {
    fontSize: '16px',
    marginTop: '25px'
  },

  sideStepContainer: {
    textAlign: 'center',
    cursor: 'pointer',

    "&.first": {
      top: '200px',
      left: '0px'
    },
    "&.last": {
      top: '200px',
      right: '0px'
    },
  },
  sideStepText: {
    marginTop: 0
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

export default InstructionsStyle;
