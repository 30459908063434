export default {
  icons: [
    { key: 'fa-heart', name: 'heart', bucket: 'Valentine\'s Day' },
    { key: 'fa-heart-o', name: 'heart outline', bucket: 'Valentine\'s Day' },
    { key: 'fa-flag', name: 'flag', bucket: 'Flags' },
    { key: 'fa-flag-checkered', name: 'checkered flag', bucket: 'Flags' },
    { key: 'fa-beer', name: 'beer', bucket: 'Other' },
    { key: 'fa-asterisk', name: 'asterisk', bucket: 'Other' },
    { key: 'fa-snowflake-o', name: 'snowflake', bucket: 'Winter & Christmas' },
    { key: 'fa-gift', name: 'gift', bucket: 'Winter & Christmas' },
    { key: 'fa-star', name: 'star', bucket: 'Other' },
    { key: 'fa-star-o', name: 'star outline', bucket: 'Other' },
    { key: 'fa-mortar-board', name: 'graduation', bucket: 'Other' },
    { key: 'fa-birthday-cake', name: 'cake', bucket: 'Other' },
    { key: 'fa-money', name: 'money', bucket: 'Other' },
    { key: 'fa-trophy', name: 'trophy', bucket: 'Other' },
    { key: 'fa-tint', name: 'rain drop', bucket: 'Spring & Mother\'s Day' },
    { key: 'fa-sun-o', name: 'sun', bucket: 'Other' },
    { key: 'fa-tree', name: 'tree', bucket: 'Other' },
    { key: 'fa-moon-o', name: 'moon', bucket: 'Other' },
    { key: 'fa-leaf', name: 'leaf', bucket: 'Other' },
    { key: 'fa-tag', name: 'tag', bucket: 'Other' },
    { key: 'fa-tags', name: 'tags', bucket: 'Other' },
    { key: 'fa-shopping-bag', name: 'bag', bucket: 'Other' },
    { key: 'fa-shopping-basket', name: 'basket', bucket: 'Other' },
    { key: 'fa-circle', name: 'circle', bucket: 'Other' },
    { key: 'fa-flash', name: 'bolt', bucket: 'Other' },
    { key: 'fa-certificate', name: 'certificate', bucket: 'Other' },
    { key: 'fa-paw', name: 'dog paw', bucket: 'Other' },
    { key: 'fa-music', name: 'music', bucket: 'Other' },
    { key: 'fa-life-saver', name: 'life saver', bucket: 'Other' },
    { key: 'fa-map', name: 'map', bucket: 'Other' },
    { key: 'fa-map-signs', name: 'map signs', bucket: 'Other' },
    { key: 'fa-rocket', name: 'rocket', bucket: 'Other' },
    { key: 'fa-dollar', name: 'dollar', bucket: 'Other' },
    { key: 'fa-soccer-ball-o', name: 'soccer ball', bucket: 'Other' },
    { key: 'fa-bank', name: 'bank', bucket: 'Other' },
    { key: 'fa-bell', name: 'bell', bucket: 'Other' },
    { key: 'fa-book', name: 'book', bucket: 'Other' },
    { key: 'fa-bicycle', name: 'bicycle', bucket: 'Other' },
    { key: 'fa-coffee', name: 'coffee', bucket: 'Other' },
    { key: 'fa-diamond', name: 'diamond', bucket: 'Other' },
    { key: 'fa-gamepad', name: 'gamepad', bucket: 'Other' },
    { key: 'fa-hand-peace-o', name: 'peace', bucket: 'Other' },
    { key: 'fa-hand-spock-o', name: 'spock', bucket: 'Other' },
    { key: 'fa-hotel', name: 'hotel', bucket: 'Other' },
    { key: 'fa-legal', name: 'legal', bucket: 'Other' },
    { key: 'fa-life-bouy', name: 'life bouy', bucket: 'Other' },
    { key: 'fa-lightbulb-o', name: 'lightbulb', bucket: 'Other' },
    { key: 'fa-mobile', name: 'mobile', bucket: 'Other' },
    { key: 'fa-paint-brush', name: 'paintbrush', bucket: 'Other' },
    { key: 'fa-pencil', name: 'pencil', bucket: 'Other' },
    { key: 'fa-phone', name: 'phone', bucket: 'Other' },
    { key: 'fa-flask', name: 'flask', bucket: 'Other' },
    { key: 'fa-film', name: 'film', bucket: 'Other' },
    { key: 'fa-anchor', name: 'anchor', bucket: 'Other' },
    { key: 'fa-check', name: 'check', bucket: 'Other' },
    { key: 'fa-cutlery', name: 'cutlery', bucket: 'Other' },
    { key: 'fa-lemon-o', name: 'lemon', bucket: 'Other' },
    { key: 'fa-smile-o', name: 'smile', bucket: 'Other' },
    { key: 'fa-road', name: 'road', bucket: 'Other' },
  ],
  imgs: [
    { key: 'image_peanut.png', bucket: 'Other' },
    { key: 'image_heart_1.png', bucket: 'Valentine\'s Day' },
    { key: 'image_heart_2.png', bucket: 'Valentine\'s Day' },
    { key: 'image_heart_3.png', bucket: 'Valentine\'s Day' },
    { key: 'image_heart_4.png', bucket: 'Valentine\'s Day' },
    { key: 'image_custom_hearts_1.png', bucket: 'Valentine\'s Day' },
    { key: 'image_custom_hearts_2.png', bucket: 'Valentine\'s Day' },
    { key: 'image_custom_hearts_3.png', bucket: 'Valentine\'s Day' },
    { key: 'image_rose_1.png', bucket: 'Valentine\'s Day' },
    { key: 'image_rose_2.png', bucket: 'Valentine\'s Day' },
    { key: 'image_dragon_1.png', bucket: 'Lunar New Year' },
    { key: 'image_dragon_2.png', bucket: 'Lunar New Year' },
    { key: 'image_dragon_3.png', bucket: 'Lunar New Year' },
    { key: 'image_lantern_1.png', bucket: 'Lunar New Year' },
    { key: 'image_lantern_2.png', bucket: 'Lunar New Year' },
    { key: 'image_lion_1.png', bucket: 'Lunar New Year' },
    { key: 'image_rooster_1.png', bucket: 'Lunar New Year' },
    { key: 'image_rooster_2.png', bucket: 'Lunar New Year' },
    { key: 'image_rooster_3.png', bucket: 'Lunar New Year' },
    { key: 'image_clover_1.png', bucket: 'St. Patrick\'s Day' },
    { key: 'image_clover_2.png', bucket: 'St. Patrick\'s Day' },
    { key: 'image_clover_3.png', bucket: 'St. Patrick\'s Day' },
    { key: 'image_clover_4.png', bucket: 'St. Patrick\'s Day' },
    { key: 'image_clover_5.png', bucket: 'St. Patrick\'s Day' },
    { key: 'image_st_patty_character.png', bucket: 'St. Patrick\'s Day' },
    { key: 'image_st_patty_hat.png', bucket: 'St. Patrick\'s Day' },
    { key: 'image_fireworks_1.png', bucket: 'July 4th & President\'s Day' },
    { key: 'image_fireworks_2.png', bucket: 'July 4th & President\'s Day' },
    { key: 'image_fireworks_3.png', bucket: 'July 4th & President\'s Day' },
    { key: 'image_president_hat_1.png', bucket: 'July 4th & President\'s Day' },
    { key: 'image_president_hat_2.png', bucket: 'July 4th & President\'s Day' },
    { key: 'image_usa_flag_1.png', bucket: 'July 4th & President\'s Day' },
    { key: 'image_usa_flag_2.png', bucket: 'July 4th & President\'s Day' },
    { key: 'image_usa_flag_3.png', bucket: 'July 4th & President\'s Day' },
    { key: 'image_us_seal.png', bucket: 'July 4th & President\'s Day' },
    { key: 'image_president_1.png', bucket: 'July 4th & President\'s Day' },
    { key: 'image_president_2.png', bucket: 'July 4th & President\'s Day' },
    { key: 'image_president_3.png', bucket: 'July 4th & President\'s Day' },
    { key: 'image_easter_egg_1.png', bucket: 'Easter' },
    { key: 'image_easter_egg_2.png', bucket: 'Easter' },
    { key: 'image_easter_egg_3.png', bucket: 'Easter' },
    { key: 'image_easter_egg_4.png', bucket: 'Easter' },
    { key: 'image_easter_bunny_1.png', bucket: 'Easter' },
    { key: 'image_easter_bunny_2.png', bucket: 'Easter' },
    { key: 'image_easter_bunny_3.png', bucket: 'Easter' },
    { key: 'image_easter_bunny_4.png', bucket: 'Easter' },
    { key: 'image_bee_1.png', bucket: 'Spring & Mother\'s Day' },
    { key: 'image_bee_2.png', bucket: 'Spring & Mother\'s Day' },
    { key: 'image_bee_3.png', bucket: 'Spring & Mother\'s Day' },
    { key: 'image_bee_4.png', bucket: 'Spring & Mother\'s Day' },
    { key: 'image_lips_1.png', bucket: 'Valentine\'s Day' },
    { key: 'image_anti_valentines_1.png', bucket: 'Valentine\'s Day' },
    { key: 'image_anti_valentines_2.png', bucket: 'Valentine\'s Day' },
    { key: 'image_anti_valentines_3.png', bucket: 'Valentine\'s Day' },
    { key: 'image_logo_1.png', bucket: 'Other' },
    { key: 'image_unicorn_1.png', bucket: 'Other' },
    { key: 'image_unicorn_2.png', bucket: 'Other' },
    { key: 'image_flower_1.png', bucket: 'Spring & Mother\'s Day' },
    { key: 'image_flower_2.png', bucket: 'Spring & Mother\'s Day' },
    { key: 'image_flower_3.png', bucket: 'Spring & Mother\'s Day' },
    { key: 'image_flower_4.png', bucket: 'Spring & Mother\'s Day' },
    { key: 'image_flower_5.png', bucket: 'Spring & Mother\'s Day' },
    { key: 'image_flower_6.png', bucket: 'Spring & Mother\'s Day' },
    { key: 'image_mothers_day_1.png', bucket: 'Spring & Mother\'s Day' },
    { key: 'image_mothers_day_2.png', bucket: 'Spring & Mother\'s Day' },
    { key: 'image_mothers_day_3.png', bucket: 'Spring & Mother\'s Day' },
    { key: 'image_cactus_1.png', bucket: 'Other' },
    { key: 'image_cactus_2.png', bucket: 'Other' },
    { key: 'image_cactus_3.png', bucket: 'Other' },
    { key: 'image_dandelion_1.png', bucket: 'Spring & Mother\'s Day' },
    { key: 'image_dandelion_2.png', bucket: 'Spring & Mother\'s Day' },
    { key: 'image_dandelion_3.png', bucket: 'Spring & Mother\'s Day' },
    { key: 'image_dreamcatcher_1.png', bucket: 'Other' },
    { key: 'image_dreamcatcher_2.png', bucket: 'Other' },
    { key: 'image_dreamcatcher_3.png', bucket: 'Other' },
    { key: 'image_feather_1.png', bucket: 'Other' },
    { key: 'image_feather_2.png', bucket: 'Other' },
    { key: 'image_feather_3.png', bucket: 'Other' },
    { key: 'image_steer_skull_1.png', bucket: 'Other' },
    { key: 'image_steer_skull_2.png', bucket: 'Other' },
    { key: 'image_steer_skull_3.png', bucket: 'Other' },
    { key: 'image_smoke.png', bucket: 'Other' },
    { key: 'image_flag_canada.png', bucket: 'Flags' },
    { key: 'image_flag_us.png', bucket: 'Flags' },
    { key: 'image_flag_france.png', bucket: 'Flags' },
    { key: 'image_flag_mexico.png', bucket: 'Flags' },
    { key: 'image_flag_uk.png', bucket: 'Flags' },
    { key: 'image_flag_italy.png', bucket: 'Flags' },
    { key: 'image_flag_australia.png', bucket: 'Flags' },
    { key: 'image_flag_japan.png', bucket: 'Flags' },
    { key: 'image_flag_pakistan.png', bucket: 'Flags' },
    { key: 'image_flag_portugal.png', bucket: 'Flags' },
    { key: 'image_bats_1.png', bucket: 'Halloween' },
    { key: 'image_bats_2.png', bucket: 'Halloween' },
    { key: 'image_bats_3.png', bucket: 'Halloween' },
    { key: 'image_frankenstein_1.png', bucket: 'Halloween' },
    { key: 'image_frankenstein_2.png', bucket: 'Halloween' },
    { key: 'image_ghost_1.png', bucket: 'Halloween' },
    { key: 'image_ghost_2.png', bucket: 'Halloween' },
    { key: 'image_haunted_house.png', bucket: 'Halloween' },
    { key: 'image_pumpkin_1.png', bucket: 'Halloween' },
    { key: 'image_pumpkin_2.png', bucket: 'Halloween' },
    { key: 'image_pumpkin_3.png', bucket: 'Halloween' },
    { key: 'image_pumpkin_4.png', bucket: 'Halloween' },
    { key: 'image_pumpkin_5.png', bucket: 'Halloween' },
    { key: 'image_witch_1.png', bucket: 'Halloween' },
    { key: 'image_witch_2.png', bucket: 'Halloween' },
    { key: 'image_witch_3.png', bucket: 'Halloween' },
    { key: 'image_leaves_1.png', bucket: 'Fall & Thanksgiving' },
    { key: 'image_leaves_2.png', bucket: 'Fall & Thanksgiving' },
    { key: 'image_leaves_3.png', bucket: 'Fall & Thanksgiving' },
    { key: 'image_leaves_4.png', bucket: 'Fall & Thanksgiving' },
    { key: 'image_leaves_5.png', bucket: 'Fall & Thanksgiving' },
    { key: 'image_leaves_6.png', bucket: 'Fall & Thanksgiving' },
    { key: 'image_leaves_7.png', bucket: 'Fall & Thanksgiving' },
    { key: 'image_thanksgiving_pumpkin.png', bucket: 'Fall & Thanksgiving' },
    { key: 'image_cornucopia.png', bucket: 'Fall & Thanksgiving' },
    { key: 'image_turkey_1.png', bucket: 'Fall & Thanksgiving' },
    { key: 'image_turkey_2.png', bucket: 'Fall & Thanksgiving' },
    { key: 'image_turkey_3.png', bucket: 'Fall & Thanksgiving' },
    { key: 'image_turkey_4.png', bucket: 'Fall & Thanksgiving' },
    { key: 'image_christmas_bells.png', bucket: 'Winter & Christmas' },
    { key: 'image_christmas_tree_1.png', bucket: 'Winter & Christmas' },
    { key: 'image_christmas_tree_2.png', bucket: 'Winter & Christmas' },
    { key: 'image_christmas_tree_3.png', bucket: 'Winter & Christmas' },
    { key: 'image_christmas_tree_4.png', bucket: 'Winter & Christmas' },
    { key: 'image_christmas_tree_5.png', bucket: 'Winter & Christmas' },
    { key: 'image_mistletoe.png', bucket: 'Winter & Christmas' },
    { key: 'image_ornament_1.png', bucket: 'Winter & Christmas' },
    { key: 'image_ornament_2.png', bucket: 'Winter & Christmas' },
    { key: 'image_ornament_3.png', bucket: 'Winter & Christmas' },
    { key: 'image_presents_1.png', bucket: 'Winter & Christmas' },
    { key: 'image_presents_2.png', bucket: 'Winter & Christmas' },
    { key: 'image_presents_3.png', bucket: 'Winter & Christmas' },
    { key: 'image_santa_1.png', bucket: 'Winter & Christmas' },
    { key: 'image_santa_2.png', bucket: 'Winter & Christmas' },
    { key: 'image_santa_3.png', bucket: 'Winter & Christmas' },
    { key: 'image_santa_4.png', bucket: 'Winter & Christmas' },
    { key: 'image_santa_penguin.png', bucket: 'Winter & Christmas' },
    { key: 'image_snowman_1.png', bucket: 'Winter & Christmas' },
    { key: 'image_snowman_2.png', bucket: 'Winter & Christmas' },
    { key: 'image_snowman_3.png', bucket: 'Winter & Christmas' },
    { key: 'image_snowman_4.png', bucket: 'Winter & Christmas' },
    { key: 'image_snow_1.png', bucket: 'Winter & Christmas' },
    { key: 'image_snow_2.png', bucket: 'Winter & Christmas' },
    { key: 'image_snow_3.png', bucket: 'Winter & Christmas' },
    { key: 'image_snow_4.png', bucket: 'Winter & Christmas' },
    { key: 'image_snow_5.png', bucket: 'Winter & Christmas' },
    { key: 'image_snow_6.png', bucket: 'Winter & Christmas' },
    { key: 'image_snow_7.png', bucket: 'Winter & Christmas' },
    { key: 'image_raindeer_1.png', bucket: 'Winter & Christmas' },
    { key: 'image_raindeer_2.png', bucket: 'Winter & Christmas' },
    { key: 'image_raindeer_3.png', bucket: 'Winter & Christmas' },
    { key: 'image_raindeer_4.png', bucket: 'Winter & Christmas' },
    { key: 'image_raindeer_5.png', bucket: 'Winter & Christmas' },
    { key: 'image_raindeer_6.png', bucket: 'Winter & Christmas' },
    { key: 'image_cat_1.png', bucket: 'Winter & Christmas' },
    { key: 'image_cat_2.png', bucket: 'Winter & Christmas' },
    { key: 'image_moose_1.png', bucket: 'Winter & Christmas' },
    { key: 'image_moose_2.png', bucket: 'Winter & Christmas' },
    { key: 'image_moose_3.png', bucket: 'Winter & Christmas' },
    { key: 'image_moose_4.png', bucket: 'Winter & Christmas' },
    { key: 'image_moose_5.png', bucket: 'Winter & Christmas' },
    { key: 'image_santa_dog.png', bucket: 'Winter & Christmas' },
    { key: 'image_santa_panda.png', bucket: 'Winter & Christmas' },
    { key: 'image_banana_1.png', bucket: 'Other' },
    { key: 'image_banana_leaf.png', bucket: 'Other' },
    { key: 'image_pineapple.png', bucket: 'Other' },
    { key: 'image_black_friday_1.png', bucket: 'Other' },
    { key: 'image_black_friday_2.png', bucket: 'Other' },
    { key: 'image_sale_1.png', bucket: 'Other' },
    { key: 'image_sale_2.png', bucket: 'Other' },
    { key: 'image_sale_3.png', bucket: 'Other' },
    { key: 'image_sale_4.png', bucket: 'Other' },
    { key: 'image_shopping_1.png', bucket: 'Other' },
    { key: 'image_shopping_2.png', bucket: 'Other' },
    { key: 'image_snowflake_icon_1.png', bucket: 'Other' },
    { key: 'image_bubble_1.png', bucket: 'Other' },
    { key: 'image_bubble_2.png', bucket: 'Other' },
    { key: 'image_eiffel_tower.png', bucket: 'Other' },
    { key: 'image_mimmi_bags_1.png', bucket: 'Other' },
    { key: 'image_mimmi_bags_2.png', bucket: 'Other' },
    { key: 'image_mimmi_bags_3.png', bucket: 'Other' },
    { key: 'image_dachshund.png', bucket: 'Other' },
    { key: 'image_dachshund_1.png', bucket: 'Other' },
    { key: 'image_wire_haired_dachshund_1.png', bucket: 'Other' },
    { key: 'image_wire_haired_dachshund_2.png', bucket: 'Other' },
    { key: 'image_flonero_1.png', bucket: 'Other' },
    { key: 'image_flonero_2.png', bucket: 'Other' },
    { key: 'image_flonero_3.png', bucket: 'Other' },
    { key: 'image_muva.png', bucket: 'Other' },
    { key: 'image_muva_1.png', bucket: 'Other' },
    { key: 'image_crossfit.png', bucket: 'Other' }
  ],
  number_symbols: [
    { display: 10, value: 10 },
    { display: 25, value: 25 },
    { display: 50, value: 50 },
    { display: 75, value: 75 },
    { display: 100, value: 100 }
  ],
  delay: [
    { display: '0 sec', value: 0 },
    { display: '1 sec', value: 1000 },
    { display: '2 secs', value: 2000 },
    { display: '5 secs', value: 5000 },
    { display: '10 sec', value: 10000 }
  ],
  home_page_only: [
    { display: 'All pages', value: false },
    { display: 'Home page only', value: true },
    { display: 'Specific page', value: null }
  ],
  duration: [
    { display: '15 secs', value: 15000 },
    { display: '30 secs', value: 30000 },
    { display: '1 min', value: 60000 },
    { display: '5 mins', value: 300000 },
    { display: '10 mins', value: 600000 }
  ],
  font_size: [
    { display: 'Small', value: 5 },
    { display: 'Medium', value: 20 },
    { display: 'Large', value: 40 },
    { display: 'X-Large', value: 100 }
  ],
  speed: [
    { display: 'Slowest', value: 110 },
    { display: 'Slow', value: 80 },
    { display: 'Normal', value: 50 },
    { display: 'Fast', value: 30 },
    { display: 'Fastest', value: 10 }
  ]
};