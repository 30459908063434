import React, { Component } from 'react';
import { CardElement, injectStripe } from 'react-stripe-elements';
import { Button } from '@material-ui/core/';
import axios from 'axios';
import General from 'helper/general.js';
import 'assets/css/stripe.css';


class CardForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      disabled: false,
    };
    this.submit = this.submit.bind(this);
  }

  async submit(ev) {
    ev.preventDefault();
    this.setState({ disabled: true });
    if(this.props.stripe) {
      const payload = await this.props.stripe.createToken();
      if(payload.error) {
        this.setState({ disabled: false });
        this.props.errorFunc(payload.error.message);
      }
      else if(payload.token) {
        axios({
          method: 'POST',
          url: General.serverURL + 'address_validator/api/stripe_card',
          data: { brand_id: General.getBrandData().id, token: payload.token.id }
        })
        .then((result) => this.props.successFunc())
        .catch((err) => this.props.errorFunc(err.response.data))
      }
    } 
    else
      console.log('Stripe.js has not loaded yet.');
  }

  render() {
    return (
      <div className='checkout' style={{ textAlign: 'center' }}>
        <CardElement />
        <Button variant='contained' color='primary' onClick={this.submit} disabled={this.state.disabled}>
          Submit
        </Button>
      </div>
    );
  }
}

export default injectStripe(CardForm);