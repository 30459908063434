import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Chip from '@material-ui/core/Chip';
import AddIcon from '@material-ui/icons/Add';
import { withRouter } from 'react-router-dom';
import _ from 'lodash';
import axios from 'axios';
import General from 'helper/general.js';
import Loader from 'components/loader.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import GridContainer from 'components/Grid/GridContainer.jsx';
import Card from 'components/Card/Card.jsx';
import CardHeader from 'components/Card/CardHeader.jsx';
import CardBody from 'components/Card/CardBody.jsx';
import { Button, TextField } from '@material-ui/core/';
import Toggle from '../../components/toggle.jsx';
import AnalyticsStyle from 'assets/jss/analytics.jsx';
import ValidationRulesStyle from "assets/jss/validationRules";
import Snackbar from '../../components/snackBar.jsx';

class Settings extends React.Component {
  constructor(props) {
    super(props);
    this.state = { loading: true };
  }

  async componentDidMount() {
    const brand = General.getBrandData();
    this.setState(Object.assign({ loading: false, subscription_products: false }, _.pick(brand, ['admin_email', 'admin_phone', 'admin_name', 'subscription_products', 'config_add_notes', 'plan_name'])));

    axios({
      method: "GET",
      url:
        General.serverURL +
        "address_validator/api/user_list?brand_id=" +
        brand.id,
    })
      .then((result) => {
        this.setState({ loading: false, usersData: result.data });
      })
      .catch(() => this.setState({ loading: false }));
  }

  updateState(key, value) {
    let newState = this.state;
    newState[key] = value;
    newState.open = false;
    this.setState(newState);
  }

  toggleOption(key) {
    this.props.toggleReChargeView();
    this.updateState(key, !this.state[key]);
    General.setBrandValue(key, this.state[key], (type) => this.setState({ open: true, alert_type: type }));
  }

  updateAdminInfo() {
    const adminInfo = {
      admin_name: this.state.admin_name,
      admin_email: this.state.admin_email,
      admin_phone: this.state.admin_phone
    };

    axios({
      method: 'PUT',
      url: General.serverURL + 'address_validator/api/brand_attributes',
      data: Object.assign(adminInfo, { name: General.getBrandData().name }),
      headers: { Authorization: `Bearer ${General.getJWT()}` }
    })
    .then((result) => {
      this.setState({ open: true, alert_type: 'success' });
      General.storeBrandData(Object.assign(General.getBrandData(), adminInfo));
    })
    .catch((err) => this.setState({ open: true, alert_type: 'error' }));
  }

  render() {
    const { classes, history } = this.props;

    if (this.state.loading) return <Loader />;
    else
      return (
        <GridContainer justifyContent='center'>
          <Snackbar
            alert_type={this.state.alert_type}
            close={() => this.setState({ open: false, alert_type: null })}
            open={this.state.open}
          />
          <GridItem xs={12} md={6} lg={4}>
            <Card>
              <CardHeader color='primary'>
                <h3 className={classes.cardTitleWhite}>Admin Contact Info</h3>
              </CardHeader>
              <CardBody>
                <TextField
                  autoFocus
                  margin='dense'
                  id='admin-info-modal-name'
                  label='Name'
                  type='name'
                  fullWidth
                  value={this.state.admin_name || ''}
                  onChange={(event) => this.setState({ admin_name: event.target.value })}
                />
                <TextField
                  margin='dense'
                  id='admin-info-modal-email'
                  label='Email'
                  type='email'
                  fullWidth
                  value={this.state.admin_email || ''}
                  onChange={(event) => this.setState({ admin_email: event.target.value })}
                />
                <TextField
                  margin='dense'
                  id='admin-info-modal-phone'
                  label='Phone'
                  type='phone'
                  fullWidth
                  value={this.state.admin_phone || ''}
                  onChange={(event) => this.setState({ admin_phone: event.target.value })}
                />
                <Button
                  className={classes.button}
                  disabled={!this.state.admin_email}
                  color="primary"
                  variant="contained"
                  type="submit"
                  onClick={() => this.updateAdminInfo()}
                >
                  Update
                </Button>
              </CardBody>
            </Card>
          </GridItem>
          <GridItem xs={12} md={6} lg={4}>
            <Card>
              <CardHeader color='primary'>
                <h3 className={classes.cardTitleWhite}>Other Settings</h3>
              </CardHeader>
              <CardBody>
                <p className={classes.cardDescription}>
                  Do you sell subscription products via <a href='https://rechargepayments.com/'  target='_blank' rel='noopener noreferrer'>ReCharge</a>?
                </p>
                <Toggle value={this.state.subscription_products} onChange={() => this.toggleOption('subscription_products')} yes_label='Yes' no_label='No' />

                <p className={classes.cardDescription}>
                  Do you want to append add a note to the Shopify order notes field when a customer chooses the suggested address post-checkout?
                </p>
                <Toggle value={this.state.config_add_notes} onChange={() => this.toggleOption('config_add_notes')} yes_label='Yes' no_label='No' />

                {
                  this.state.plan_name === 'shopify_plus'
                  ?
                  <div>
                    <p className={classes.cardDescription}>
                      Change the prompts page view based on the type of integration you are using for checkout validation
                    </p>

                    <ToggleButtonGroup
                      size="small"
                      color="primary"
                      value={this.props.view}
                      exclusive
                      onChange={this.props.changeView}
                      aria-label="Prompts View"
                    >
                      <ToggleButton
                        value="Checkout Liquid"
                        classes={
                          this.props.view === "Checkout Liquid"
                            ? { selected: this.props.classes.toggleSelected }
                            : { root: this.props.classes.toggleRoot }
                        }
                      >
                        Checkout Liquid
                      </ToggleButton>
                      <ToggleButton
                        value="Checkout UI Extension"
                        classes={
                          this.props.view === "Checkout UI Extension"
                            ? { selected: this.props.classes.toggleSelected }
                            : { root: this.props.classes.toggleRoot }
                        }
                      >
                        Checkout UI Extension
                      </ToggleButton>
                    </ToggleButtonGroup>
                  </div>
                  :
                  null
                }
              </CardBody>
            </Card>
          </GridItem>
          <GridItem xs={12} md={6} lg={4}>
            <Card>
              <CardHeader color='primary'>
                <h3 className={classes.cardTitleWhite}>Users</h3>
              </CardHeader>
              <CardBody>
                {(this.state.usersData && (this.state.usersData.length > 0)) && (
                  <Table className={classes.table} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell>Email</TableCell>
                        <TableCell align="right">User Type</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {this.state.usersData.map((row) => (
                        <TableRow key={row.id}>
                          <TableCell component="th" scope="row">
                            {row.email}
                          </TableCell>
                          <TableCell align="right">
                            <Chip
                              size="small"
                              color={row.type === "primary" ? "primary" : "default"}
                              label={
                                row.type === "primary" ? "Primary" : "Staff"
                              }
                            />
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                )}
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.button}
                  endIcon={<AddIcon />}
                  onClick={() => {
                    if (General.localStorageAvailable()) {
                      localStorage.setItem(
                        "address_validator_previous_route",
                        "/settings"
                      );
                      if (this.state.usersData && this.state.usersData.length) {
                        const primaryUser = this.state.usersData.find(u => u.type === "primary")
                        localStorage.setItem(
                          "address_validator_primary_user",
                          primaryUser.email
                        );
                      }
                    }
                    history.push("/user");
                  }}
                >
                  Create
                </Button>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      );
  }
}


Settings.propTypes = {
  classes: PropTypes.object.isRequired,
  view: PropTypes.string.isRequired,
  changeView: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired
};

export default  withStyles(
  (theme) => ({
    ...ValidationRulesStyle(theme),
    ...AnalyticsStyle(theme),
  }),
  { withTheme: true },
)(withRouter(Settings));
