import _ from "lodash";
import axios from "axios";
import draftToHtml from "draftjs-to-html";
import { convertToRaw } from "draft-js";

const DEV = process.env.NODE_ENV === "development"; // localstorage is only available in development since it cannot be used within an iframe
const serverURL = DEV ? "http://localhost:3000/" : "https://app.roboturk.co/";
// const serverURL = DEV
//   ? "http://localhost:3000/"
//   : "https://4b2e-117-222-192-227.ngrok.io/";
let updateCustomTextTimeout; // Used to store timeout function to delay updates based on text changes


// some users on incognito browsers do not have access to localStorage
const localStorageAvailable = () => {
  try {
    if (localStorage) return true;
  } catch {
    return false;
  }
};

let AVbrandData = localStorageAvailable()
  ? JSON.parse(localStorage.getItem("address_validator_data"))
  : null;
let FDbrandData = localStorageAvailable()
  ? JSON.parse(localStorage.getItem("festive_decorations_data"))
  : null;

let AVjwt =
  localStorageAvailable() && AVbrandData
    ? localStorage.getItem("address_validator_jwt_" + AVbrandData.name)
    : null;
let ajaxJWT =
  localStorageAvailable() && (AVbrandData || FDbrandData)
    ? localStorage.getItem(
        AVbrandData
          ? `av_put_jwt_${AVbrandData.name}`
          : `fd_put_jwt_${FDbrandData.name}`
      )
    : null;

const saveChanges = (changes, cb) => {
  axios({
    method: "PUT",
    url: serverURL + "address_validator/api/brand_attributes",
    data: Object.assign(changes, { name: AVbrandData.name }),
    headers: { Authorization: `Bearer ${ajaxJWT}` },
  })
    .then((result) => cb("success"))
    .catch((err) => cb("error"));
};

const saveFDChanges = (changes, cb) => {
  axios({
    method: "PUT",
    url: serverURL + "festive_decorations/api/brand_attributes",
    data: Object.assign(changes, { name: FDbrandData.name }),
    headers: { Authorization: `Bearer ${ajaxJWT}` },
  })
    .then((result) => cb("success"))
    .catch((err) => cb("error"));
};

const setStylesheet = () => {
  const stylesheet = document.getElementById("custom_stylesheet");
  let css = `
    #suggestedAddress:hover, #originalAddress:hover, button.av_button:hover { 
      background-color: ${AVbrandData.customization.colorHover} !important;
    }
    p#loader-text, span#input-address {
      color: ${AVbrandData.customization.colorText};
    }
    ${AVbrandData.style_css || ''}
  `;

  // customer corners, border width, suggestion divider and suggestion borders
  if (AVbrandData.customization.cornerStyle === "box")
    css += `#myModal > #myModal-container, #addressValidatorBox, .alert-warning, button.av_button, .btn-row > .av_button { border-radius: 0px; }`;
  if (AVbrandData.customization.borderWidth !== "2px")
    css += `#myModal-container, #addressValidatorBox, .btn-row > .av_button { border-width: ${AVbrandData.customization.borderWidth}; }`;
  if (AVbrandData.customization.suggestionDivider === "hide")
    css += `#addressValidatorBox > hr { height: 0px; border-width: 0px; }`;
  if (AVbrandData.customization.suggestionOptionBorderDisplay === "show")
    css += `div#addressValidatorBox > div#suggestedAddress, div#originalAddress { box-shadow: 0px 0px 0px ${
      AVbrandData.customization.borderWidth || "2px"
    } ${AVbrandData.customization.suggestionOptionBorderColor || "#000000"}; }`;

  if (stylesheet) stylesheet.innerHTML = css;
  else {
    let style = document.createElement("style");
    style.setAttribute("id", "custom_stylesheet");
    if (style.styleSheet) style.styleSheet.cssText = css;
    else style.appendChild(document.createTextNode(css));
    document.getElementsByTagName("head")[0].appendChild(style);
  }
};

const stringifyConvertRaw = (input) =>
  JSON.stringify(convertToRaw(input.getCurrentContent()));

export default {
  localStorageAvailable: localStorageAvailable,

  pauseResumeApp(mode, cb) {
    axios({
      method: "POST",
      url: serverURL + `address_validator/api/${mode}_app`,
      data: _.pick(AVbrandData, ["name", "plan_name"]),
      headers: { Authorization: `Bearer ${ajaxJWT}` },
    })
      .then((result) => cb("success"))
      .catch((err) => cb("error"));
  },

  setJWT(data, shop) {
    ajaxJWT = data;
    if (localStorageAvailable())
      localStorage.setItem(
        AVbrandData ? `av_put_jwt_${shop}` : `fd_put_jwt_${shop}`,
        data
      );
  },
  getJWT: () => ajaxJWT,

  setPromptLocation: (value, cb) => {
    let changes = {};
    if (value === "checkout_popup")
      changes = {
        precheckout_popup: true,
        validate_pre_checkout: false,
        thank_you_popup: false,
      };
    else if (value === "checkout_embed")
      changes = {
        precheckout_popup: false,
        validate_pre_checkout: true,
        thank_you_popup: false,
      };
    else if (value === "thank_you_embed")
      changes = {
        precheckout_popup: false,
        validate_pre_checkout: false,
        thank_you_popup: false,
        disable_thank_you_validate: false,
      };
    else if (value === "thank_you_popup")
      changes = {
        precheckout_popup: false,
        validate_pre_checkout: false,
        thank_you_popup: true,
        disable_thank_you_validate: false,
      };

    AVbrandData = Object.assign(AVbrandData, changes);
    saveChanges(changes, cb);
  },

  shopifyPlus: (plan) =>
    ["shopify_plus", "plus_partner_sandbox"].indexOf(plan) !== -1,

  serverURL: serverURL,

  setStylesheet: setStylesheet,

  storeAVjwt(data) {
    AVjwt = data;
    if (localStorageAvailable())
      localStorage.setItem("address_validator_jwt_" + AVbrandData.name, data);
  },

  getAVjwt: () => AVjwt,

  storeBrandData(data) {
    AVbrandData = data;
    if (localStorageAvailable())
      localStorage.setItem("address_validator_data", JSON.stringify(data));
  },

  getBrandData: () => AVbrandData,

  setBrandValue(key, value, cb) {
    if (AVbrandData[key] !== value) {
      AVbrandData[key] = value;
      saveChanges(_.pick(AVbrandData, key), cb);
    }
  },

  storeFDBrandData(data) {
    FDbrandData = data;
    if (localStorageAvailable())
      localStorage.setItem("festive_decorations_data", JSON.stringify(data));
  },

  getFDBrandData: () => FDbrandData,

  setFDBrandValue(key, value, cb) {
    if (FDbrandData[key] !== value) {
      FDbrandData[key] = value;
      saveFDChanges(_.pick(FDbrandData, key), cb);
    }
  },

  changeColor(color, attribute, cb) {
    if (AVbrandData.customization[attribute] !== color.hex) {
      AVbrandData.customization[attribute] = color.hex;
      saveChanges(_.pick(AVbrandData, "customization"), cb);
      setStylesheet();
    }
  },

  changeText(event, attribute, cb) {
    clearTimeout(updateCustomTextTimeout);

    if (
      [
        "style_css",
        "email_address_incorrect",
        "skip_keywords",
        "update_link_email",
        "po_box_scope",
        "form_css",
        "form_window",
        "banner_title",
        "banner_description",
        "banner_type"
      ].includes(attribute)
    ) {
      if (AVbrandData[attribute] !== event.target.value) {
        AVbrandData[attribute] = event.target.value;

        if (attribute === "style_css") setTimeout(() => setStylesheet(), 1500);

        updateCustomTextTimeout = setTimeout(
          () => saveChanges(_.pick(AVbrandData, attribute), cb),
          attribute === "po_box_scope" ? 100 : 1500
        );
      }
    } else if (attribute === "custom_email_message") {
      if (
        stringifyConvertRaw(AVbrandData[attribute]) !==
        stringifyConvertRaw(event)
      ) {
        const rawData = convertToRaw(event.getCurrentContent());
        let customEmailMessageValue;

        // Check to account for empty custom message
        if (rawData.blocks.length === 1 && rawData.blocks[0].text === "") {
          AVbrandData.customization.incorrectAddressEmailBody = null;
          customEmailMessageValue = null;
        } else {
          AVbrandData.customization.incorrectAddressEmailBody = draftToHtml(
            rawData
          );
          customEmailMessageValue = stringifyConvertRaw(event);
        }

        updateCustomTextTimeout = setTimeout(
          () =>
            saveChanges(
              {
                customization: AVbrandData.customization,
                custom_email_message: customEmailMessageValue,
              },
              cb
            ),
          1500
        );
      }

      AVbrandData[attribute] = event;
    } else if (AVbrandData.customization[attribute] !== event.target.value) {
      AVbrandData.customization[attribute] = event.target.value;

      if (
        [
          "borderWidth",
          "cornerStyle",
          "suggestionDivider",
          "suggestionOptionBorderDisplay",
          "suggestionOptionBorderColor",
          "showSuggestionOptionButtons"
        ].includes(attribute)
      ) {
        saveChanges(_.pick(AVbrandData, "customization"), cb);
        setStylesheet();
      } else
        updateCustomTextTimeout = setTimeout(
          () => {
            AVbrandData.customization[attribute] = event.target.value;
            saveChanges(_.pick(AVbrandData, "customization"), cb)
          },
          1500
        );
    }
  },
};
