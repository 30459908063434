import React, { useState, useEffect } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import GridItem from 'components/Grid/GridItem.jsx';
import GridContainer from 'components/Grid/GridContainer.jsx';
import { Button, Typography } from "@material-ui/core";
import General from "helper/general.js";
import * as FullStory from '@fullstory/browser';

const useStyles = makeStyles({
  root: {
    minWidth: 275,
  },
  cardContent: {
    textAlign: "center",
    padding: "20px 40px",
  },
  title: {
    color: "blue",
    margin: "20px 0px",
  },
  actionLink: {
    margin: "10px 0px",
  },
});

const brand = General.getBrandData();
const logEvent = (name) => FullStory.event(name, {
  brand: brand.name,
  plan_name: brand.plan_name,
  platform: 'shopify'
});

const OrderProtection = (props) => {
  const classes = useStyles();

  const [enabled, setEnabled] = useState(false);

  useEffect(() => logEvent('Order Protection tab visit'));

  return (
    <GridContainer justifyContent='center'>
      <GridItem xs={10} sm={10} md={6}>
        <Card className={classes.root}>
          <CardContent className={classes.cardContent}>
            <Typography variant="h5" component="h2" className={classes.title}>
              Protect Against Shipping Issues At No Cost With <a href='https://orderprotection.com/av-inbound' target='_blank' rel='noopener noreferrer'>OrderProtection.com</a>
            </Typography>
            <GridContainer justifyContent='center'>
              <GridItem xs={12}>
                <Typography variant="body1" component="p">
                  Why add <a href='https://orderprotection.com/av-inbound' target='_blank' rel='noopener noreferrer' onClick={() => logEvent('Order Protection header link click')}>OrderProtection.com</a> through Address Validator?
                </Typography>
                <br />
              </GridItem>
              <GridItem xs={6}>
                <b>Protect your orders</b>
                <br />
                <Typography variant="body1" component="p">
                  69% of customers are willing to buy shipping protection.
                </Typography>
              </GridItem>
              <GridItem xs={6}>
                <b>Protect your brand</b>
                <br />
                <Typography variant="body1" component="p">
                  40%+ of people leave negative reviews after a bad shipping experience.
                </Typography>
              </GridItem>
              <GridItem xs={12}>
                <br />
                <Typography variant="body1" component="p">
                  <a href='https://orderprotection.com/av-inbound' target='_blank' rel='noopener noreferrer' onClick={() => logEvent('Order Protection body link click')}>OrderProtection.com</a> is a shipping insurance provider and Address Validator partner that protects customers against shipping issues like damage, theft, and more. Their service is completely free for merchants and is up to the customer to purchase for each order. 
                </Typography>
                <br />
              </GridItem>
            </GridContainer>
            {
              enabled
              ?
              <Button 
                variant='contained' color='primary' size='large'                
                onClick={() => {
                  window.open('https://orderprotection.com/av-inbound');
                  logEvent('Order Protection Calendly click');
                }}
              >
                Schedule time to activate integration
              </Button>
              :
              <Button variant='contained' color='primary' size='large' onClick={() => {
                setEnabled(true);
                logEvent('Order Protection Enable clicked')
              }}>
                Enable Order Protection
              </Button>
            }
          </CardContent>
        </Card>
      </GridItem>
    </GridContainer>
  );
};

export default OrderProtection;
