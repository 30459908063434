import React from "react";
import CircularProgress from "@material-ui/core/CircularProgress";

import "../assets/css/promptspreview.scss";

const content = (props) => {
  if (props.successMessage) {
    return (
      <div className="p-success">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 14 14"
          focusable="false"
          aria-hidden="true"
          className="p-check"
        >
          <circle cx="7" cy="7" r="5.6"></circle>
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M9.5 5.2 6.914 9.092a.4.4 0 0 1-.617.061L4.5 7.35"
          ></path>
        </svg>
        <div className="p-success-content">
          <div>Message</div>
          <div>{props.textValue}</div>
        </div>
      </div>
    );
  }

  if (props.validating) {
    return (
      <div className="p-prompt-box">
        <div className="p-validating">
          <p style={{ marginTop: 0 }}>{props.textValue}</p>
          <CircularProgress size={20} />
        </div>
      </div>
    );
  }

  return (
    <div className="p-prompt-box">
      {props.suggestion ? null : <h2 className="p-heading1">Warning</h2>}
      <span className={`p-message ${props.suggestion ? "suggestion" : ""}`}>
        {props.textValue || "MESSAGE"}
      </span>
      <div className="p-addressInput">
        <span
          className={`p-addressLine ${props.suggestion ? "suggestion" : ""}`}
        >
          400 Howard
        </span>
        <span
          className={`p-addressLine ${props.suggestion ? "suggestion" : ""}`}
        >
          San Francisco
        </span>
        <span className="p-addressLine">CA</span>
        <span className="p-addressLine">94105</span>
      </div>
      {props.suggestion ? null : (
        <div className="p-info">
          <span className="p-infoMessage">If yes, proceed to submit form.</span>
        </div>
      )}
    </div>
  );
};

function UIExtPromptsPreview(props) {
  return (
    <div className="p-outer">
      <p className="p-title">{props.label || "TITLE"}</p>
      {content(props)}
    </div>
  );
}

UIExtPromptsPreview.propTypes = {};

export default UIExtPromptsPreview;
