import React from 'react';
import { Tooltip, Typography } from '@material-ui/core/';
import HelpIcon from '@material-ui/icons/HelpOutline';


class MoreInfo extends React.Component {
  render() {
    return (
      <Tooltip title={<Typography color='inherit'>{this.props.note}</Typography>} placement='right-end' enterDelay={100} arrow>
        <HelpIcon className='help-icon' fontSize='inherit'/>
      </Tooltip>
    );
  }
}


export default MoreInfo;