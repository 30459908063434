import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import _ from 'lodash';
import axios from 'axios';
import moment from 'moment';
import General from 'helper/general.js';
import Loader from 'components/loader.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import GridContainer from 'components/Grid/GridContainer.jsx';
import Card from 'components/Card/Card.jsx';
import CardHeader from 'components/Card/CardHeader.jsx';
import CardBody from 'components/Card/CardBody.jsx';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, ResponsiveContainer, Tooltip } from 'recharts';
import { CloudDownload } from '@material-ui/icons/';
import AnalyticsStyle from 'assets/jss/analytics.jsx';
import ReactExport from 'react-data-export';
import { Button } from '@material-ui/core/';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const color = '#8884d8';

const formatDate = (date, days=0) => moment(date, 'YYYY-MM-DD').add(days, 'day').format('MMM D');


class Analytics extends React.Component {
  constructor(props) {
    super(props);
    this.state = { loading: true }
  }

  async componentDidMount () {
    const brand = General.getBrandData();

    const result = await axios({
      method: 'GET',
      url: `${General.serverURL}address_validator/api/admin_analytics_tab?shop=${brand.name}`,
      headers: { Authorization: `Bearer ${General.getJWT()}` }
    });

    // loop off analytics becuase there will always be orders processed, but not always orders blocked or updated
    let savesData = _.map(result.data.analytics, (analytic) => { 
      const targetSave = _.find(result.data.saves, (save) => formatDate(analytic.order_date) === formatDate(save.created_at, -1)) || { checkout_blocked: 0, thank_you_blocked: 0, checkout_updated: 0, thank_you_updated: 0, checkout_warned: 0, thank_you_warned: 0 };

      return {
        date: formatDate(analytic.order_date), 
        Blocked: targetSave.checkout_blocked + targetSave.thank_you_blocked,
        Standardized: targetSave.checkout_updated + targetSave.thank_you_updated,
        Updated: targetSave.checkout_warned + targetSave.thank_you_warned,
        order_count: analytic.order_count
      };
    });

    this.setState({
      order_count: _.sumBy(savesData, 'order_count'),
      blocked_count: _.sumBy(savesData, 'Blocked'),
      standardized_count: _.sumBy(savesData, 'Standardized'),
      updated_count: _.sumBy(savesData, 'Updated'),
      savesData,
      loading: false
    });
  }

  render() {
    const { classes } = this.props;

    if(this.state.loading)
      return <Loader />;
    else if(this.state.order_count === 0)
      return (
        <GridContainer justifyContent='center'>
          <GridItem xs={10} sm={10} md={6}>
            <Card>
              <CardBody>
                <h2 style={{ textAlign: 'center' }}>No orders in the past 3 months - come back later</h2>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      );
    else
      return (
        <GridContainer justifyContent='center'>
          <GridItem xs={12} md={6} lg={4}>
            <Card>
              <CardHeader color='primary'>
                <h3 className={classes.cardTitleWhite}>{this.state.order_count} orders processed</h3>
              </CardHeader>
              <CardBody>
                <ResponsiveContainer width='100%' height={250}>
                  <BarChart data={this.state.savesData}>
                    <CartesianGrid strokeDasharray='3 3' vertical={false} />
                    <XAxis dataKey='date' />
                    <YAxis width={45} />
                    <Tooltip />
                    <Bar dataKey='order_count' fill={color} />
                  </BarChart>
                </ResponsiveContainer>
                <small><i>Address Validator</i> only processes orders that go through the standard checkout processed in North America, EU, UK, Australia and New Zealand.</small>
              </CardBody>
            </Card>
          </GridItem>
          <GridItem xs={12} md={6} lg={4}>
            <Card>
              <CardHeader color='primary'>
                <h3 className={classes.cardTitleWhite}>{this.state.blocked_count} failed deliveries prevented</h3>
              </CardHeader>
              <CardBody>
                <ResponsiveContainer width='100%' height={250}>
                  <BarChart data={this.state.savesData}>
                    <CartesianGrid strokeDasharray='3 3' vertical={false} />
                    <XAxis dataKey='date' />
                    <YAxis width={30} />
                    <Tooltip />
                    <Bar dataKey='Blocked' fill={color} />
                  </BarChart>
                </ResponsiveContainer>
                <small>A failed delivery is prevented when a customer specifies an accurate address after being notified that the initial address is not deliverable (ie invalid house number and street combination or missing apt number).</small>
              </CardBody>
            </Card>
          </GridItem>
          <GridItem xs={12} md={6} lg={4}>
            <Card>
              <CardHeader color='primary'>
                <h3 className={classes.cardTitleWhite}>{this.state.standardized_count} shipping delays avoided</h3>
              </CardHeader>
              <CardBody>
                <ResponsiveContainer width='100%' height={250}>
                  <BarChart data={this.state.savesData}>
                    <CartesianGrid strokeDasharray='3 3' vertical={false} />
                    <XAxis dataKey='date' />
                    <YAxis width={30} />
                    <Tooltip />
                    <Bar dataKey='Standardized' fill={color} />
                  </BarChart>
                </ResponsiveContainer>
                <small>A shipping delay is avoided when a customer specifies an address with a typo or invalid postal code and uses the corrected address suggested by the app.</small>
              </CardBody>
            </Card>
          </GridItem>
          {
            this.state.updated_count > 0
            ?
            <GridItem xs={12} md={6} lg={4}>
              <Card>
                <CardHeader color='primary'>
                  <h3 className={classes.cardTitleWhite}>{this.state.updated_count} addresses rules enforced</h3>
                </CardHeader>
                <CardBody>
                  <ResponsiveContainer width='100%' height={250}>
                    <BarChart data={this.state.savesData}>
                      <CartesianGrid strokeDasharray='3 3' vertical={false} />
                      <XAxis dataKey='date' />
                      <YAxis width={30} />
                      <Tooltip />
                      <Bar dataKey='Updated' fill={color} />
                    </BarChart>
                  </ResponsiveContainer>
                  <small>An address rule is enforced when a customer specifies an address that cannot be processed (ie PO Box, too long, contains non-Latin characters) and corrects the issue after being notified. These rules are managed on the <i>Address Alerts</i> tab.</small>
                </CardBody>
              </Card>
            </GridItem>
            :
            null
          }
          <GridItem xs={12}>
            <div style={{ textAlign: 'center' }}>
              <ExcelFile element={<Button startIcon={<CloudDownload />} variant='contained' color='primary'>Export Data</Button>}>
                <ExcelSheet data={this.state.savesData} name='Analytics'>
                  <ExcelColumn label='Date' value='date'/>
                  <ExcelColumn label='Orders' value='order_count'/>
                  <ExcelColumn label='Failed deliveries prevented' value='Blocked'/>
                  <ExcelColumn label='Shipping delays avoided' value='Standardized'/>
                  <ExcelColumn label='Address rules enforced' value='Updated'/>
                </ExcelSheet>
              </ExcelFile>
            </div>
          </GridItem>
        </GridContainer>
      );
  }
}


Analytics.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(AnalyticsStyle)(Analytics);