import React from 'react';
import { Button, Link, Paper, Typography } from '@material-ui/core';
import GridItem from 'components/Grid/GridItem.jsx';
import GridContainer from 'components/Grid/GridContainer.jsx';
import Rating from '@material-ui/lab/Rating';
import CostIcon from '@material-ui/icons/MonetizationOnOutlined';
import TimeIcon from '@material-ui/icons/AccessTime';


export default function AppCard({ data }) {
  return (
    <Paper style={{ marginTop: 10, padding: 20 }}>
      <GridContainer justifyContent='flex-start' alignItems='center' >
        {
          data.assets.app_label_path
          ?
          <GridItem xs={12}>
            <img src={data.assets.app_label_path} alt={data.description} />
          </GridItem>
          :
          null
        }
        <GridItem xs={12}>
          <GridContainer justifyContent='flex-start' alignItems='center' >
            <GridItem xs={12}>
              <table style={{ borderSpacing: 5 }}>
                <tbody>
                  <tr>
                    <td>
                      <img src={data.assets.app_logo_path} alt={data.name} style={{ maxWidth: 90 }} />
                    </td>
                    <td style={{ width: 500 }}>
                      <Typography variant='h5'>
                        {data.name}
                      </Typography>
                      <Typography variant='subtitle1' color='textSecondary'>
                        {data.description}
                      </Typography>
                      <table>
                        <tbody>
                          <tr>
                            <td>
                              <Rating name='read-only' value={parseFloat(data.reviews_rating)} precision={0.5} readOnly />
                            </td>
                            <td>
                              <Typography variant='body2'>
                                {data.reviews_rating} ({data.reviews_count} reviews)
                              </Typography>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                    <td>
                      <Link href={data.key === 'pagefly' ? data.hrefs.app_store_path : data.hrefs.app_store_path + '?utm_campaign=address_validator'} target='_blank'>
                        <Button size='small' color='primary'>
                          Learn more
                        </Button>
                      </Link>
                    </td>
                    <td>
                      <Link href={data.key === 'pagefly' ? data.hrefs.direct_install_link_path : data.hrefs.direct_install_link_path + '&utm_campaign=address_validator'} target='_blank'>
                        <Button variant='contained' color='primary'>
                          Add app
                        </Button>
                      </Link>
                    </td>
                  </tr>
                </tbody>
              </table>
            </GridItem>
          </GridContainer>
        </GridItem>
        <GridItem xs={12}>
          <table>
            <tbody>
              <tr>
                <td>
                  <TimeIcon fontSize='small' />
                </td>
                <td>
                  <Typography variant='body2'>
                    Average setup time: <b>{data.setup_time}</b>
                  </Typography>
                </td>
              </tr>
            </tbody>
          </table>
        </GridItem>
        <GridItem xs={12}>
          <table>
            <tbody>
              <tr>
                <td>
                  <CostIcon fontSize='small' />
                </td>
                <td>
                  <Typography variant='body2'>
                    {data.pricing_description_text}
                  </Typography>
                </td>
              </tr>
            </tbody>
          </table>
        </GridItem>
      </GridContainer>
    </Paper>
  );
}