import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import General from '../helper/general.js';
import Button from 'components/CustomButtons/Button.jsx';
import MessageIcon from '@material-ui/icons/Message';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import moment from 'moment';

import headerLinksStyle from '../assets/jss/headerLinks.jsx';

// request review if: (1) no review yet, (2) created more than 10 days ago and (3) brand is not ignored
const requestReview = (brand) => !brand.reviewed && moment().diff(brand.created_at, 'days') > 10 && !brand.ignore;

class HeaderLinks extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      requestReview: null,
      showAdmin: false,
    }
  }

  componentDidMount() {
    if(this.props.app === 'address_validator') {
      const brand = General.getBrandData();
      if(brand)
        this.setState({ 
          showAdmin: brand.activated, 
          configured: brand.configured, 
          shopify_plus: brand.shopify_plus, 
          requestReview: requestReview(brand)
        });
    }
    else {
      const brand = General.getFDBrandData();
      if(brand)
        this.setState({ requestReview: requestReview(brand) });
    }
  }

  render() {
    const { classes } = this.props;
    
    if(window.innerWidth > 959 && this.props.app)
      return (
        <div>
          {
            this.state.showAdmin && this.props.app === 'address_validator' && this.state.shopify_plus
            ?
            <Button color='transparent' aria-label='Show Instructions' className={classes.buttonLink} onClick={() => this.props.openInstructions()}>
              <MenuBookIcon className={classes.icons} />
              <span className={classes.linkText}>Show Instructions</span>
            </Button>
            :
            null
          }
          <Button color='transparent' aria-label='Help Center' className={classes.buttonLink} onClick={() => window.open('https://support.apphub.com/en/collections/3501419-address-validator')}>
            <span className={classes.linkText}>Help Center</span>
          </Button>
          <Button color='transparent' aria-label='FAQ' className={classes.buttonLink} onClick={() => window.open('https://www.addressvalidator.com/faq.html')}>
            <span className={classes.linkText}>FAQ</span>
          </Button>
          {
            this.state.requestReview
            ?
            <a href={`https://apps.shopify.com/${this.props.app.replace('_', '-')}?reveal_new_review=true&utm_content=contextual&utm_medium=shopify&utm_source=admin`} target='_blank' rel='noopener noreferrer'>
              <Button color='transparent' aria-label='Leave Review' className={classes.buttonLink}>
                <MessageIcon className={classes.icons} />
                <span className={classes.linkText}>Leave Review</span>
              </Button>
            </a>
            :
            null
          }
        </div>
      );
    else
      return null;
  }
}

export default withStyles(headerLinksStyle)(HeaderLinks);