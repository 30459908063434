import { cardCategoryWhite, cardTitleWhite } from 'assets/jss/theme.jsx';

const analyticsStyle = theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
  },
  
  cardCategoryWhite,
  cardTitleWhite,
  table: {
    marginBottom: theme.spacing(0.5)
  },
  button: {
    margin: theme.spacing(0.5),
  },
});

export default analyticsStyle;
