import { cardCategoryWhite, cardTitleWhite } from 'assets/jss/theme.jsx';

const orderHistoryStyle = theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  table_head: {
    color: 'black',
    fontSize: 18,
    textAlign: 'center'
  },
  table_body: {
    fontSize: 16,
    textAlign: 'center'
  },
  center: {
    textAlign: 'center'
  },
  cardCategoryWhite,
  cardTitleWhite
});

export default orderHistoryStyle;
