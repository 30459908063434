import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import withStyles from '@material-ui/core/styles/withStyles';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Icon from '@material-ui/core/Icon';
import HeaderLinks from './headerLinks.jsx';
import General from '../helper/general.js';
import Toggle from './toggle.jsx';
import sidebarStyle from 'assets/jss/sidebar.jsx';
import Badge from '@material-ui/core/Badge';

class Sidebar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hideAdmin: true
    };
  }

  componentDidMount() {
    if(this.props.app === 'address_validator') {
      const brand = General.getBrandData();
      this.setState({ 
        hideAdmin: !brand.activated, 
        showCustomization: brand.activated && brand.shopify_plus,
        firstTime: brand.admin_email === null
      });
    }
  }

  render() {
    const activeRoute = (routeName) => this.props.location.pathname.includes(routeName);
    const { brand, classes, closeDrawer, handleDrawerToggle, open, routes } = this.props;

    let links = (
      <List className={classes.list}>
        {routes.map((prop, key) => {
          if(!this.props.showReCharge && prop.path === '/recharge')
            return null;
          else if (prop.redirect)
            return null;
          else if(prop.path === '/user')
            return null;

          let listItemClasses, whiteFontClasses;
          let bottomBtnClass = ' ';

          // Style buttons
          if(prop.path === '/powerups') {
            listItemClasses = classNames({ [' ' + classes.red]: true });
            whiteFontClasses = classNames({ [' ' + classes.whiteFont]: true });
          }
          else {
            listItemClasses = classNames({ [' ' + classes.orange]: activeRoute(prop.path) });
            whiteFontClasses = classNames({ [' ' + classes.whiteFont]: activeRoute(prop.path) });
          }

          return (
            <NavLink
              to={prop.path}
              className={bottomBtnClass + classes.item}
              activeClassName='active'
              key={key}
            >
              <ListItem button className={classes.itemLink + listItemClasses}>
                <ListItemIcon className={classes.itemIcon + whiteFontClasses}>
                  {prop.highlight ? (
                    <Badge color="secondary" variant="dot">
                      {typeof prop.icon === 'string' ? (
                        <Icon>{prop.icon}</Icon>
                      ) : (
                        <prop.icon />
                      )}
                    </Badge>
                  ) : (
                    <div>
                      {typeof prop.icon === 'string' ? (
                        <Icon>{prop.icon}</Icon>
                      ) : (
                        <prop.icon />
                      )}
                    </div>
                  )}
                </ListItemIcon>

                <ListItemText
                  primary={prop.sidebarName}
                  className={classes.itemText + whiteFontClasses}
                  disableTypography={true}
                />
              </ListItem>
            </NavLink>
          );
        })}
      </List>
    );
    let sidebarTitle = (
      <div className={classes.logo}>
        <a href={'http://' + brand.domain} target='_blank' rel='noopener noreferrer' className={classes.logoLink} onClick={() => closeDrawer() }>
          {brand.name}
        </a>
        <div className={classes.centerContainer}>
          <Toggle value={!this.props.paused} onChange={() => General.pauseResumeApp(this.props.paused ? 'resume' : 'pause', () => this.props.togglePause())} yes_label='Live' no_label='Paused' />
        </div>
        <div className={classes.centerContainer}>
          {
            this.props.paused 
            ? 
            <small className={classes.warningMessage}><b>No addresses are being processed</b></small>
            : 
            <small className={this.state.firstTime ? 'firstTimeNotice' : classes.activeMessage}><b>Addresses are being processed</b></small>
          }
        </div>
      </div>
    );

    return (
      <div>
        <Hidden mdUp implementation='css'>
          <Drawer
            variant='temporary'
            anchor='right'
            open={open}
            classes={{ paper: classes.drawerPaper }}
            onClose={handleDrawerToggle}
            ModalProps={{ keepMounted: true }} // Better open performance on mobile.
          >
            {sidebarTitle}
            <div className={classes.sidebarWrapper}>
              <HeaderLinks toggleDrawer={handleDrawerToggle} />
              {links}
            </div>
          </Drawer>
        </Hidden>
        <Hidden smDown implementation='css'>
          <Drawer
            anchor='left'
            variant='permanent'
            open
            classes={{ paper: classes.drawerPaper }}
          >
            {sidebarTitle}
            <div className={classes.sidebarWrapper}>{links}</div>
          </Drawer>
        </Hidden>
      </div>
    );
  }
}

Sidebar.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(sidebarStyle)(Sidebar);
