import React from 'react';
import { Dialog, DialogContent } from '@material-ui/core/';


class Notice extends React.Component {
  render() {
    return (
      <Dialog
        open={this.props.open}
        onClose={this.props.close}
        maxWidth='lg'
      >
        <DialogContent>
          <img src='https://d3eci0d3sspd26.cloudfront.net/address_validator_admin_assets/shopify_notice.png' alt='Shopify_Notice' style={{ width: '100%' }} />
        </DialogContent>
      </Dialog>
    );
  }
};


export default Notice;

