import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import _ from 'lodash';
import axios from 'axios';
import moment from 'moment';
import generateAddress from 'helper/generateAddress.js';
import General from 'helper/general.js';
import Loader from 'components/loader.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import GridContainer from 'components/Grid/GridContainer.jsx';
import Card from 'components/Card/Card.jsx';
import CardHeader from 'components/Card/CardHeader.jsx';
import CardBody from 'components/Card/CardBody.jsx';
import { Redirect } from 'react-router';
import { FormControl, Select, MenuItem, Button, Chip, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, InputBase, Table, TableBody, TableCell, TableFooter, TableHead, TablePagination, TableRow, Tooltip, Typography } from '@material-ui/core/';
import { Timeline, TimelineItem, TimelineSeparator, TimelineConnector, TimelineContent, TimelineDot, TimelineOppositeContent } from '@material-ui/lab/';
import { CloudDownload, FirstPage, KeyboardArrowLeft, KeyboardArrowRight, LastPage, Refresh, Search } from '@material-ui/icons/';
import ReactExport from 'react-data-export';


import OrderHistoryStyle from 'assets/jss/orderHistory.jsx';
import '../../assets/css/address.css';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const actionsStyles = theme => ({
  root: {
    flexShrink: 0,
    color: theme.palette.text.secondary,
    marginLeft: theme.spacing(2.5),
  },
});

const determineStatus = (order, suggestion, correct) => {
  if(correct)
    return {
      text: 'Correct Address',
      tooltip: 'Customer specified the correct address'
    }
  else if(order.status === 'updated') {
    if(suggestion) {
      if(order.precheckout) 
        return {
          text: 'Suggestion Used',
          tooltip: 'Customer used suggested address during checkout'
        };
      else 
        return {
          text: 'Suggestion Used',
          tooltip: 'Customer used selected address after checkout',
        };
    }
    else 
      return {
        text: 'Updated Address',
        tooltip: 'Customer updated address after checkout via form'
      };
  }
  else if(order.status === 'confirmed') 
    return {
      text: 'Confirmed',
      tooltip: 'Customer confirmed original address'
    };
  else if(!order.status) {
    if(suggestion)
      return {
        text: 'Unchanged',
        tooltip: 'Customer did not confirm original address or select suggested address'
      };
    else
      return {
        text: 'Unverified',
        tooltip: 'Address specified by customer could not be verifed and customer did not specify a new one'
      }; 
  }
}

const genTimelineItem = (time, content) => {
  return (
    <TimelineItem>
      <TimelineOppositeContent>
        <Typography color='textSecondary'>{time}</Typography>
      </TimelineOppositeContent>
      <TimelineSeparator>
        <TimelineDot />
        <TimelineConnector />
      </TimelineSeparator>
      <TimelineContent>
        {content}
      </TimelineContent>
    </TimelineItem>
  );
}


class TablePaginationActions extends React.Component {
  render() {
    const { classes, count, page, rowsPerPage, theme } = this.props;

    return (
      <div className={classes.root}>
        <IconButton
          onClick={(event) => this.props.onChangePage(event, 0)}
          disabled={page === 0}
          aria-label='First Page'
        >
          {theme.direction === 'rtl' ? <LastPage /> : <FirstPage />}
        </IconButton>
        <IconButton
          onClick={(event) => this.props.onChangePage(event, this.props.page - 1)}
          disabled={page === 0}
          aria-label='Previous Page'
        >
          {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
        </IconButton>
        <IconButton
          onClick={(event) => this.props.onChangePage(event, this.props.page + 1)}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label='Next Page'
        >
          {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
        </IconButton>
        <IconButton
          onClick={(event) => this.props.onChangePage(event, Math.max(0, Math.ceil(this.props.count / this.props.rowsPerPage) - 1))}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label='Last Page'
        >
          {theme.direction === 'rtl' ? <FirstPage /> : <LastPage />}
        </IconButton>
      </div>
    );
  }
}

TablePaginationActions.propTypes = {
  classes: PropTypes.object.isRequired,
  count: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  theme: PropTypes.object.isRequired,
};

const TablePaginationActionsWrapped = withStyles(actionsStyles, { withTheme: true })(
  TablePaginationActions,
);


class OrderHistory extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      count: null,
      count_loading: true,
      csvData: [],
      loading: true,
      results: [],
      page: 0,
      rowsPerPage: 50,
      redirect: false,
      searchField: '',
      searchResult: null,
      searchShow: false,
      showInfoBox: false,
      filterStatus: 'all',
      queryStatus: ''
    }
  }

  fetchOrders(page) {
    this.setState({ page: page, loading: true });
    axios({
      method: 'GET',
      url: `${General.serverURL}address_validator/api/admin_order_history?page=${page}&shop=${General.getBrandData().name}&status=${this.state.queryStatus}&rows=${this.state.rowsPerPage}`,
      headers: { Authorization: `Bearer ${General.getAVjwt()}` }
    })
    .then((result) => {
      let results = result.data.results;

      if(this.state.filterStatus === 'unverified')
        _.remove(results, (obj) => obj.correct || obj.suggestion || obj.modification);

      this.setState({ 
        loading: false, 
        results: results,
        csvData: _.map(results, (obj) => {
          let data = {
            store_order_id: obj.order.store_order_id,
            store_order_name: obj.order.store_order_name,
            status: determineStatus(obj.order, obj.suggestion, obj.correct).text,
            residential_commercial: obj.order.residential_commercial_ind,
            missing_apt: obj.order.missing_unit_num,
            original_company: obj.order.company,
            original_address_1: obj.order.address_1,
            original_address_2: obj.order.address_2,
            original_city: obj.order.city,
            original_state: obj.order.state,
            original_zip: obj.order.zip,
            original_country: obj.order.country,
          }
          
          // show company on original order object if it doesn't exist on the suggestions object
          if(obj.suggestion) {
            data.suggested_company = obj.suggestion.company || obj.order.company;
            data.suggested_address_1 = obj.suggestion.address_1;
            data.suggested_address_2 = obj.suggestion.address_2;
            data.suggested_city = obj.suggestion.city;
            data.suggested_state = obj.suggestion.state;
            data.suggested_zip = obj.suggestion.zip;
            data.suggested_country = obj.order.country;
          }

          return data;
        })
      })
    })
    .catch((err) => this.setState({ loading: false, redirect: true }));
  }

  componentDidMount() {
    if(General.localStorageAvailable())
      localStorage.setItem('address_validator_previous_route', '/order_history');

    this.fetchOrders(0);

    const brand = General.getBrandData();

    axios({
      method: 'GET',
      url: `${General.serverURL}address_validator/api/admin_order_count?shop=${General.getBrandData().name}`,
      headers: { Authorization: `Bearer ${General.getAVjwt()}` }
    })
    .then((result) => this.setState({ count_loading: false, count: result.data.count, domain: brand.domain }))
    .catch((err) => this.setState({ count_loading: false, redirect: true }));
  }

  searchOrder() {
    axios({
      method: 'POST',
      url: `${General.serverURL}address_validator/api/admin_order_search`,
      headers: { Authorization: `Bearer ${General.getAVjwt()}` },
      data: {
        shop: General.getBrandData().name,
        id: this.state.searchField.trim()
      }
    })
    .then((result) => this.setState({ searchShow: true, searchResult: result.data ? [result.data] : null }))
    .catch((err) => this.setState({ count_loading: false, redirect: true }));
  }

  generateTableHeader(classes) {
    return (
      <TableHead>
        <TableRow>
          <TableCell className={classes.table_head}>Date</TableCell>
          <TableCell className={classes.table_head}>Order Id</TableCell>
          <TableCell className={classes.table_head}>Shipping Address</TableCell>
          <TableCell className={classes.table_head}>Status</TableCell>
          <TableCell className={classes.table_head}>Notes</TableCell>
        </TableRow>
      </TableHead>
    );
  }

  displayOrder = (data, classes) => {
    let { order, suggestion, modification, correct } = data;
    const status = determineStatus(order, suggestion, correct);

    // add state to modification since none exist
    if(modification)
      modification.state = order.state;

    return (
      <TableRow key={(order.created_at) + Math.floor(Math.random() * 100)} hover={true}>
        <TableCell className={`${classes.table_body} fs-exclude`}>
          {moment(order.created_at).format('MMM D')}
          <br /><br />
          <Chip label='More Info' variant='outlined' size='small' onClick={() => this.setState({ showInfoBox: true, infoBoxData: data })} />
        </TableCell>
        <TableCell className={`${classes.table_body} fs-exclude`}>
          {order.store_order_id}
          <br />
          {order.store_order_name}
        </TableCell>
        <TableCell className={`${classes.table_body} fs-exclude`} dangerouslySetInnerHTML={{ __html: generateAddress(order, order.status === 'updated' ? suggestion || modification || order : order) }} />
        <TableCell className={`${classes.table_body} fs-exclude`}>
          <Tooltip title={status.tooltip} placement='top'>
            <Chip label={status.text} className={classes.chip} />
          </Tooltip>
          <br />
        </TableCell>
        <TableCell className={`${classes.table_body} fs-exclude`}>
          {order.missing_unit_num && !order.address_2 && !order.company ? <Chip color='secondary' label='Missing apt/unit #' className={classes.chip} /> : null}
          {order.residential_commercial_ind ? <Chip color='primary' label={order.residential_commercial_ind.split('_')[0]} className={classes.chip} /> : null}
        </TableCell>
      </TableRow>
    );
  }

  genDialogContent() {
    if(this.state.infoBoxData) {
      let { order, suggestion, modification, correct } = this.state.infoBoxData;
      
      // fill in attributes for suggestions + modifications to render properly
      if(suggestion)
        suggestion.country = order.country;
      if(modification) {
        modification.state = order.country;
        modification.country = order.country;
      }

      return (
        <DialogContent className='fs-exclude'>
          <Timeline>
            {
              genTimelineItem(
                moment(order.created_at).format('h:mm a'),
                <div>
                  <Typography>Customer an placed order with this shipping address:</Typography>
                  <div dangerouslySetInnerHTML={{ __html: generateAddress(order, order) }} style={{ textAlign: 'center', fontStyle: 'italic' }} />
                </div>
              )
            }
            {
              suggestion && !correct 
              ?
              genTimelineItem(
                moment(suggestion.created_at).format('h:mm a'),
                <div>
                  <Typography>Address Validator suggested this address of:</Typography>
                  <div dangerouslySetInnerHTML={{ __html: generateAddress(suggestion, suggestion) }} style={{ textAlign: 'center', fontStyle: 'italic' }} />
                </div>
              )
              :
              null
            }
            {
              suggestion && !correct && order.status
              ?
              genTimelineItem(
                moment(suggestion.updated_at).format('h:mm a'),
                order.status === 'confirmed'
                ?
                <Typography><b>Customer confirmed original address</b></Typography>
                :
                <Typography><b>Customer used suggested address</b></Typography>
              )
              :
              null
            }
            {
              !suggestion && !modification && !correct
              ?
              genTimelineItem(
                moment(order.created_at).format('h:mm a'),
                <Typography><b>Address Validator was not able to find a suggested correction and the customer did not specify a new address</b></Typography>
              )
              :
              null
            }
            {
              correct
              ?
              genTimelineItem(
                moment(order.created_at).format('h:mm a'),
                <Typography><b>The specified address was accurate</b></Typography>
              )
              :
              null
            }
            {
              modification
              ?
              genTimelineItem(
                moment(modification.created_at).format('h:mm a'),
                <div>
                  <Typography>Customer specified a new address of:</Typography>
                  <div dangerouslySetInnerHTML={{ __html: generateAddress(modification, modification) }} style={{ textAlign: 'center', fontStyle: 'italic' }} />
                </div>
              )
              :
              null
            }
          </Timeline>
        </DialogContent>
      );
    }
    else
      return null;
  }

  genDialogButton() {
    if(this.state.infoBoxData && this.state.infoBoxData.order && this.state.infoBoxData.order.store_order_id && this.state.domain)
      return (
        <DialogActions style={{ justifyContent: 'center' }}>
          <Button onClick={() => window.open(`https://${this.state.domain}/admin/orders/${this.state.infoBoxData.order.store_order_id}?orderListBeta=true`, '_blank')} color='primary'>
            View Shopify Order Object
          </Button>
        </DialogActions>
      );
    else
      return null;
  }

  changeNumRows(event) {
    this.setState({ rowsPerPage: event.target.value });
    setTimeout(() => this.fetchOrders(this.state.page), 100);
  }

  changeStatus(event) {
    this.setState({ filterStatus: event.target.value, queryStatus: event.target.value === 'all' ? '' : event.target.value });
    setTimeout(() => this.fetchOrders(this.state.page), 100);
  }

  render() {
    const { classes } = this.props;

    if(this.state.loading || this.state.count_loading)
      return <Loader />;
    else if(this.state.redirect)
      return <Redirect to='/user' />;
    else if(this.state.count + this.state.results.length === 0)
      return (
        <GridContainer justifyContent='center'>
          <GridItem xs={10} sm={10} md={6}>
            <Card>
              <CardBody>
                <h2 style={{ textAlign: 'center' }}>No orders in the past month - come back later</h2>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      );
    else if(this.state.searchShow)
      return (
        <GridContainer justifyContent='center'>
          <Dialog onClose={() => this.setState({ showInfoBox: false })} open={this.state.showInfoBox}>
            <DialogTitle>Order Information</DialogTitle>
            {this.genDialogContent()}
            {this.genDialogButton()}
          </Dialog>
          <GridItem xs={12} lg={10}>
            <Card>
              <CardHeader color='primary'>
                <h3 className={classes.cardTitleWhite}>Search Results</h3>
              </CardHeader>
              <CardBody>
              {
                this.state.searchResult
                ?
                <Table className={classes.table}>
                  {this.generateTableHeader(this.props.classes)}
                  <TableBody>
                    {_.map(this.state.searchResult, (data) => this.displayOrder(data, classes))}
                  </TableBody>
                </Table>
                :
                <Typography variant='h3' className={classes.center}>Order not found</Typography>
              }
              <div className={classes.center} style={{ marginTop: 15 }}>
                <Button 
                  startIcon={<Refresh />} 
                  variant='contained' 
                  color='default' 
                  onClick={() => this.setState({ searchField: '', searchResult: null, searchShow: false })}
                >
                  Go Back
                </Button>
              </div>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      );
    else
      return (
        <GridContainer justifyContent='center'>
          <Dialog onClose={() => this.setState({ showInfoBox: false })} open={this.state.showInfoBox}>
            <DialogTitle>Order Information</DialogTitle>
            {this.genDialogContent()}
            {this.genDialogButton()}
          </Dialog>
          <GridItem xs={12} lg={10}>
            <Card>
              <CardHeader color='primary'>
                <h3 className={classes.cardTitleWhite}>Recent orders processed</h3>
              </CardHeader>
              <CardBody>
                <GridContainer justifyContent='center'>
                  <GridItem xs={12} md={4}>
                    <form>
                      <InputBase
                        className={classes.input}
                        placeholder='Search for order by ID'
                        inputProps={{ 'aria-label': 'Search for order by ID' }}
                        value={this.state.searchField}
                        onChange={(event) => this.setState({ searchField: event.target.value })}
                        variant='outlined'
                        style={{ borderBottom: '1px solid #A9A9A9' }}
                      />
                      <Button 
                        startIcon={<Search />} 
                        variant='contained' 
                        type='submit'
                        color='primary' 
                        style={{ marginLeft: 3 }} 
                        disabled={!this.state.searchField} 
                        onClick={(event) => { event.preventDefault(); this.searchOrder(); }}
                      >
                        Search
                      </Button>
                    </form>
                  </GridItem>
                  <GridItem xs={12} md={2}>
                    <FormControl>
                      <Select
                        labelId='status-selector-label'
                        id='status-selector'
                        value={this.state.filterStatus}
                        onChange={(event) => this.changeStatus(event)}
                      >
                        <MenuItem value={'all'}>All Orders</MenuItem>
                        <MenuItem value={'confirmed'}>Confirmed Orders</MenuItem>
                        <MenuItem value={'updated'}>Updated Orders</MenuItem>
                        <MenuItem value={'unverified'}>Unverified Orders</MenuItem>
                      </Select>
                    </FormControl>
                  </GridItem>
                  <GridItem xs={12} md={2}>
                    <FormControl>
                      <Select
                        labelId='status-selector-label'
                        id='status-selector'
                        value={this.state.rowsPerPage}
                        onChange={(event) => this.changeNumRows(event)}
                      >
                        <MenuItem value={25}>25 per page</MenuItem>
                        <MenuItem value={50}>50 per page</MenuItem>
                        <MenuItem value={100}>100 per page</MenuItem>
                        <MenuItem value={250}>250 per page</MenuItem>
                      </Select>
                    </FormControl>
                  </GridItem>
                  <GridItem xs={12} md={4}>
                  {
                    this.state.csvData.length > 0
                    ?
                    <ExcelFile element={<Button startIcon={<CloudDownload />} variant='contained' color='primary' style={{ float: 'right' }}>Export Data</Button>}>
                      <ExcelSheet data={this.state.csvData} name='Orders'>
                        <ExcelColumn label='Order ID' value='store_order_id'/>
                        <ExcelColumn label='Order Name' value='store_order_name'/>
                        <ExcelColumn label='Status' value='status'/>
                        <ExcelColumn label='Address Type' value='residential_commercial'/>
                        <ExcelColumn label='Missing Apt #' value={(col) => col.missing_apt ? 'x' : ''}/>
                        <ExcelColumn label='Original Company' value='original_company'/>
                        <ExcelColumn label='Original Address' value='original_address_1'/>
                        <ExcelColumn label='Original Apt' value='original_address_2'/>
                        <ExcelColumn label='Original City' value='original_city'/>
                        <ExcelColumn label='Original State' value='original_state'/>
                        <ExcelColumn label='Original Zip' value='original_zip'/>
                        <ExcelColumn label='Original Country' value='original_country'/>
                        <ExcelColumn label='Suggested Company' value='suggested_company'/>
                        <ExcelColumn label='Suggested Address' value='suggested_address_1'/>
                        <ExcelColumn label='Suggested Apt' value='suggested_address_2'/>
                        <ExcelColumn label='Suggested City' value='suggested_city'/>
                        <ExcelColumn label='Suggested State' value='suggested_state'/>
                        <ExcelColumn label='Suggested Zip' value='suggested_zip'/>
                        <ExcelColumn label='Suggested Country' value='suggested_country'/>
                      </ExcelSheet>
                    </ExcelFile>
                    :
                    null
                  }
                  </GridItem>
                  <GridItem xs={12} style={{ marginTop: 15, overflowX: 'auto' }}>
                    <Table className={classes.table}>
                      {this.generateTableHeader(this.props.classes)}
                      <TableBody>
                        {_.map(this.state.results, (data) => this.displayOrder(data, classes))}
                      </TableBody>
                      {
                        this.state.count > this.state.results.length
                        ?
                        <TableFooter>
                          <TableRow>
                            <TablePagination
                              colSpan={5}
                              count={this.state.count}
                              rowsPerPage={this.state.rowsPerPage}
                              page={this.state.page}
                              onChangePage={(event, page) => this.fetchOrders(page)}
                              rowsPerPageOptions={[]}
                              ActionsComponent={TablePaginationActionsWrapped}
                            />
                          </TableRow>
                        </TableFooter>
                        :
                        null
                      }
                    </Table>
                  </GridItem>
              </GridContainer>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      );
  }
}

OrderHistory.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(OrderHistoryStyle)(OrderHistory);