import React from 'react';
import { SketchPicker } from 'react-color';

let delayFunction;


class ColorPicker extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hover: false,
      showPicker: false
    };
  }

  componentDidMount() {
    this.setState({ color: this.props.color });
  }

  colorChangeComplete(color, event) {
    clearTimeout(delayFunction);
    delayFunction = setTimeout(() => this.props.onChangeComplete(color), 500);
  }

  render() {
    const styles = {
      wrapper: {
        height: '50px',
        width: '100%'
      },
      swatch: {
        background: '#fff',
        border: `1px ${this.state.hover ? 'solid black' : ' dotted #a9a9a9'}`,
        borderRadius: '2px',
        cursor: 'pointer',
        display: 'inline-block',
        padding: '8px',
        width: 'calc(100% - 16px)',
      },
      color: {
        background: this.props.color,
        border: `1px ${this.state.hover ? 'solid black' : ' dotted #a9a9a9'}`,
        borderRadius: '2px',
        height: '30px',
        textAlign: 'center',
        width: '100%',
      },
      text: {
        color: 'white',
        fontSize: '1vw',
        fontWeight: 'bold',
        lineHeight: '30px',
        WebkitTextStroke: '0.2px black'
      },
      popover: {
        position: 'absolute',
        zIndex: '50',
      },
      cover: {
        bottom: '0px',
        left: '0px',
        position: 'fixed',
        right: '0px',
        top: '0px',
      }
    };

    return (
      <div style={styles.wrapper} onMouseEnter={() => this.setState({ hover: true })} onMouseLeave={() => this.setState({ hover: false })}>
        <div style={ styles.swatch } onClick={ () => this.setState({ showPicker: !this.state.showPicker, hover: false }) }>
          <div style={ styles.color }>
            <span style={styles.text}>{this.state.hover ? 'Click to edit' : null}</span>
          </div>
        </div>
        {
          this.state.showPicker 
          ? 
          <div style={ styles.popover }>
            <div style={ styles.cover } onClick={ () => this.setState({ showPicker: false, hover: false }) }/>
            <SketchPicker 
              color={ this.state.color } 
              onChange={ (color, event) => this.setState({ color: color.hex }) }
              onChangeComplete={ (color, event) => this.colorChangeComplete(color, event) }
              disableAlpha={true} 
            />
          </div> 
          : 
          null 
        }
      </div>
    );
  }
}


export default ColorPicker;