import React from 'react';
import _ from 'lodash';
import General from '../helper/general.js';


class FormButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    const brand = General.getBrandData();
    this.setState(Object.assign(
      _.pick(brand, [ 'customEmailLink', 'customize_email_link', 'name', 'update_link_email']), 
      brand.customization
    ));
  }


  render() {
    return (
      <a href={this.state.customize_email_link && this.state.customEmailLink ? this.state.customEmailLink : ('https://verify.addressvalidator.com/verify_address?shop=' + this.state.name + '&type='+ (this.props.type ? 'missing_unit_num' : 'invalid_address') + '&email=' + this.state.update_link_email)} target='_blank' rel='noopener noreferrer'>
        <button style={{ 
          backgroundColor: this.state.colorButtonBackground, 
          color: this.state.colorButtonText, 
          borderColor: 
          this.state.colorButtonBorder, 
          borderRadius: 6, 
          borderWidth: 2, 
          fontSize: 16, 
          padding: 18, 
          cursor: 'pointer' 
        }}>
          {this.props.buttonText || this.state.updateConfirmButton}
        </button>
      </a>
    );
  }
};


export default FormButton;