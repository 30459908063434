import { primaryColor } from "assets/jss/theme.jsx";

const ValidationRulesStyle = (theme) => ({
  toggleSelected: {
    "&&": {
      backgroundColor: '#114db2',
      color: 'white',
    },
    "&&:hover": {
      backgroundColor: '#114db2',
    },
  },
  toggleRoot: {
    "&&": {
      backgroundColor: primaryColor,
      color: 'white',
    },
    "&&:hover": {
      backgroundColor: '#114db2',
    },
  },
});

export default ValidationRulesStyle;
