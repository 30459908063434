import { 
  cardCategoryWhite, 
  cardTitle,
  cardTitleWhite, 
  primaryColor,
  primaryDarkColor,
  center,
  left
} from 'assets/jss/theme.jsx';


const PromptsStyle = theme => ({
  cardCategoryWhite,
  cardTitle,
  cardTitleWhite,
  cardDescription: {
    fontSize: '16px',
    marginBottom: '0px',
    textAlign: 'center'
  },
  cardDescriptionLeft: {
    fontSize: '16px',
    marginBottom: '0px',
    textAlign: 'left'
  },
  center,
  left,
  link: {
    color: primaryColor,
    cursor: 'pointer',
    '&:hover': {
      color: primaryDarkColor
    }
  }
});

export default PromptsStyle;