import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import _ from 'lodash';
import General from 'helper/general.js';
import GridItem from 'components/Grid/GridItem.jsx';
import GridContainer from 'components/Grid/GridContainer.jsx';
import Card from 'components/Card/Card.jsx';
import CardHeader from 'components/Card/CardHeader.jsx';
import CardBody from 'components/Card/CardBody.jsx';
import { Button, FormControl, FormControlLabel, InputLabel, MenuItem, Paper, Select, Switch, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from '@material-ui/core/';
import ColorPicker from '../../components/colorPicker.jsx';
import AlertsStyles from 'assets/jss/alerts.jsx';
import MoreInfo from '../../components/moreInfo.jsx';
import Loader from 'components/loader.jsx';
import Snackbar from '../../components/snackBar.jsx';
import DescriptionBox from 'components/descriptionBox.jsx';


let previewTimeout;
const trimLowerCase = (value) => value.replace(/[^\w\s]| /gi, '').toLowerCase();
const us_states = ['Alabama', 'Alaska', 'Arizona', 'Arkansas', 'California', 'Colorado', 'Connecticut', 'Delaware', 'District of Columbia', 'Florida', 'Georgia', 'Hawaii', 'Idaho', 'Illinois', 'Indiana', 'Iowa', 'Kansas', 'Kentucky', 'Louisiana', 'Maine', 'Maryland', 'Massachusetts', 'Michigan', 'Minnesota', 'Mississippi', 'Missouri', 'Montana', 'Nebraska', 'Nevada', 'New Hampshire', 'New Jersey', 'New Mexico', 'New York', 'North Carolina', 'North Dakota', 'Ohio', 'Oklahoma', 'Oregon', 'Pennsylvania', 'Rhode Island', 'South Carolina', 'South Dakota', 'Tennessee', 'Texas', 'Utah', 'Vermont', 'Virginia', 'Washington', 'West Virginia', 'Wisconsin', 'Wyoming', 'Puerto Rico', 'Armed Forces Americas', 'Armed Forces Europe', 'Armed Forces Pacific'];
const colors = [
 { key: 'colorWarningBackground', label: 'Background' }, 
 { key: 'colorWarningBorder', label: 'Border' }, 
 { key: 'colorWarningText', label: 'Text' }
];
let alerts = [
  {
    title: 'General Message',
    description: 'Enable this option to display a general message above the address form.',
    help: 'You may want to add a message like "Please double check the shipping address to ensure prompt delivery"',
    toggleValue: 'general_message',
    labelValue: 'generic_message',
    check: (input) => true
  },
  {
    title: 'PO Boxes',
    description: 'PO Boxes',
    help: 'Couriers like FedEx and UPS cannot ship to PO Boxes',
    toggleValue: 'disable_po_boxes',
    labelValue: 'textPOBox',
    check: (input) => /box\d+|pobox/i.test(trimLowerCase(input)) && !/\d+box/i.test(trimLowerCase(input))
  },
  {
    title: 'USPS gopost',
    description: 'USPS gopost',
    help: 'Couriers like FedEx and UPS cannot ship to USPS gopost',
    toggleValue: 'usps_gopost',
    labelValue: 'uspsGopost',
    check: (input) => /\d+gopost/i.test(trimLowerCase(input))
  },
  {
    title: 'Highway Contract Route',
    description: 'Highway Contract Route',
    help: 'Highway Contract Routes are used in rural locations and some couriers cannot deliver to them',
    toggleValue: 'highway_contract_route',
    labelValue: 'highwayContractRoute',
    check: (input) => /hc\d+/i.test(trimLowerCase(input))
  },
  {
    title: 'Private Mailboxes',
    description: 'Private Mailboxes',
    toggleValue: 'disable_pmb',
    labelValue: 'textPMB',
    check: (input) => /pmb\d+/i.test(trimLowerCase(input))
  },
  {
    title: 'Parcel Lockers / Collects',
    description: 'Parcel Lockers or Parcel Collects',
    help: 'This may be applicable if you ship to Australia and/or New Zealand',
    toggleValue: 'disable_parcel_box',
    labelValue: 'textParcelBox',
    check: (input) => /parcellocker|parcelcollect/i.test(trimLowerCase(input))
  },
  {
    title: 'DHL Packstations',
    description: 'DHL Packstations',
    help: 'This may be applicable if you ship to Austria and/or Germany',
    toggleValue: 'disable_packstation',
    labelValue: 'textPackstation',
    check: (input) => /packstation|postfiliale/i.test(trimLowerCase(input)),
  },
  {
    title: 'Separate DHL Packstation #',
    description: 'DHL Packstation addresses with the station # on the same line as the term "Packstation"',
    help: 'Some 3PLs require the term "Packstation" to be on a different line than the Packstation #',
    toggleValue: 'separate_packstation',
    labelValue: 'textSeparatePackstation',
    check: (input) => /(packstation|postfiliale)\d+/i.test(trimLowerCase(input))
  },
  {
    title: 'Missing House Number',
    description: 'addresses with missing house #',
    toggleValue: 'address1_num_check',
    labelValue: 'textStreetNum',
    check: (input) => !/\d/.test(input) && !/packstation|postfiliale/.test(input) && input.length >= 6
  },
  {
    title: 'Missing Street Name',
    description: 'addresses with missing street names',
    toggleValue: 'street_check',
    labelValue: 'textStreet',
    check: (input) => input && input.replace(/\d/g, '').length <= 4
  },
  {
    title: 'Extra Long Addresses',
    description: 'extra long addresses',
    help: 'Some 3PLs and couriers have character limits on the address_1, address_2, city and company fields.',
    toggleValue: 'config_address_length',
    labelValue: 'textAddressLength',
    check: (input, lengthLimit) => input.length > lengthLimit
  },
  {
    title: 'Emojis',
    description: 'addresses with emojis',
    help: 'Some 3PLs and couriers cannot parse emojis. This rule is applied to the address_1, address_2, city and company fields.',
    toggleValue: 'emoji_check',
    labelValue: 'textEmojiCheck',
    check: (input) => /([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/.test(input)
  },
  {
    title: 'Non-Latin Characters',
    description: 'addresses with non-latin characters. Accented characters like à, è or ö are not allowed. Do not use option if you ship to European addresses',
    help: 'Some 3PLs and couriers cannot parse non-latin characters. Do not use this option if you ship to European addresses. This rule is applied to the address_1, address_2, city and company fields.',
    toggleValue: 'latin_check',
    labelValue: 'textLatinOnly',
    // eslint-disable-next-line
    check: (input) => (/[^\u0000-\u007f]/.test(input) || /[~!$%\^&*+=\[\]\\;{}|\\":<>\?@]/.test(input))
  },
  {
    title: 'Non-Latin Characters - Allow Accents',
    description: 'addresses with non-latin characters. Accented characters like à, è or ö are allowed',
    help: 'Some 3PLs and couriers cannot parse non-latin characters. This rule is applied to the address_1, address_2, city and company fields.',
    toggleValue: 'latin_check_allow_accents',
    labelValue: 'textLatinOnlyAllowAccents',
    // eslint-disable-next-line
    check: (input) => (/[^\u0000-\u007f\p{Mn}\p{P}À-ÿ]/.test(input) || /[~!$%\^&*+=\[\]\\;{}|\\":<>\?@]/.test(input))
  },
  {
    title: 'Noncontiguous US States',
    description: 'Alaska, Guam, Hawaii or Puerto Rico',
    help: 'Some couriers cannot to noncontiguous US states (Alaska, Guam, Hawaii or Puerto Rico)',
    toggleValue: 'noncontiguous_states',
    labelValue: 'textNoncontinguousStates',
    check: (input) => ['Alaska', 'Hawaii', 'Puerto Rico'].indexOf(input) !== -1
  },
  {
    title: 'US Military Addresses',
    description: 'military addresses',
    help: 'Some couriers cannot to US military addresses (Armed Forces Americas, Armed Forces Europe, Armed Forces Pacific)',
    toggleValue: 'military_block',
    labelValue: 'textMilitaryBlock',
    check: (input) => ['Armed Forces Americas', 'Armed Forces Europe', 'Armed Forces Pacific'].indexOf(input) !== -1
  },
  {
    title: 'Numeric Postal Code',
    description: 'non-numeric postal codes. Do not use this option if you ship to countries outside of the US',
    help: 'For example, this may be applicable if you only ship to the US where the zip code is purely numeric',
    toggleValue: 'zip_num_only',
    labelValue: 'textNumOnlyZip',
    check: (input) => input.length > 3 && /[^$,.\d]/.test(input.replace('-', ''))
  },
  {
    title: 'NZ Rural Addresses',
    description: 'rural addresses in New Zealand',
    toggleValue: 'nz_rural_check',
    labelValue: 'textNZRural',
    check: (input) => /rd\d+/.test(input.toLowerCase().replace(/ |:/g, ''))
  }
];
const characterLimits = [10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60];
const genericMessageTypes = [{ type: 'info', label: 'Info Message' }, { type: 'critical', label: 'Critical Message' }, { type: 'warning', label: 'Warning Message' }]

class Alerts extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      open: false,
      message: null,
      calcAddressField: '',
      calcZipField: '',
      testAddressField: '',
      testStateField: '',
      testZipField: ''
    };
  }

  componentDidMount() {
    const brand = General.getBrandData();
    General.setStylesheet();

     // only show these General Message + Numeric Postal Code for Shopify Plus merchants since they only load on the checkout
    if(!brand.shopify_plus)
      _.remove(alerts, (obj) => ['General Message', 'Numeric Postal Code'].includes(obj.title));

    this.setState(Object.assign(
      brand.customization,
      _.pick(brand, ['disable_po_boxes', 'disable_parcel_box', 'disable_packstation', 'separate_packstation', 'address1_num_check', 'street_check', 'config_address_length', 'latin_check', 'latin_check_allow_accents', 'emoji_check', 'noncontiguous_states', 'zip_num_only', 'nz_rural_check', 'disable_pmb', 'general_message', 'po_box_scope', 'military_block', 'address_length_limit', 'prompt_alerts', 'block_orders', 'precheckout_popup', 'usps_gopost', 'highway_contract_route', 'shopify_plus']),
      { loading: false }
    ));
  }

  promptButtonStyle() {
    return { backgroundColor: this.state.colorButtonBackground, borderColor: this.state.colorButtonBorder, color: this.state.colorButtonText };
  }

  updateState(key, value) {
    let newState = this.state;
    newState[key] = value;
    newState.open = false;
    this.setState(newState);
  }

  changeDefaultValue(key, event) {
    this.updateState(key, event.target.value);

    General.changeText(
      event,
      key, 
      (type) => this.setState({ open: true, alert_type: type })
    );
  }

  changeText(event, key, label) {
    this.updateState(key, event.target.value);

    General.changeText(
      event,
      key,
      (type) => this.setState({ open: true, alert_type: type })
    );
  }

  changeColor(color, key) {
    this.updateState(key, color.hex);

    General.changeColor(color, key, (type) => this.setState({ open: true, alert_type: type }));
  }

  selectLooseOption() {
    this.setState({ prompt_alerts: false, block_orders: false });
    General.setBrandValue('prompt_alerts', false, (type) => this.setState({ open: true, alert_type: type }));
    General.setBrandValue('block_orders', false, (type) => this.setState({ open: true, alert_type: type }));
  }

  selectMediumOption() {
    this.setState({ prompt_alerts: true, block_orders: false });
    General.setBrandValue('prompt_alerts', true, (type) => this.setState({ open: true, alert_type: type }));
    General.setBrandValue('block_orders', false, (type) => this.setState({ open: true, alert_type: type }));
  }
  
  selectStrictOption() {
    this.setState({ prompt_alerts: true, block_orders: true });
    General.setBrandValue('prompt_alerts', true, (type) => this.setState({ open: true, alert_type: type }));
    General.setBrandValue('block_orders', true, (type) => this.setState({ open: true, alert_type: type }));
  }

  toggleAlert(key) {
    let newState = this.state;
    newState[key] = !newState[key];
    newState['open'] = false;

    this.setState(newState);

    General.setBrandValue(key, newState[key], (type) => this.setState({ open: true, alert_type: type }));
  }

  generateSampleAlert(obj, type) {
    const checkFunction = (obj) => {
      if(obj.toggleValue === 'config_address_length')
        return obj.check(this.state.calcAddressField, this.state.address_length_limit);
      else if(['noncontiguous_states', 'military_block'].indexOf(obj.toggleValue) !== -1)
        return obj.check(this.state.testStateField);
      else if(obj.toggleValue === 'zip_num_only')
        return obj.check(this.state.calcZipField);
      else
        return obj.check(this.state.calcAddressField);
    };

    if(this.state[obj.toggleValue] && this.state[obj.labelValue] && checkFunction(obj)) {
      if(type === 'div')
        return <div key={obj.labelValue} className={this.props.classes.warningBox} 
                style={{ color: this.state.colorWarningText, backgroundColor: this.state.colorWarningBackground, borderColor: this.state.colorWarningBorder }} 
                dangerouslySetInnerHTML={{ __html: this.state[obj.labelValue] }} />;
      else if(type === 'text' && obj.toggleValue !== 'general_message')
        return <p key={obj.labelValue} dangerouslySetInnerHTML={{ __html: this.state[obj.labelValue] }} style={{ margin: 0 }} />;
    }
    else
      return null;
  }

  generateSamplePromptAlerts() {
    let triggeredAlerts = _.map(alerts, (obj) => this.generateSampleAlert(obj, 'text'))
    _.remove(triggeredAlerts, (alert) => !alert);

    if(triggeredAlerts.length)
      return triggeredAlerts;
    else
      return 'Example warning message';
  }

  render() {
    const { classes, view } = this.props;

    const isUsingCheckoutLiquid = view === "Checkout Liquid";

    if(this.state.loading)
      return <Loader />;
    else
      return (
        <div>
          <Snackbar
            alert_type={this.state.alert_type}
            close={() => this.setState({ open: false, alert_type: null })}
            open={this.state.open}
          />
          <GridContainer justifyContent='center'>
            <GridItem xs={12} md={10}>
              <DescriptionBox 
                classes={classes}
                content={this.state.shopify_plus ? 'This allows you to warn against or block specific types of addresses that cannot be delivered. These alerts are displayed during checkout whenever address rules are violated.' : 'This allows you to prompt customers for alternative addresses when undeliverable ones are detected (ie PO Boxes or addresses with non-Latin characters)'}
                title='What are these address rules?'
              />
            </GridItem>
            {
            this.state.shopify_plus 
            ? 
            <GridItem xs={12} md={10} lg={6}>
              <Card>
                <CardHeader color='primary'>
                  <h3 className={classes.cardTitleWhite}>Customizations</h3>
                </CardHeader>
                <CardBody>
                  <GridContainer justifyContent='center'>
                    {
                      isUsingCheckoutLiquid ?
                        _.map(colors, (obj) => (
                          <GridItem xs={12} md={4} key={obj.key}>
                            <Typography className={classes.colorTitle}>{obj.label}</Typography>
                            <ColorPicker color={this.state[obj.key]} onChangeComplete={ (color) => this.changeColor(color, obj.key) } />
                          </GridItem>
                        ))
                        :
                        null
                    }
                    {
                      this.state.precheckout_popup
                      ?
                      <GridItem xs={12}>
                        <p className={classes.cardDescription}>How do you want these alerts displayed?</p>
                        <br />
                        <TableContainer>
                          <Table className={classes.table} aria-labelledby='customization options' size='small'>
                            <TableHead>
                              <TableRow>
                                <TableCell className={classes.thead}></TableCell>
                                <TableCell className={classes.thead}>Mode</TableCell>
                                <TableCell className={classes.thead} align='center'>Inline Alerts <MoreInfo note='Render alerts above the Customer Information form if the shipping address violates any rules' /></TableCell>
                                <TableCell className={classes.thead} align='center'>Modal Alerts <MoreInfo note='Render modal alerts if the customer attempts to proceed with a shipping address that violates any rules' /></TableCell>
                                <TableCell className={classes.thead} align='center'>Block Orders <MoreInfo note='Block addresses that violates any rules from proceeding' /></TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              <TableRow className={!this.state.prompt_alerts && !this.state.block_orders ? classes.table_selected : ''} onClick={() => this.selectLooseOption()}>
                                <TableCell><Button variant='contained' size='small' disabled={!this.state.prompt_alerts && !this.state.block_orders}>Select</Button></TableCell>
                                <TableCell>Loose</TableCell>
                                <TableCell align='center' className={classes.table_cell_green}>✓</TableCell>
                                <TableCell align='center' className={classes.table_cell_green}></TableCell>
                                <TableCell align='center' className={classes.table_cell_green}></TableCell>
                              </TableRow>
                              <TableRow className={this.state.prompt_alerts && !this.state.block_orders ? classes.table_selected : ''} onClick={() => this.selectMediumOption()}>
                                <TableCell><Button variant='contained' size='small' disabled={this.state.prompt_alerts && !this.state.block_orders}>Select</Button></TableCell>
                                <TableCell>Medium</TableCell>
                                <TableCell align='center' className={classes.table_cell_green}>✓</TableCell>
                                <TableCell align='center' className={classes.table_cell_green}>✓</TableCell>
                                <TableCell align='center' className={classes.table_cell_green}></TableCell>
                              </TableRow>
                              <TableRow className={this.state.prompt_alerts && this.state.block_orders ? classes.table_selected : ''} onClick={() => this.selectStrictOption()}>
                                <TableCell><Button variant='contained' size='small' disabled={this.state.prompt_alerts && this.state.block_orders}>Select</Button></TableCell>
                                <TableCell>Strict</TableCell>
                                <TableCell align='center' className={classes.table_cell_green}>✓</TableCell>
                                <TableCell align='center' className={classes.table_cell_green}>✓</TableCell>
                                <TableCell align='center' className={classes.table_cell_green}>✓</TableCell>
                              </TableRow>
                            </TableBody>
                          </Table>
                        </TableContainer>
                        {
                          this.state.prompt_alerts && !this.state.block_orders
                          ?
                          <GridContainer justifyContent='center'>
                            <GridItem xs={12} md={5}>
                              <TextField
                                label='Proceed button label on modal alert'
                                value={this.state.buttonProceedIgnoreWarnings || ''}
                                onChange={(event) => this.changeDefaultValue('buttonProceedIgnoreWarnings', event)}
                                className={classes.textField}
                                margin='normal'
                                fullWidth={true}
                              />
                            </GridItem>
                          </GridContainer>
                          :
                          null
                        }
                      </GridItem>
                      :
                      null
                    }
                  </GridContainer>
                </CardBody>
              </Card>
            </GridItem>
            : 
            null
            }
            {
            this.state.shopify_plus 
            ? 
            <GridItem xs={12} md={10} lg={4}>
              <Card>
                <CardHeader color='primary'>
                  <h3 className={classes.cardTitleWhite}>Preview</h3>
                </CardHeader>
                <CardBody>
                  <p className={classes.cardDescription}>
                    Try addresses below to see the enabled address alerts:
                  </p>
                  {_.map(alerts, (obj) => this.generateSampleAlert(obj, 'div'))}
                  <TextField
                    id='testAddressField'
                    label='Address'
                    value={this.state.testAddressField || ''}
                    onChange={(event) => {
                      const input = event.target.value;
                      clearTimeout(previewTimeout);
                      previewTimeout = setTimeout(() => this.setState({ calcAddressField: input }), 300);
                      this.setState({ testAddressField: input });
                    }}
                    className={classes.textField}
                    margin='normal'
                    fullWidth={true}
                  />
                  <TextField
                    id='testZipField'
                    label='Postal code'
                    value={this.state.testZipField || ''}
                    onChange={(event) => {
                      const input = event.target.value;
                      clearTimeout(previewTimeout);
                      previewTimeout = setTimeout(() => this.setState({ calcZipField: input }), 300);
                      this.setState({ testZipField: input });
                    }}
                    className={classes.textField}
                    margin='normal'
                    fullWidth={true}
                  />
                  <FormControl className={classes.formControl}>
                    <InputLabel htmlFor='state'>US State</InputLabel>
                    <Select
                      value={this.state.testStateField}
                      onChange={(event) => this.setState({ testStateField: event.target.value })}
                      inputProps={{ name: 'state', id: 'us-state' }}
                      autoWidth={true}
                    >
                      <MenuItem value=''><em>None</em></MenuItem>
                      {_.map(us_states, (data) => <MenuItem value={data} key={data}>{data}</MenuItem>)}
                    </Select>
                  </FormControl>
                  {
                    this.state.prompt_alerts
                    ?
                    <div>
                      <hr />
                      <p className={classes.cardDescription} style={{ backgroundColor: 'rgba(0,0,0,0.4)', color: 'white', paddingBottom: 5, paddingTop: 10 }}>
                        Prompt displayed when customer ignores the warning and proceeds:
                      </p>
                      <div id='myModal'>
                        <div id='myModal-container' style={{ backgroundColor: this.state.colorTrim }}>
                          <div id='addressValidatorBox' style={{ backgroundColor: this.state.colorBackground, borderColor: this.state.colorBorder, color: this.state.colorText }}>
                            <h2>{this.generateSamplePromptAlerts()}</h2>
                          </div>
                          <br />
                          <span>{this.state.testAddressField || 'Example Address'}</span>
                          <br />
                          <span>City, {this.state.testStateField || 'CA'}, {this.state.testZipField || 94105}</span>
                          <br />
                          <div className='btn-row'>
                            <button className='av_button' id='btn-update-address' style={this.promptButtonStyle()}>{this.state.buttonUpdate}</button>
                            {this.state.block_orders ? null : <button className='av_button' id='btn-proceed-address' style={this.promptButtonStyle()}>{this.state.buttonProceedIgnoreWarnings}</button>}
                          </div>
                        </div>
                      </div>
                    </div>
                    :
                    null
                  }
                </CardBody>
              </Card>
            </GridItem>
            : 
            null
            }
            <GridItem xs={12} md={10}>
              <Card>
                <CardHeader color='primary'>
                  <h3 className={classes.cardTitleWhite}>Rules</h3>
                </CardHeader>
                <CardBody>
                  <GridContainer>
                    {
                      _.map(alerts, (alert) => (
                        <GridItem xs={12} key={alert.title}>
                          <Paper style={{ marginTop: 10, padding: 10 }}>
                            <GridContainer>
                              <GridItem xs={12} md={8}>
                                <Typography variant='h6'>{alert.title}</Typography>
                              </GridItem>
                              <GridItem xs={12} md={4}>
                                <FormControlLabel
                                  control={
                                    <Switch
                                      checked={this.state[alert.toggleValue]}
                                      onChange={(value) => this.toggleAlert(alert.toggleValue)}
                                      color='primary'
                                    />
                                  }
                                  label={this.state[alert.toggleValue] ? 'Enabled' : 'Disabled'}
                                  style={{ float: 'right' }}
                                />
                              </GridItem>
                              <GridItem xs={12} md={6}>
                                <span 
                                  className={classes.cardDescription} 
                                  dangerouslySetInnerHTML={{__html: alert.title === 'General Message' ? alert.description : 'Enable this option if you cannot ship to <b>' + alert.description + '</b>.' }} 
                                  style={{ textAlign: 'left' }} 
                                />
                                {
                                  alert.help 
                                  ?
                                  <span className={classes.cardDescription}><MoreInfo note={alert.help} /></span>
                                  :
                                  null
                                }
                                {
                                  alert.title === 'Extra Long Addresses' && this.state[alert.toggleValue]
                                  ?
                                  <div>
                                    <span className={classes.cardDescription} style={{ textAlign: 'left' }}>What is the maximum character limit?</span>
                                    <Select
                                      value={this.state.address_length_limit}
                                      onChange={(event) => this.changeText(event, 'address_length_limit', 'Character limit')}
                                      style={{ marginLeft: 15 }}
                                    >
                                      {_.map(characterLimits, (limit) => <MenuItem key={limit} value={limit}>{limit}</MenuItem>)}
                                    </Select>
                                  </div>
                                  :
                                  null
                                }
                                {
                                  alert.title === 'PO Boxes' && this.state[alert.toggleValue]
                                  ?
                                  <div>
                                    <span className={classes.cardDescription} style={{ textAlign: 'left' }}>
                                      What is the scope of these PO Box alerts? 
                                      <MoreInfo note='For example, select "US only" if you only want to block PO Boxes in the US.' />
                                    </span>
                                    <Select
                                      value={this.state.po_box_scope}
                                      onChange={(event) => this.changeText(event, 'po_box_scope', 'PO Box Scope')}
                                      style={{ marginLeft: 15 }}
                                    >
                                      <MenuItem value='all'>All countries</MenuItem>
                                      <MenuItem value='au'>Australia only</MenuItem>
                                      <MenuItem value='nz'>New Zealand only</MenuItem>
                                      <MenuItem value='us'>US only</MenuItem>
                                      <MenuItem value='international'>Non-US countries only</MenuItem>
                                    </Select>
                                  </div>
                                  :
                                  null
                                }
                              </GridItem>
                              {
                                this.state[alert.toggleValue]
                                ?
                                <GridItem xs={12} md={6}>
                                  {
                                    alert.title === 'General Message' && !isUsingCheckoutLiquid ?
                                    <Typography variant='body2'>Customize message:</Typography>
                                    :
                                    <Typography variant='body2'>Customize warning message:</Typography>
                                  }
                                  <TextField
                                    id={alert.labelValue}
                                    value={this.state[alert.labelValue]}
                                    placeholder={this.state[alert.labelValue]}
                                    onChange={(event) => this.changeDefaultValue(alert.labelValue, event)}
                                    className={classes.textField}
                                    margin='normal'
                                    multiline={true}
                                    fullWidth={true}
                                    style={alert.title === 'General Message' ? { marginTop: 0 } : {}}
                                  />
                                  {
                                    alert.title === 'General Message' && !isUsingCheckoutLiquid ?
                                    <>
                                      <br />
                                      <br />
                                      <Typography variant='body2'>Message Title:</Typography>
                                      <TextField
                                        id="generic_message_title"
                                        value={this.state["generic_message_title"] || "Note"}
                                        placeholder={this.state["generic_message_title"] || "Note"}
                                        onChange={(event) => this.changeDefaultValue("generic_message_title", event)}
                                        className={classes.textField}
                                        margin='normal'
                                        fullWidth={true}
                                        style={{ marginTop: 0 }}
                                      />
                                      <br />
                                      <br />
                                      <Typography variant='body2'>Message Type:</Typography>
                                      <Select
                                        labelId="banner_type-select-label"
                                        label="Message Type"
                                        value={this.state["generic_message_type"] || "info"}
                                        onChange={(event) => this.changeDefaultValue("generic_message_type", event)}
                                        margin='dense'
                                        fullWidth={true}
                                      >
                                        {_.map(genericMessageTypes, (option) => <MenuItem key={option.type} value={option.type}>{option.label}</MenuItem>)}
                                      </Select>
                                    </>
                                    :
                                    null
                                  }
                                </GridItem>
                                :
                                null
                              }
                            </GridContainer>
                          </Paper>
                        </GridItem>
                      ))
                    }
                  </GridContainer>
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        </div>
      );
  }
}

export default withStyles(AlertsStyles)(Alerts);
