import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import _ from 'lodash';
import axios from 'axios';
import moment from 'moment';
import generateAddress from 'helper/generateAddress.js';
import General from 'helper/general.js';
import Loader from 'components/loader.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import GridContainer from 'components/Grid/GridContainer.jsx';
import Card from 'components/Card/Card.jsx';
import CardHeader from 'components/Card/CardHeader.jsx';
import CardBody from 'components/Card/CardBody.jsx';
import { Redirect } from 'react-router';
import { Button, Chip, Table, TableBody, TableCell, TableHead, TableRow, Tooltip } from '@material-ui/core/';

import OrderHistoryStyle from 'assets/jss/orderHistory.jsx';
import '../../assets/css/address.css';

const determineStatus = (order, suggestion, correct) => {
  if(correct)
    return {
      text: 'Correct Address',
      tooltip: 'Customer specified the correct address'
    }
  else if(order.status === 'updated') {
    if(suggestion) {
      if(order.precheckout) 
        return {
          text: 'Suggestion Used',
          tooltip: 'Customer used suggested address during checkout'
        };
      else 
        return {
          text: 'Suggestion Used',
          tooltip: 'Customer used selected address after checkout',
        };
    }
    else 
      return {
        text: 'Updated Address',
        tooltip: 'Customer updated address after checkout via form'
      };
  }
  else if(order.status === 'confirmed') 
    return {
      text: 'Confirmed',
      tooltip: 'Customer confirmed original address'
    };
  else if(!order.status) 
    return {
      text: 'Unchanged',
      tooltip: 'Customer did not confirm original address or select suggested address'
    };
}


class OrderHistory extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      count: null,
      loading: true,
      charge_id: null,
      charges: [],
      results: [],
      redirect: false,
    }
  }

  fetchOrders(charge_id) {
    if(this.state[charge_id])
      this.setState({ results: this.state[charge_id], charge_id: charge_id });
    else {
      this.setState({ loading: true, charge_id: charge_id });
      axios({
        method: 'GET',
        url: `${General.serverURL}address_validator/api/charge_orders?charge_id=${charge_id}`,
        headers: { Authorization: `Bearer ${General.getAVjwt()}` }
      })
      .then((result) => {
        let newState = this.state;
        newState.loading = false;
        newState.results = result.data;
        newState[charge_id] = result.data;
        this.setState(newState);
      })
      .catch((err) => this.setState({ loading: false, redirect: true }));
    }
  }

  componentDidMount() {
    if(General.localStorageAvailable())
      localStorage.setItem('address_validator_previous_route', '/billing');
    
    axios({
      method: 'GET',
      url: `${General.serverURL}address_validator/api/charges`,
      headers: { Authorization: `Bearer ${General.getAVjwt()}` }
    })
    .then((result) => this.setState({ loading: false, charges: result.data }))
    .catch((err) => this.setState({ loading: false, redirect: true }));
  }

  generateTableHeader(classes) {
    return (
      <TableHead>
        <TableRow>
          <TableCell className={classes.table_head}>Date</TableCell>
          <TableCell className={classes.table_head}>Order Id</TableCell>
          <TableCell className={classes.table_head}>Shipping Address</TableCell>
          <TableCell className={classes.table_head}>Status</TableCell>
          <TableCell className={classes.table_head}>Notes</TableCell>
        </TableRow>
      </TableHead>
    );
  }

  displayOrder = (data, classes) => {
    const { order, suggestion, correct } = data;
    const status = determineStatus(order, suggestion, correct);

    return (
      <TableRow key={(order.created_at) + Math.floor(Math.random() * 100)} hover={true}>
        <TableCell className={classes.table_body}>{moment(order.created_at).format('MMM D')}</TableCell>
        <TableCell className={classes.table_body}>{order.store_order_id}<br />{order.store_order_name}</TableCell>
        <TableCell className={classes.table_body} dangerouslySetInnerHTML={{ __html: generateAddress(order, order.status === 'updated' && suggestion ? suggestion : order) }} />
        <TableCell className={classes.table_body}>
          <Tooltip title={status.tooltip} placement='top'>
            <Chip label={status.text} className={classes.chip} />
          </Tooltip>
        </TableCell>
        <TableCell className={classes.table_body}>
          {order.missing_unit_num && !order.address_2 && !order.company ? <Chip color='secondary' label='Missing apt/unit #' className={classes.chip} /> : null}
          {order.residential_commercial_ind ? <Chip color='primary' label={order.residential_commercial_ind.split('_')[0]} className={classes.chip} /> : null}
        </TableCell>
      </TableRow>
    );
  }


  render() {
    const { classes } = this.props;

    if(this.state.loading)
      return <Loader />;
    else if(this.state.redirect)
      return <Redirect to='/user' />;
    else if(this.state.charge_id) {
      if(this.state.results && this.state.results.length > 0)
        return (  
          <GridContainer justifyContent='center'>
            <GridItem xs={12} lg={10}>
              <Card>
                <CardHeader color='primary'>
                  <h3 className={classes.cardTitleWhite} style={{ float: 'left' }}>Orders associated with charge <b>{this.state.charge_id}</b></h3>
                  <Button variant='contained' color='default' onClick={() => this.setState({ results: [], charge_id: null })} style={{ float: 'right' }}>Go Back</Button>
                </CardHeader>
                <CardBody>
                  <GridContainer justifyContent='center'>
                    <GridItem xs={12} style={{ marginTop: 15, overflowX: 'auto' }}>
                      <Table className={classes.table}>
                        {this.generateTableHeader(this.props.classes)}
                        <TableBody>
                          {_.map(this.state.results, (data) => this.displayOrder(data, classes))}
                        </TableBody>
                      </Table>
                    </GridItem>
                </GridContainer>
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        );
      else
        return (
          <GridContainer justifyContent='center'>
            <GridItem xs={10} md={6}>
              <Card>
                <CardBody style={{ textAlign: 'center' }}>
                  <h2>Order info has been archived or redacted</h2>
                  <Button variant='contained' color='default' onClick={() => this.setState({ results: [], charge_id: null })}>Go Back</Button>
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        );
    }
    else if(this.state.charges && this.state.charges.length === 0)
      return (
        <GridContainer justifyContent='center'>
          <GridItem xs={10} md={6}>
            <Card>
              <CardBody>
                <h2 style={{ textAlign: 'center' }}>No charges yet</h2>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      );
    else if(this.state.charges && this.state.charges.length > 0)
      return (
        <GridContainer justifyContent='center'>
          <GridItem xs={12} md={10}>
            <Card>
              <CardHeader color='primary'>
                <h3 className={classes.cardTitleWhite}>Recent app usage charges added to your Shopify bill</h3>
              </CardHeader>
              <CardBody>
                <GridContainer justifyContent='center'>
                  <GridItem xs={12} style={{ marginTop: 15, overflowX: 'auto' }}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell className={classes.table_head}>ID</TableCell>
                          <TableCell className={classes.table_head}>Description</TableCell>
                          <TableCell className={classes.table_head}>Fee</TableCell>
                          <TableCell className={classes.table_head}></TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {_.map(this.state.charges, (charge) => (
                          <TableRow key={charge.id}>
                            <TableCell className={classes.table_body}>{charge.id}</TableCell>
                            <TableCell className={classes.table_body}>{charge.description}</TableCell>
                            <TableCell className={classes.table_body}>${charge.fee}</TableCell>
                            <TableCell className={classes.table_body}>{/source email fee/.test(charge.description) ? ''  : <Button variant='contained' color='default' size='small' onClick={() => this.fetchOrders(charge.id)}>More Info</Button>}</TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      );
  }
}

OrderHistory.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(OrderHistoryStyle)(OrderHistory);