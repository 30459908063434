import React from 'react';
import { Link } from 'react-router-dom';
import withStyles from '@material-ui/core/styles/withStyles';
import General from 'helper/general.js';
import GridItem from 'components/Grid/GridItem.jsx';
import GridContainer from 'components/Grid/GridContainer.jsx';
import Card from 'components/Card/Card.jsx';
import CardHeader from 'components/Card/CardHeader.jsx';
import CardBody from 'components/Card/CardBody.jsx';
import { Button, Checkbox, Chip, FormControlLabel, Switch, TextField } from '@material-ui/core/';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import _ from 'lodash';
import axios from 'axios';
import draftToHtml from 'draftjs-to-html';
import { convertToRaw } from 'draft-js';
import Toggle from '../../components/toggle.jsx';
import Loader from '../../components/loader.jsx';
import InputChips from '../../components/inputChips.jsx';
import EmailsStyles from 'assets/jss/emails.jsx';
import MoreInfo from '../../components/moreInfo.jsx';
import FormButton from '../../components/formButton.jsx';
import DescriptionBox from 'components/descriptionBox.jsx';
import Snackbar from '../../components/snackBar.jsx';

const validateEmail = (email) => {
  // eslint-disable-next-line
  let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase().trim());
};

let updateCustomTextTimeout;

const variables = ['FIRST_NAME', 'LAST_NAME', 'EMAIL', 'ORDER_ID', 'ORDER_NAME', 'ADDRESS_1', 'ADDRESS_2', 'CITY', 'STATE', 'ZIP', 'COUNTRY', 'FORM_LINK'];


class Emails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      testEmail: '',
      testType: false,
      source_email_loading: false,
      source_email_initialized: false,
      source_email_notice: false,
      custom_email_body: '',
      custom_email_id: null
    };
  }

  componentDidMount() {
    const brand = General.getBrandData();
    const attributes = _.pick(brand, ['email', 'customize_email_link', 'source_email', 'source_email_ready', 'source_email_enabled', 'email_address_incorrect', 'config_email_address_incorrect_customer', 'config_email_address_incorrect', 'custom_email_message', 'po_box_emails', 'full_custom_email', 'config_email_address_change', 'emails_stats', 'ignore_config_emails']);

    axios({
      method: 'GET',
      url: General.serverURL + 'address_validator/api/get_custom_email_template',
      headers: { Authorization: `Bearer ${General.getJWT()}` }
    })
    .then((result) => {
      this.setState(Object.assign(attributes, brand.customization, { loading: false }, 
        (result.data ? { custom_email_body: result.data.body, custom_email_id: result.data.id } : {})
      ));
    })
  }

  sendTestEmail() {
    axios({
      method: 'POST',
      url: General.serverURL + 'address_validator/api/send_test_email',
      headers: { Authorization: `Bearer ${General.getJWT()}` },
      data: {
        brand_id: General.getBrandData().id,
        type: this.state.testType,
        email: this.state.testEmail
      }
    })
    .then((result) => this.setState({ open: true, alert_type: 'success', testEmail: '' }))
    .catch((err) => this.setState({ open: true, alert_type: 'error', testEmail: '' }));
  }

  verifySourceEmail() {
    this.setState({ source_email_loading: true, source_email_loading_message: 'Hang tight - sending verification email to ' + this.state.source_email });
    axios({
      method: 'PUT',
      url: General.serverURL + 'address_validator/api/initalize_source_email',
      headers: { Authorization: `Bearer ${General.getJWT()}` },
      data: {
        brand_id: General.getBrandData().id,
        source_email: this.state.source_email
      }
    })
    .then((result) => this.setState({ source_email_loading: false, source_email_initialized: true }));
  }

  confirmSourceEmail() {
   this.setState({ source_email_loading: true, source_email_loading_message: 'Confirming source email status' });
    axios({
      method: 'PUT',
      url: General.serverURL + 'address_validator/api/check_status_source_email',
      headers: { Authorization: `Bearer ${General.getJWT()}` },
      data: {
        brand_id: General.getBrandData().id,
        source_email: this.state.source_email
      }
    })
    .then((result) => {
      if(result.data === 'verified')
        this.setState({ source_email_ready: true, source_email_loading: false });
      else if(result.data === 'pending')
        this.setState({ source_email_notice: true, source_email_loading: false });
    }); 
  }

  resetSourceEmail() {
    this.toggleOption('source_email_ready', '', null);
    this.toggleOption('source_email', '', '')
  }

  renderSourceEmailBody(classes) {
    if(this.state.source_email_ready)
      return (
        <CardBody className={classes.center}>
          <p className={classes.cardDescription}>
            Emails will be sent from <b>{this.state.source_email}</b>
          </p>
          <hr />
          <p className={classes.cardDescription}>Would you like to use your own email HTML markup? This will completely replace the default email template.</p>
          <FormControlLabel
            control={
              <Switch
                checked={this.state.full_custom_email}
                onChange={(value) => this.toggleOption('full_custom_email', 'Custom email body')}
                color='primary'
                disabled={!this.state.source_email_ready}
              />
            }
            label={this.state.full_custom_email ? 'Yes' : 'No'}
          />
          <br />
          <Button
            variant='contained'
            color='primary'
            className={classes.button}
            onClick={() => this.resetSourceEmail()}
            style={{ marginTop: 10 }}
            disabled={!this.state.source_email_ready}
          >
            Reset Source Email
          </Button>
        </CardBody>
      );
    else
      return (
        this.state.source_email_loading
        ?
        <Loader marginBottom={50} marginTop={50} message={this.state.source_email_loading_message} />
        :
        <CardBody className={classes.center}>
          <p className={classes.cardDescription}>
            Enable to: (a) send email notifications from your own domain instead of <i>orders@roboturk.co</i> and/or (b) enable full customization of emails. <b>This feature will cost an additional $14.99 per month and will be added to your Shopify bill.</b>
            <MoreInfo note='Emails sent from your own email address may mitigate customer confusion and ensure a more seamless customer experience.' />
          </p>
          <FormControlLabel
            control={
              <Switch
                checked={this.state.source_email_enabled}
                onChange={(value) => this.toggleOption('source_email_enabled', 'Custom email domain')}
                color='primary'
              />
            }
            label={this.state.source_email_enabled ? 'Enabled' : 'Disabled'}
          />
          {
            this.state.source_email_enabled
            ?
            <GridContainer justifyContent='center'>
              <GridItem xs={12} md={8}>
                <p className={classes.cardDescription}><b>Step 1:</b> What email address do you want these notifications to be sent from?</p>
                <TextField
                  id='custom-email-address'
                  label='Source email'
                  value={this.state.source_email || ''}
                  onChange={(event) => this.setState({ source_email: event.target.value})}
                  className={classes.textField}
                  margin='normal'
                  disabled={this.state.source_email_initialized}
                  fullWidth={true}
                />
              </GridItem>
              {
                this.state.source_email && validateEmail(this.state.source_email)
                ?
                <GridItem xs={12} md={8}>
                  <p className={classes.cardDescription}><b>Step 2:</b> Verify email</p>
                  <Button variant='contained' color='primary' className={classes.button} disabled={this.state.source_email_initialized} onClick={() => this.verifySourceEmail()} style={{ marginTop: 10 }}>
                    Send verification email
                  </Button>
                </GridItem>
                :
                null
              }
              {
                this.state.source_email_initialized
                ?
                <GridItem xs={12}>
                  <p className={classes.cardDescription}><b>Step 3:</b> Check your <i>{this.state.source_email}</i> inbox for an email from <i>verification@roboturk.co</i> with a subject line of <i>[Address Validator app]: Please confirm your email</i>. Open the email and click on the link to confirm your identity. Once you do that, click the button below to finish setup.</p>
                  <Button variant='contained' color='primary' className={classes.button} onClick={() => this.confirmSourceEmail()} style={{ marginTop: 10 }}>
                    I have confirmed my email via the link
                  </Button>
                </GridItem>
                :
                null
              }
              {
                this.state.source_email_notice
                ?
                <b style={{ color: '#8B0000' }}>Pending - please check your email and click on the confirmation link</b>
                :
                null
              }
            </GridContainer>
            :
            null
          }
        </CardBody>
      );
  }

  describeEmailTargets() {
    if(this.state.config_email_address_incorrect && this.state.config_email_address_incorrect_customer)
      return <span>Emails will be sent to the <b>store and customer</b>.</span>
    else if(this.state.config_email_address_incorrect)
      return <span>Emails will only be sent to the <b>store</b>.</span>
    else if(this.state.config_email_address_incorrect_customer)
      return <span>Emails will only be sent to the <b>customer</b>.</span>
    else
      return 'No emails will be sent.'
  }

  changeEmailWho(key, store_value, customer_value) {
    this.setState({ config_email_address_incorrect_customer: customer_value, config_email_address_incorrect: store_value, open: false });

    General.setBrandValue(
      key, 
      (key === 'config_email_address_incorrect') ? store_value : customer_value,
      (type) => this.setState({ open: true, alert_type: type }) 
    );
  }

  updateState(key, value) {
    let newState = this.state;
    newState[key] = value;
    newState.open = false;
    this.setState(newState);
  }

  changeTextValue(event, key, message) {
    this.updateState(key, event.target.value);

    General.changeText(
      event,
      key, 
      (type) => this.setState({ open: true, alert_type: type })
    );
  }

  customizeEmail(event) {
    this.updateState('custom_email_message', event)

    General.changeText(
      event, 
      'custom_email_message', 
      (type) => this.setState({ open: true, alert_type: type })
    );
  }

  toggleOption(key, type, value) {
    if (value) {
      this.updateState(key, value);
    } else {
      this.updateState(key, !this.state[key]);
    }

    General.setBrandValue(key, this.state[key], (type) =>
      this.setState({ open: true, alert_type: type })
    );
  }

  updateCustomEmailTemplate(email_body) {
    this.setState({ custom_email_body: email_body });

    clearTimeout(updateCustomTextTimeout);

    updateCustomTextTimeout = setTimeout(() => {
      axios({
        method: 'PUT',
        url: General.serverURL + 'address_validator/api/update_custom_email_template',
        data: { email_body: email_body, id: this.state.custom_email_id },
        headers: { Authorization: `Bearer ${General.getJWT()}` },
      })
      .then((result) => this.setState({ custom_email_id: result.data.id, open: true, alert_type: 'success' }))
      .catch((err) => this.setState({ open: true, alert_type: 'error' }))
    }, 4000);
  }

  emailLocation() {
    if(this.state.email_address_incorrect)
      return <span>Email notifications will be sent to <i>{this.state.email_address_incorrect.replace(/,/g, ', ')}</i>.</span>
    else
      return <span>Store email notifications will be sent to <i>{General.getBrandData().email}</i>. Please specify another email address below if you want them to be sent elsewhere. This address is also is displayed in the body of the email.</span>      
  }


  render() {
    const { classes } = this.props;

    if(this.state.loading)
      return <Loader />;
    else
    return (
      <GridContainer justifyContent='center'>
        <Snackbar
          alert_type={this.state.alert_type}
          close={() => this.setState({ open: false, alert_type: null })}
          open={this.state.open}
        />
        <GridItem xs={12} md={10}>
          <DescriptionBox 
            classes={classes}
            content='<i>Address Validator</i> notifies customers of inconsistencies in shipping addresses, but some customers may accidentally skip over them, resulting in orders with unverifiable shipping addresses. You can automatically send emails to alert the store and/or the customer in these scenarios.'
            title='What are these email notifications?'
          />
        </GridItem>
        <GridItem xs={12} md={7}>
          <Card>
            <CardHeader color='primary'>
              <h3 className={classes.cardTitleWhite}>Preview</h3>
            </CardHeader>
            <CardBody>
              <GridContainer justifyContent='center'>
                {
                  this.state.full_custom_email
                  ?
                  <GridItem xs={12} md={10} lg={7}>
                    <div className={classes.center}>
                      <Button variant='contained' color='primary' className={classes.button} onClick={() => {
                        var newWindow = window.open();
                        newWindow.document.write(this.state.custom_email_body);
                      }}>
                        Preview email
                      </Button>
                    </div>
                  </GridItem>
                  :
                  <GridItem xs={12} md={10} lg={7}>
                    <GridContainer justifyContent='center'>
                      <GridItem xs={12}>
                        <h3 style={{ margin: 0 }}>
                          {
                            this.state.emailHeaderLine
                            ?
                            this.state.emailHeaderLine
                            :
                            (
                              this.state.testType
                              ?
                              this.state.subjectMissingUnitApt
                              :
                              this.state.subjectUnverifiableAddress
                            )
                          }
                        </h3>
                      </GridItem>
                      <GridItem xs={12} md={6}>
                        {
                          draftToHtml(convertToRaw(this.state.custom_email_message.getCurrentContent())).length > 8 
                          ?
                          <p dangerouslySetInnerHTML={{ __html: draftToHtml(convertToRaw(this.state.custom_email_message.getCurrentContent())) }} />
                          :
                          <p>Uh oh! We received your order, but we're not able to confidently verify your shipping address.<br /><br /><b>Please confirm or send an updated shipping address to {this.state.email_address_incorrect || this.state.email}</b><br /><br /></p>
                        }
                        <FormButton type={this.state.testType} />
                        <br/><br/>
                        <span>Order: [order_id]</span>
                        <br />
                        <span>[first_name] [last_name]</span>
                        <br />
                        <span>[email]</span>
                      </GridItem>
                      <GridItem xs={12} md={6}>
                        <a href='https://www.google.com/maps/place/40.73061,-73.935242' target='_blank' rel='noopener noreferrer'>
                          <img alt='map' src='https://image.maps.api.here.com/mia/1.6/mapview?c=40.73061,-73.935242&z=17.1&w=250&h=250&f=1&app_id=iYsJ7c228mivEUt7lUZN&app_code=jVdGwlZ9oywJzrQ8uqgSSw&poix0=40.73061,-73.935242;1aaf07;ffffff;20;o'/>
                        </a>
                        <div style={{ backgroundColor: '#404040', padding: 9, color: '#f2f2f2', textAlign: 'center', width: 232 }}>
                          <span>[address1]</span> <span>[address2]</span>
                          <br />
                          <span>[city]</span>, <span>[state]</span> <span>[zip]</span>
                          <br />
                          <span>[country]</span>
                        </div>
                      </GridItem>
                    </GridContainer>
                  </GridItem>
                }
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12} md={3}>
          <Card>
            <CardHeader color='primary'>
              <h3 className={classes.cardTitleWhite}>Send test email</h3>
            </CardHeader>
            <CardBody className={classes.center}>
              <GridContainer>
                <GridItem xs={12}>
                  <p className={classes.cardDescription}>Where do you want to receive this test email?</p>
                  <TextField
                    id='test-email-addresses'
                    label='Email address'
                    value={this.state.testEmail}
                    onChange={(event) => this.setState({ testEmail: event.target.value})}
                    className={classes.textField}
                    margin='normal'
                    fullWidth={true}
                  />
                  <p className={classes.cardDescription}>What type of email notification?</p>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={this.state.testType}
                        onChange={(value) => this.setState({ testType: !this.state.testType })}
                        color='primary'
                      />
                    }
                    label={this.state.testType ? 'Missing apt/suite #' : 'Invalid address'}
                  />
                  <br />
                  <Button variant='contained' color='primary' className={classes.button} disabled={!this.state.testEmail} onClick={() => this.sendTestEmail()}>
                    Send test email
                  </Button>
                </GridItem>  
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12} md={5}>
          <GridContainer>
            <GridItem xs={12}>
              <Card>
                <CardHeader color='primary'>
                  <h3 className={classes.cardTitleWhite}>Who gets these emails?</h3>
                </CardHeader>
                <CardBody>
                  <GridContainer>
                    <GridItem xs={12}>
                       <FormControlLabel
                        control={
                          <Checkbox
                            checked={this.state.config_email_address_incorrect}
                            onChange={(event) => this.changeEmailWho('config_email_address_incorrect', event.target.checked, this.state.config_email_address_incorrect_customer)}
                            value='Store'
                            color='primary'
                          />
                        }
                        label='Store'
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={this.state.config_email_address_incorrect_customer}
                            onChange={(event) => this.changeEmailWho('config_email_address_incorrect_customer', this.state.config_email_address_incorrect, event.target.checked)}
                            value='Customer'
                            color='primary'
                          />
                        }
                        label='Customer'
                      />
                      <p className={classes.cardDescription}>{this.describeEmailTargets()}</p>
                      <hr />
                      <p className={classes.cardDescription}>
                        {this.emailLocation()}
                      </p>
                      <InputChips list={this.state.email_address_incorrect} label='Email address' onChange={(value) => this.changeTextValue(value, 'email_address_incorrect', 'Automated emails will be sent to')}/>
                    </GridItem>
                  </GridContainer>
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        </GridItem>
        <GridItem xs={12} md={5}>
          <Card>
            <CardHeader color='primary'>
              <h3 className={classes.cardTitleWhite}>Email domain & customization</h3>
            </CardHeader>
            {this.renderSourceEmailBody(classes)}
          </Card>
        </GridItem>
        <GridItem xs={12} md={10}>
          <Card>
            <CardHeader color='primary'>
              <h3 className={classes.cardTitleWhite}>Email template</h3>
            </CardHeader>
            <CardBody>
              {
                this.state.full_custom_email
                ?
                <GridContainer>
                  <GridItem xs={12}>
                    <div className={classes.center}>
                      <p className={classes.cardDescription}>Use the following case-sensitive keywords to insert variables:</p>
                      {_.map(variables, (variable) => <Chip label={'{{' + variable + '}}'} className={classes.chip} variant='outlined' size='small' key={variable} onClick={() => this.updateCustomEmailTemplate(this.state.custom_email_body + '{{' + variable + '}}')}/>)}
                    </div>
                    <TextField
                      id='email-header'
                      label='Email HTML markup'
                      value={this.state.custom_email_body}
                      onChange={(event) => this.updateCustomEmailTemplate(event.target.value)}
                      className={classes.textField}
                      margin='normal'
                      multiline
                      fullWidth={true}
                    />
                  </GridItem>
                </GridContainer>
                :
                <GridContainer>
                  <GridItem xs={12} md={4}>
                    <p className={classes.cardDescription}>
                      Specify the email header. It will the same as the subject line if nothing is specified.
                    </p>
                    <TextField
                      id='email-header'
                      label='Header line on email body'
                      value={this.state.emailHeaderLine || (this.state.testType ? this.state.subjectMissingUnitApt : this.state.subjectUnverifiableAddress)}
                      onChange={(event) => this.changeTextValue(event, 'emailHeaderLine', 'Header line updated to ')}
                      className={classes.textField}
                      multiline
                      margin='normal'
                      fullWidth={true}
                    />
                    <p className={classes.cardDescription}>
                      Customize the button text and colors on the <Link to='/address_prompts'><i>Address Prompts</i> tab</Link>.
                    </p>
                  </GridItem>
                  <GridItem xs={12} md={8}>
                    <p className={classes.cardDescription}>
                      Specify the email message.
                    </p>
                    <Editor
                      editorState={this.state.custom_email_message}
                      toolbarClassName='toolbarClassName'
                      wrapperClassName='wrapperClassName'
                      editorClassName='editorClassName'
                      placeholder={`Uh oh! We received your order, but we're not able to confidently verify your shipping address`}
                      onEditorStateChange={(event) => this.customizeEmail(event)}
                    />
                  </GridItem>
                </GridContainer>
              }
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12} md={5}>
          <Card>
            <CardHeader color='primary'>
              <h3 className={classes.cardTitleWhite}>Subject lines</h3>
            </CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={12}>
                  <TextField
                    id='invalid-addresses-subject'
                    label='Subject line for invalid addresses'
                    value={this.state.subjectUnverifiableAddress || 'Please confirm or update shipping address'}
                    onChange={(event) => this.changeTextValue(event, 'subjectUnverifiableAddress', 'Subject line updated to ')}
                    className={classes.textField}
                    margin='normal'
                    fullWidth={true}
                  />
                </GridItem>
                <GridItem xs={12}>
                  <TextField
                    id='missing-num-subject'
                    label='Subject line for missing apt/suite #'
                    value={this.state.subjectMissingUnitApt || 'Please specify apt, unit, suite or floor'}
                    onChange={(event) => this.changeTextValue(event, 'subjectMissingUnitApt', 'Subject line updated to ')}
                    className={classes.textField}
                    margin='normal'
                    fullWidth={true}
                  />
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12} md={5}>
          <Card>
            <CardHeader color='primary'>
              <h3 className={classes.cardTitleWhite}>Other notifications</h3>
            </CardHeader>
            <CardBody className={classes.center}>
              <p className={classes.cardDescription}>
                Emails when customers proceed with PO Box addresses
              </p>
              <Toggle value={this.state.po_box_emails} onChange={() => this.toggleOption('po_box_emails')} yes_label='Enabled' no_label='Disabled' />
              <hr />
              <p className={classes.cardDescription}>
                Emails when customers update the shipping address after checkout
              </p>
              <Toggle value={this.state.config_email_address_change} onChange={() => this.toggleOption('config_email_address_change')} yes_label='Enabled' no_label='Disabled' />
              <hr />
              <p className={classes.cardDescription}>
                Weekly emails with Address Validator stats
              </p>
              <Toggle value={this.state.emails_stats} onChange={() => this.toggleOption('emails_stats')} yes_label='Enabled' no_label='Disabled' />
              <hr />
              <p className={classes.cardDescription}>
                Emails about new features and updates
              </p>
              <Toggle value={!this.state.ignore_config_emails} onChange={() => this.toggleOption('ignore_config_emails')} yes_label='Enabled' no_label='Disabled' />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  }
}

export default withStyles(EmailsStyles)(Emails);
