import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import debounce from 'lodash/debounce';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Fab, Grid, IconButton, Snackbar, Step, StepLabel, Stepper, Typography } from '@material-ui/core/';
import { ChevronLeft, ChevronRight, Close } from '@material-ui/icons/';
import GridItem from 'components/Grid/GridItem.jsx';
import General from 'helper/general.js';
import instructionsStyle from 'assets/jss/instructions.jsx';


const cdn = 'https://d3eci0d3sspd26.cloudfront.net/address_validator_admin_assets/';

const steps = ['Select <i>Online Store</i>', 'Select <i>Themes</i>', 'Edit Code', 'checkout.liquid', 'Insert Snippet'] ;
const stepsUIExt = ['Select <i>Settings</i>', 'Select <i>Checkout</i>', 'Select page', 'Add App', 'Allow Permissions'];


const getStepContent = (step, setupUiExtentions) => {
  switch (step) {
    case 0:
      return <span>Login to your Shopify admin dashboard and select the <i>{ setupUiExtentions ? 'Settings' : 'Online Store' }</i> menu option on the bottom left.</span>;
    case 1:
      return setupUiExtentions ? <span>Select the <i>Checkout</i> option. Then, <i>Go to Checkout Editor</i></span> : <span>Select the <i>Themes</i> option.</span>;
    case 2:
      return setupUiExtentions ? <span>Select the page from the dropdown menu above - Information page/Shipping page only</span> : <span>Click on <i>Actions</i> dropdown button and select <i>Edit code</i> within <i>Currrent theme</i> section.</span>;
    case 3:
      return setupUiExtentions ? <span>Select the <i>Add App</i> button at the bottom of left menu</span> : <span>Select <i>checkout.liquid</i> file under <i>Layout</i> on the left.</span>;
    case 4:
      return setupUiExtentions ? <span>Enable the ability to <i>block checkout</i> progress for full functionality</span> : <span>Insert the code snippet directly below the <code>{`{{ checkout_scripts }}`}</code> line in the head section. Click the <i>Save</i> button on the top right.</span>;
    default:
      return null;
  }
}

const getStepImage = (step, classes) => {
  switch (step) {
    case 0:
      return <img src={cdn + 'setup_step_1.png'} alt='step_1' className={classes.imageObj} />;    
    default:
      return <img src={`${cdn}plus_step_${step + 1}.png`} alt={`step_${step + 1}`} className={classes.imageObj} />;
  }
}

const getStepImagesUiExt = (step, classes) => {
  if (step + 1 === 4) {
    return [
      <img src={cdn + `avstep${step + 1}a.png`} alt={`step_${step + 1}`} className={classes.imageObjExtraSmall} />,
      <Typography className={classes.instructions}>
        <span>then, select the extension by Address Validator</span>
      </Typography>,
      <br />,
      <img src={cdn + `avstep${step + 1}b.png`} alt={`step_${step + 1}`} className={classes.imageObjExtraSmall} />,
      <Typography className={classes.instructions}>
        <span>for 'Information Page' drag to move before the shipping form (example below)</span>
      </Typography>,
      <br />,
      <img src={cdn + `avstep${step + 1}c.png`} alt={`step_${step + 1}`} className={classes.imageObjSmall} />,
      <Typography className={classes.instructions}>
        <span>for 'Shipping Page' drag to move before the shipping method type (example below)</span>
      </Typography>,
      <br />,
      <img src={cdn + `avstep${step + 1}d.png`} alt={`step_${step + 1}`} className={classes.imageObjSmall} />,
      <Typography className={classes.instructions}>
        <span>Note: Extension should be added to Information page and Shipping page only and placed at the described locations</span>
      </Typography>,
    ]
  }

  return [<img src={cdn + `avstep${step + 1}.png`} alt={`step_${step + 1}`} className={classes.imageObjExtraSmall} />]
}

class Instructions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeStep: 0,
      message_open: false,
      shopify_plus: false,
      isSmallScreen: false,
    }

    this.resizeFunction = this.resizeFunction.bind(this);
    this.debouncedResize = debounce(this.resizeFunction, 300);
  }

  resizeFunction() {
    this.setState({ isSmallScreen: window.innerWidth <= 520 });
  }

  componentDidMount() {
    const brand = General.getBrandData();

    if(brand)
      this.setState({ activeStep: 0, shopify_plus: brand.shopify_plus, activated: brand.activated });

    // Allow for keyboard navigation
    document.onkeydown = (e) => {
      if(e.keyCode === 37)
        this.setState({ activeStep: Math.min(Math.max(this.state.activeStep - 1, 0), 4) });
      else if (e.keyCode === 39)
        this.setState({ activeStep: Math.min(Math.max(this.state.activeStep + 1, 0), 4) });
    }

    window.addEventListener('resize', this.debouncedResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.debouncedResize);
  }

  supportLink() {
    return <a href='https://help.shopify.com/en/questions#/contact' target='_blank' rel='noopener noreferrer'>contact Shopify support</a>
  }

  close() {
    this.setState({ activeStep: 0 });
    this.props.close();
  }

  copyCode() {
    const elm = document.getElementById('codeBox');
  
    if(document.body.createTextRange) { // for Internet Explorer
      const range = document.body.createTextRange();
      range.moveToElementText(elm);
      range.select();
      document.execCommand('Copy');
    }
    else if(window.getSelection) { // other browsers
      const selection = window.getSelection();
      const range = document.createRange();
      range.selectNodeContents(elm);
      selection.removeAllRanges();
      selection.addRange(range);
      document.execCommand('Copy');
    }

    this.setState({ message_open: true });
  }


  render() {
    const { classes, view } = this.props;
    const { activeStep } = this.state;

    const setupUiExtentions = view === "Checkout UI Extension";
    const stepsToDisplay = setupUiExtentions ? stepsUIExt : steps;

    if(this.state.activated && this.state.shopify_plus)
      return (
        <div>
          <Snackbar
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            autoHideDuration={1000}
            onClose={() => this.setState({ message_open: false })}
            open={this.state.message_open}
            message={<span>Code snippet copied to clipboard</span>}
          />
          <Dialog
            open={this.props.open}
            onClose={() => this.close()}
            aria-labelledby='instructions-title'
            aria-describedby='instructions-content'
            fullWidth={true}
            maxWidth='lg'
            classes={{
              paperScrollPaper: classes.paperChild,
              scrollPaper: classes.paperParent
            }}
          >
            <DialogTitle id='instructions-title'>
              <Stepper activeStep={activeStep} alternativeLabel>
                {stepsToDisplay.map((label, index) => <Step key={label}><StepLabel>{!this.state.isSmallScreen && <div dangerouslySetInnerHTML={{ __html: label }} />}</StepLabel></Step>)}
              </Stepper>
              <IconButton aria-label='close' className={classes.closeButton} onClick={() => this.close()}>
                <Close />
              </IconButton>
              <div className={classes.center}>
                <Typography variant='body1'>
                  Need help? Send us a note at <a href='mailto:support@roboturk.co'>support@roboturk.co</a> or <a href='https://calendly.com/address-validator/15min' target='_blank' rel='noopener noreferrer'>pick a time on the calendar here</a>.
                </Typography>
              </div>
            </DialogTitle>
            <DialogContent className={classes.center} dividers>
              <Grid container justify='center' className={classes.gridContainer}>
                <GridItem xs={12}>
                  <div className={classNames(classes.sideStepContainer, 'first')}>
                    <Fab size='medium' color='default' aria-label='Previous' className={classes.margin} disabled={activeStep === 0} onClick={() => this.setState({ activeStep: this.state.activeStep - 1 })}>
                      <ChevronLeft fontSize='large'>Previous</ChevronLeft>
                    </Fab>
                  </div>
                  <div className={classNames(classes.sideStepContainer, 'last')}>
                    <Fab size='medium' color='default' aria-label='Previous' className={classes.margin} disabled={activeStep === 4} onClick={() => this.setState({ activeStep: this.state.activeStep + 1 })}>
                      <ChevronRight fontSize='large'>Next</ChevronRight>
                    </Fab>
                  </div>
                  <Typography className={classes.instructions}>
                    {getStepContent(activeStep, setupUiExtentions)}
                  </Typography>
                  {
                    !setupUiExtentions && activeStep === 4
                    ?
                    <div className={classes.center}>
                      <pre id='codeBox' className={classes.codeBox}>
                        {`<!-- ADDRESS VALIDATOR -->`}
                        <br />
                        {`<script async src="https://d3ox8tdk614ykk.cloudfront.net/pobox_checker.js"></script>`}
                      </pre>
                      <Button
                        variant='contained'
                        color='primary'
                        onClick={() => this.copyCode()}
                        className={classes.button}
                      >
                        Copy code to clipboard
                      </Button>
                    </div>
                    :
                    null
                  }
                  <div className={classes.images}>
                    {setupUiExtentions ? getStepImagesUiExt(activeStep, classes) : getStepImage(activeStep, classes)}
                  </div>
                  {
                    !setupUiExtentions && activeStep === 3
                    ?
                    <div className={classes.center} style={{ marginBottom: 20 }}>
                      <Typography className={classes.instructions}>
                        <span><b>Don't see the <i>checkout.liquid</i> file? Please {this.supportLink()} and ask them to activate the <i>checkout.liquid</i> file by enabling <i>Maintenance</i> mode for your account. It should just take a few minutes and once enabled, click <i>Add a new layout</i> under the <i>Layout</i> section to add the <i>checkout</i> template.</b></span>
                      </Typography>
                      <img src={cdn + 'add_checkout_template.png'} alt='Add Checkout Template' className={classes.imageObj} style={{ maxWidth: 700 }} />;
                    </div>
                    :
                    null
                  }
                </GridItem>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button
                disabled={activeStep === 0}
                size='large'
                onClick={() => this.setState({ activeStep: this.state.activeStep - 1 })}
                className={classes.button}
              >
                <ChevronLeft fontSize='large'>Back</ChevronLeft>
                Back
              </Button>
              {
                activeStep < 4
                ?
                <Button
                  variant='contained'
                  size='large'
                  color='primary'
                  onClick={() => this.setState({ activeStep: this.state.activeStep + 1 })}
                  className={classes.button}
                  disabled={activeStep === 4}
                >
                  Next
                  <ChevronRight fontSize='large'>Next</ChevronRight>
                </Button>
                :
                <Button
                  variant='contained'
                  size='large'
                  color='primary'
                  onClick={() => this.close()}
                  className={classes.button}
                >
                  Finished
                </Button>
              }
            </DialogActions>
          </Dialog>
        </div>
      );
    else
      return null;
  }
};


export default withStyles(instructionsStyle)(Instructions);