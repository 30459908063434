import IconsIcon from '@material-ui/icons/Toys';
import SettingsIcon from '@material-ui/icons/Settings';

import Icons from 'views/festive_decorations/Icons.jsx';
import Settings from 'views/festive_decorations/Settings.jsx';


const Routes = [
  {
    path: '/festive_decorations_settings',
    sidebarName: 'Settings',
    navbarName: 'Settings',
    icon: SettingsIcon,
    component: Settings
  },
  {
    path: '/festive_decorations_icons',
    sidebarName: 'Icons',
    navbarName: 'Icons',
    icon: IconsIcon,
    component: Icons
  },
  { 
    redirect: true, 
    path: '/', 
    to: '/festive_decorations_settings', 
    navbarName: 'Redirect' 
  }
];

export default Routes;
