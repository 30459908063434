import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import _ from 'lodash';
import General from 'helper/general.js';
import GridItem from 'components/Grid/GridItem.jsx';
import GridContainer from 'components/Grid/GridContainer.jsx';
import { Button, FormControlLabel, InputLabel, MenuItem, Radio, RadioGroup, Select, TextField, Typography } from '@material-ui/core/';
import { NavLink } from 'react-router-dom';
import { Alert } from '@material-ui/lab/';
import sanitizeHtml from 'sanitize-html';

import Card from 'components/Card/Card.jsx';
import CardHeader from 'components/Card/CardHeader.jsx';
import CardBody from 'components/Card/CardBody.jsx';
import PromptsStyles from 'assets/jss/prompts.jsx';
import Loader from 'components/loader.jsx';
import Notice from '../../components/notice.jsx';
import AdminInfo from '../../components/adminInfo.jsx';
import ColorPicker from '../../components/colorPicker.jsx';
import InputChips from '../../components/inputChips.jsx';
import MoreInfo from '../../components/moreInfo.jsx';
import Snackbar from '../../components/snackBar.jsx';
import Toggle from '../../components/toggle.jsx';
import DescriptionBox from 'components/descriptionBox.jsx';
import UIExtPromptsPreview from '../../components/UIExtPromptsPreview.jsx'
import 'assets/css/prompts.scss';


const cdn = 'https://d3eci0d3sspd26.cloudfront.net/address_validator_admin_assets/';

const promptLocation = (brand) => {
  if(brand.shopify_plus && brand.validate_pre_checkout)
    return 'checkout_embed';
  else if(brand.shopify_plus && brand.precheckout_popup)
    return 'checkout_popup';
  else if(brand.thank_you_popup)
    return 'thank_you_popup';
  else
    return 'thank_you_embed';
};

const fullPromptLocationRadioOptions = [
  { value: 'checkout_popup', label: `<span>Modal window on Customer Info step (<b>recommended</b>)</span>`, type: 'checkout' },
  { value: 'checkout_embed', label: '<span>Embedded on Shipping Method step</span>', type: 'checkout' },
  { value: 'thank_you_popup', label: '<span>Modal window on Thank You page</span>', type: 'thank_you' },
  { value: 'thank_you_embed', label: '<span>Embedded on Thank You page</span>', type: 'thank_you' },
];

const limitedPromptLocationRadioOptions = [
  { value: 'thank_you_popup', label: '<span>Modal window (<b>recommended</b>)</span>', type: 'thank_you' },
  { value: 'thank_you_embed', label: '<span>Embedded below the map</span>', type: 'thank_you' },
];

const customText = [
  { bucket: 'suggestion', key: 'textSuggest1', label: 'Title', type: 'component' },
  { bucket: 'suggestion', key: 'textSuggest2', label: 'Subtitle', type: 'component' },
  { bucket: 'suggestion', key: 'textSuggestedAddress', label: 'Suggested address label', type: 'component' },
  { bucket: 'suggestion', key: 'textSuggestionCorrectedButton', label: 'Suggested address option button', type: 'component' },
  { bucket: 'suggestion', key: 'textOriginalAddress', label: 'Original address label', type: 'component' },
  { bucket: 'suggestion', key: 'textSuggestionOriginalButton', label: 'Original address option button', type: 'component' },
  { bucket: 'suggestion', key: 'textPopupEdit', label: 'Edit/close button label', type: 'component' },
  { bucket: 'suggestion', key: 'textPopupAccept', label: 'Accept changes button label', type: 'component' },
  { bucket: 'suggestion', key: 'textSelectSuggestion', label: 'Suggested address option selected', type: 'prompt' },
  { bucket: 'suggestion', key: 'textSelectConfirm', label: 'Original address option selected', type: 'prompt' },
  { bucket: 'invalid', key: 'textInaccurate', label: 'Prompt when an inaccurate address is detected', type: 'prompt' },
  { bucket: 'invalid', key: 'textAptSuite', label: 'Prompt when the apt # is missing', type: 'prompt' },
  { bucket: 'invalid', key: 'textPopupThanks', label: 'Message when customer proceeds with unverifiable addresses', type: 'prompt' },
  { bucket: 'invalid', key: 'buttonUpdate', label: 'Update address button label', type: 'component' },
  { bucket: 'invalid', key: 'buttonProceed', label: 'Proceed button label', type: 'component' },
  { bucket: 'invalid', key: 'updateConfirmButton', label: 'Button to open address correction form', placeholder: 'Update or confirm address' },
  { bucket: 'other', key: 'textThankYouPopupCloseButton', label: 'Close button on prompts', type: 'component' },
  { bucket: 'other', key: 'textLoading', label: 'Prompt when address is being validated', type: 'component' },
  { bucket: 'other', key: 'textCorrect', label: 'Message when a correct address is detected', type: 'prompt' },
];

const customTextCheckoutExt =  [
  { bucket: 'suggestion', key: 'textSuggest2', label: 'Title', type: 'component' },
  { bucket: 'invalid', key: 'textInaccurate', label: 'Prompt when an inaccurate address is detected', type: 'prompt' },
  { bucket: 'invalid', key: 'textAptSuite', label: 'Prompt when the apt # is missing', type: 'prompt' },
]

const colors = [
 { key: 'colorTrim', label: 'Trim' }, 
 { key: 'colorBorder', label: 'Border' }, 
 { key: 'colorBackground', label: 'Background' }, 
 { key: 'colorText', label: 'Text' }, 
 { key: 'colorHover', label: 'Hover' }, 
];

const buttonColors = [
  { key: 'colorButtonBackground', label: 'Button Background' }, 
  { key: 'colorButtonBorder', label: 'Button Border' }, 
  { key: 'colorButtonText', label: 'Button Text' }, 
];

const suggestionColors = [
  { key: 'colorHighlight', label: 'Highlight Changes' }, 
  { key: 'suggestionOptionBorderColor', label: 'Option Border' }, 
];

const windowOptions = [
  { value: 120, label: '2 minutes' },
  { value: 300, label: '5 minutes' },
  { value: 600, label: '10 minutes' },
  { value: 1800, label: '30 minutes' },
  { value: 3600, label: '1 hour' },
  { value: 7200, label: '2 hours' },
  { value: 10800, label: '3 hours' },
  { value: 21600, label: '6 hours' },
  { value: 43200, label: '12 hours' },
  { value: 86400, label: '1 day' },
  { value: 172800, label: '2 days' },
  { value: 259200, label: '3 days' },
  { value: 604800, label: '1 week' }
];


class Prompts extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      previewImg: null,
      open: false,
      openDialog: false,
      tempPromptLocation: null,
      tempPreviewImg: null,
    };
  }

  componentDidMount() {
    const brand = General.getBrandData();
    General.setStylesheet();

    this.setState(Object.assign(
      brand.customization, 
      _.pick(brand, ['form_window', 'plan_name', 'usps_only', 'us_separate_address_2', 'config_us_4_zip', 'hide_popup_thanks', 'loose_check', 'allow_popup_edit_button', 'skip_keywords', 'configured', 'shopify_plus', 'popup_accept_button', 'disable_thank_you_validate', 'thank_you_popup', 'config_email_address_incorrect_customer']),
      {
        promptLocation: promptLocation(brand),
        previewImg: `${cdn}${promptLocation(brand)}.jpg`,
        loading: false,
        style_css: brand.style_css || '',
      },
    ));
  }

  firstStep() {
    return this.state.promptLocation === 'checkout_popup';
  }
  popup() {
    return /popup/.test(this.state.promptLocation);
  }

  updateState(key, value) {
    let newState = this.state;
    newState[key] = value;
    newState.open = false;
    this.setState(newState);
  }

  toggleOption(key) {
    this.updateState(key, !this.state[key]);
    General.setBrandValue(key, this.state[key], (type) => this.setState({ open: true, alert_type: type }));
  }

  toggleHideShowOption(key) {
    const event = { target: { value: this.state[key] === 'show' ?  'hide' : 'show' } };
    this.updateState(key, event.target.value);
    General.changeText(event, key, (type) => this.setState({ open: true, alert_type: type }));
  }

  changePromptLocation(value) {
    const brand = General.getBrandData();

    const allowValidationAfterCheckout = /thank_you/.test(value) ? { disable_thank_you_validate: false } : {};

    if(value !== promptLocation(brand)) {
      this.setState(Object.assign({ 
        previewImg: `${cdn}${value}.jpg`, 
        promptLocation: value, 
        tempPreviewImg: null,
        tempPromptLocation: null, 
      }, allowValidationAfterCheckout));
      General.setPromptLocation(value, (type) => this.setState({ open: true, alert_type: type }));
    }
  }

  changeColor(color, key) {
    this.updateState(key, color.hex);
    General.changeColor(color, key, (type) => this.setState({ open: true, alert_type: type }));
  }

  changeText(event, key) {
    this.updateState(key, event.target.value);
    General.changeText(event, key, (type) => this.setState({ open: true, alert_type: type }));
  }

  // Add modal wrapper with classes dependent on location of prompt
  modalWrap(input) {
    const modalClass = this.popup() ? '' : 'not-first-step';

    return (
      <div id='myModal' className={modalClass}>
        <div id='myModal-container' className={modalClass} style={{ 
          backgroundColor: this.popup() ? this.state.colorTrim : '#fefefe',
          border: this.state.promptLocation === 'thank_you_embed' ? '1px solid black' : '',
          paddingTop: this.state.promptLocation === 'thank_you_embed' ? 20 : 0
        }}>
          {input}
        </div>
      </div>
    );
  }

  promptButtonStyle() {
    return { backgroundColor: this.state.colorButtonBackground, borderColor: this.state.colorButtonBorder, color: this.state.colorButtonText };
  }

  // Add address and buttons only if: (1) prompt is on the 1st step of checkout and (2) key is equal to textAptSuite or textInaccurate
  invalidAddressPrompts(key, input) {
    if(this.firstStep() && ['textAptSuite', 'textInaccurate'].indexOf(key) !== -1)
      return (
        <div>
          {input}
          {
            <div>
              <br />
              <span id='input-address'>
                {key === 'textAptSuite' ? 'Apartment Building Address' : 'Nonexistent Address'}
                <br />
                San Francisco, CA, 94105
              </span>
              <br />
              <div className='btn-row'>
                <button className='av_button' id='btn-update-address' style={this.promptButtonStyle()}>{this.state.buttonUpdate}</button>
                <button className='av_button' id='btn-proceed-address' style={this.promptButtonStyle()}>{this.state.buttonProceed}</button>
              </div>
            </div>
          }
        </div>
      );
    else if(/thank_you/.test(this.state.promptLocation) && ['textAptSuite', 'textInaccurate'].indexOf(key) !== -1)
      return (
        <div>
          {input}
          {
            <div>
              <br />
              <span id='input-address'>
                {key === 'textAptSuite' ? 'Apartment Building Address' : 'Nonexistent Address'}
                <br />
                San Francisco, CA, 94105
              </span>
              <br />
              <div className='btn-row'>
                {this.state.promptLocation === 'thank_you_popup' ? <button className='av_button' id='btn-update-address' style={this.promptButtonStyle()}>{this.state.textThankYouPopupCloseButton}</button> : null}
                <button className='av_button' id='btn-proceed-address' style={this.promptButtonStyle()}>{this.state.updateConfirmButton}</button>
              </div>
            </div>
          }
        </div>
      );
    else
      return input;
  }


  renderInputOrPreview(obj, type) {
    // Do not show if:
    // * !thank_you_popup && key === 'textThankYouPopupCloseButton'
    // * !popup && key IN [buttonProceed, buttonUpdate]
    // * !firstStep && key IN [textPopupThanks, textLoading]
    // * hide_popup_thanks && key IN [textCorrect, textPopupThanks]
    // * firstStep && !allow_popup_edit_button && key === textPopupEdit
    // * !firstStep && key === textPopupEdit
    // * !popup_accept_button && key === textPopupAccept
    // * !popup && key === textPopupAccept
    // * showSuggestionOptionButtons !== 'show' && (key === 'textSuggestionCorrectedButton' || key === 'textOriginalCorrectedButton')
    if(
      (this.state.promptLocation !== 'thank_you_popup' && obj.key === 'textThankYouPopupCloseButton') || 
      (!this.popup() && ['buttonProceed', 'buttonUpdate'].indexOf(obj.key) !== -1) ||
      (!this.firstStep() && ['textPopupThanks', 'textLoading'].indexOf(obj.key) !== -1) ||
      (this.state.hide_popup_thanks && ['textCorrect', 'textPopupThanks'].indexOf(obj.key) !== -1) ||
      (this.firstStep() && !this.state.allow_popup_edit_button && obj.key === 'textPopupEdit') ||
      (!this.firstStep() && obj.key === 'textPopupEdit') ||
      (!this.state.popup_accept_button && obj.key === 'textPopupAccept') ||
      (!this.popup() && obj.key === 'textPopupAccept') ||
      (this.state.showSuggestionOptionButtons !== 'show' && ['textOriginalCorrectedButton', 'textSuggestionCorrectedButton'].includes(obj.key))
    )
      return null;
    else {
      if(type === 'preview')
        return (
          <div key={obj.key} style={{ width: '100%' }}>
            <Typography variant='subtitle1' className={this.popup() ? 'preview-header-modal' : ''}>{obj.label}</Typography>
            {
              this.modalWrap(
              this.invalidAddressPrompts(
                obj.key,
                <div id='addressValidatorBox' style={{ backgroundColor: this.state.colorBackground, borderColor: this.state.colorBorder, color: this.state.colorText }}>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: sanitizeHtml(this.state[obj.key]),
                    }}
                  />
                </div>
              ))
            }
          </div>
        );
      else if(type === 'input')
        return (
          <GridItem xs={12} xl={6} key={obj.key}>
            <TextField
              key={obj.key}
              label={obj.label}
              multiline={true}
              InputLabelProps={{ shrink: true }}
              placeholder={''}
              value={this.state[obj.key]}
              onChange={(event) => this.changeText(event, obj.key)}
              margin='normal'
              fullWidth={true}
            />
          </GridItem>
        );
    }
  }

  locationDescription(location) {
    switch(location) {
      case 'checkout_popup':
        return 'The address prompts appear as a modal on the first step of checkout after customer clicks on the continue button. Orders processed via express payment options (ie PayPal) will be processed on the Thank You page after checkout. '
      case 'checkout_embed':
        return 'The address prompts are embedded on the second step of checkout above the shipping methods. Orders processed via express payment options (ie PayPal) will be processed on the Thank You page after checkout. '
      case 'thank_you_popup':
        return 'The address prompts appear as a modal on the Thank You page after checkout. '
      case 'thank_you_embed':
        return 'The address prompts are embedded below the map on the Thank You page after checkout. '
      default:
        return '';
    }
  }

  pauseApp() {
    General.pauseResumeApp('pause', () => {
      this.setState({ open: true, alert_type: 'success' });
      this.props.togglePause()
    });
  }

  resumeApp() {
    this.changePromptLocation(this.state.plan_name === 'shopify_plus' ? 'checkout_popup' : 'thank_you_popup');
    General.pauseResumeApp('resume', () => {
      this.setState({ open: true, alert_type: 'success' });
      this.props.togglePause()
    });
  }
  

  render() {
    const { classes } = this.props;

    const isUsingCheckoutLiquid = this.props.view === "Checkout Liquid";
    const promptsInCheckout = this.state.promptLocation ? (['checkout_popup', 'checkout_embed'].includes(this.state.promptLocation)) : true;
    const promptsAfterCheckout = !promptsInCheckout;

    if(this.state.loading)
      return <Loader />;
    else
      return (
        <div>
          <Snackbar
            alert_type={this.state.alert_type}
            close={() => this.setState({ open: false, alert_type: null })}
            open={this.state.open}
          />
          <AdminInfo 
            cb={(type) => this.setState({ open: true, alert_type: type })}
            brandData={General.getBrandData()}
            app='address_validator'
          />
          <GridContainer justifyContent='center'>
            <GridItem xs={12} md={10}>
              <DescriptionBox 
                classes={classes}
                content='This is the 1<sup>st</sup> line of defense against shipping addresses with mistakes. Address prompts either: (a) offer suggested corrections or (b) warn customers of invalid addresses when suggestions are not available. For example, it would be impossible to suggest an alternative address for "123 Test Street".'
                title='What are address prompts?'
              />
            </GridItem>
            {
              this.props.paused
              ?
              <GridItem xs={12} md={10}>
                <Card>
                  <CardBody className={classes.center}>
                    <GridContainer>
                      <GridItem xs={12}>
                        <Typography variant='h6'>
                          Address Validator is currently paused
                        </Typography>
                        <Button variant='contained' color='primary' size='large' onClick={() => this.resumeApp()}>Resume app</Button>
                      </GridItem>
                    </GridContainer>
                  </CardBody>
                </Card>
              </GridItem>
              :
              <GridItem xs={12} md={10}>
                <Card>
                  <CardHeader color='primary'>
                    <h3 className={classes.cardTitleWhite}>{this.state.shopify_plus ? 'Location of Address Prompts' : 'How does it work?'}</h3>
                  </CardHeader>
                  <CardBody>
                    <GridContainer justifyContent='center'>
                      <GridItem xs={12} sm={6} lg={5} xl={4}>
                        {
                          this.state.shopify_plus
                          ?
                          <div>
                            <Typography variant='subtitle1'><i>During</i> checkout:</Typography>
                            <RadioGroup
                              aria-label='Location of address prompts options during checkout'
                              name='prompt_location'
                              className={classes.group}
                              value={this.state.promptLocation}
                              onChange={(value) => this.changePromptLocation(value.target.value)}
                              style={{ marginLeft: 20 }}
                            >
                              {_.map(_.filter(fullPromptLocationRadioOptions, (option) => isUsingCheckoutLiquid ? option.type === 'checkout' : (option.type === 'checkout' && option.value !== 'checkout_embed')), (option) => <FormControlLabel 
                                key={option.value} 
                                className='radio-option' 
                                value={option.value} 
                                control={<Radio color='primary' />} 
                                label={<span dangerouslySetInnerHTML={{ __html: option.label} } />} 
                                onMouseOver={() => this.setState({ tempPromptLocation: option.value, tempPreviewImg: cdn + option.value + '.jpg' })} 
                                onMouseOut={() => this.setState({ tempPromptLocation: null, tempPreviewImg: null })} 
                              />)}
                            </RadioGroup>
                            <Typography variant='subtitle1'><i>After</i> checkout:</Typography>
                            <RadioGroup
                              aria-label='Location of address prompts options on Thank You page'
                              name='prompt_location'
                              className={classes.group}
                              value={this.state.promptLocation}
                              onChange={(value) => this.changePromptLocation(value.target.value)}
                              style={{ marginLeft: 20 }}
                            >
                              {_.map(_.filter(fullPromptLocationRadioOptions, (option) => option.type === 'thank_you'), (option) => <FormControlLabel 
                                key={option.value} 
                                className='radio-option' 
                                value={option.value} 
                                control={<Radio color='primary' />} 
                                label={<span dangerouslySetInnerHTML={{ __html: option.label} } />} 
                                onMouseOver={() => this.setState({ tempPromptLocation: option.value, tempPreviewImg: cdn + option.value + '.jpg' })} 
                                onMouseOut={() => this.setState({ tempPromptLocation: null, tempPreviewImg: null })} 
                              />)}
                            </RadioGroup>
                            {
                              !this.state.configured && !/thank_you/.test(this.state.promptLocation)
                              ?
                              <Alert severity={this.state.shopify_plus ? 'error' : 'warning'} id='location_prompt_instructions_alert'>Insert code snippet to enable prompts during checkout - click "Show Instructions" on the top right</Alert>
                              :
                              null
                            }
                            {
                              this.state.configured && /thank_you/.test(this.state.promptLocation)
                              ?
                              <Alert severity='warning' id='location_prompt_instructions_alert'>The prompts appear after checkout. You can embed them into the checkout with one of the other options.</Alert>
                              :
                              null
                            }
                            <hr />
                            <p className={classes.cardDescriptionLeft}>
                              {this.locationDescription(this.state.tempPromptLocation || this.state.promptLocation)}
                            </p>
                            <p className={classes.cardDescriptionLeft}>
                              The Shopify order will be updated automatically whenever a customer updates the shipping address via the prompts.
                            </p>
                          </div>
                          :
                          <div>
                            <Typography variant='subtitle1'>The address prompts appear on the Thank You page <i>after</i> checkout.</Typography>
                            <br />
                            <Typography variant='subtitle1'>How do you want the prompts to appear?</Typography>
                            <RadioGroup
                              aria-label='Location of address prompts options on thank you page'
                              name='prompt_location'
                              className={classes.group}
                              value={this.state.promptLocation}
                              onChange={(value) => this.changePromptLocation(value.target.value)}
                              style={{ marginLeft: 20 }}
                            >
                              {_.map(_.filter(limitedPromptLocationRadioOptions, (option) => option.type === 'thank_you'), (option) => <FormControlLabel 
                                key={option.value} 
                                className='radio-option' 
                                value={option.value} 
                                control={<Radio color='primary' />} 
                                label={<span dangerouslySetInnerHTML={{ __html: option.label} } />} 
                                onMouseOver={() => this.setState({ tempPromptLocation: option.value, tempPreviewImg: cdn + option.value + '.jpg' })} 
                                onMouseOut={() => this.setState({ tempPromptLocation: null, tempPreviewImg: null })} 
                              />)}
                            </RadioGroup>
                            <p className={classes.cardDescriptionLeft}>
                              Shipping addresses on the Shopify orders will be updated automatically when customers select the suggested address or make changes via the <NavLink to='/forms'><i>address form</i></NavLink>.
                            </p>
                            <p className={classes.cardDescriptionLeft}>
                              You can see the app on the Thank You page by going through the entire checkout process, including the payment step.
                            </p>
                            {
                              this.state.configured
                              ?
                              <p className={classes.cardDescriptionLeft}>
                                <i>Address Validator can only load during checkout with <a href='https://www.shopify.com/plus' target='_blank' rel='noopener noreferrer'>Shopify Plus</a> which gives you access to the <a href='https://shopify.dev/themes/architecture/layouts/checkout-liquid' target='_blank' rel='noopener noreferrer'>checkout.liquid file</a> to customize the checkout.</i>
                              </p>
                              :
                              <p className={classes.cardDescriptionLeft}>
                                Please contact us at <a href='mailto:support@roboturk.co'>support@roboturk.co</a> if you have <a href='https://www.shopify.com/plus' target='_blank' rel='noopener noreferrer'>Shopify Plus</a> to incorporate the address prompts into the checkout. Otherwise, the app can only load after checkout due to Shopify limitations.
                              </p>
                            }
                          </div>
                        }
                      </GridItem>
                      <GridItem xs={12} sm={6} lg={5} xl={4}>
                        <div style={{ boxShadow: '0 0 10px #377dff', lineHeight: 0, padding: 5 }}>
                          {
                            this.state.shopify_plus
                            ?
                            <img src={this.state.tempPreviewImg || this.state.previewImg} style={{ width: '100%', height: 'auto' }} alt='prompt preview' />
                            :
                            <img src={cdn + (this.state.promptLocation === 'thank_you_popup' ? 'thank_you_popup_wide.gif' : 'thank_you_embed_wide.gif')} style={{ width: '100%', height: 'auto' }} alt='prompt preview' />
                          }
                        </div>
                      </GridItem>
                    </GridContainer>
                  </CardBody>
                </Card>
              </GridItem>
            }
            <GridItem sm={12} md={10} lg={5}>
              <Card>
                <CardHeader color='primary'>
                  <h3 className={classes.cardTitleWhite}>US address validation options</h3>
                </CardHeader>
                <CardBody className={classes.center}>
                  <p className={classes.cardDescription}>
                    Do you only ship with USPS? 
                    <MoreInfo note='UPS/FedEx can ship to some addresses USPS cannot. This option determines the appropriate data sources to use for validation.' />
                  </p>
                  <Toggle value={this.state.usps_only} onChange={() => this.toggleOption('usps_only')} yes_label='Yes' no_label='No' />
                  <hr />
                  <p className={classes.cardDescription}>
                    Do you want to ignore ZIP+4 codes?
                    <MoreInfo note='ZIP+4 codes are the last 4 digits of a 9-digit zipcode. While not required by USPS, these can help ensure the fastest and most accurate delivery possible.' />
                  </p>
                  <Toggle value={this.state.config_us_4_zip} onChange={() => this.toggleOption('config_us_4_zip')} yes_label='Yes' no_label='No' />
                  <hr />
                  <p className={classes.cardDescription}>
                    Do you need to separate the street address from the apt #?
                    <MoreInfo note='USPS formatting dictates that the apartment # follow the street address on the same field. However, some 3PLs require the two components in separate fields (address_1 and address_2).' />
                  </p>
                  <Toggle value={this.state.us_separate_address_2} onChange={() => this.toggleOption('us_separate_address_2')} yes_label='Yes' no_label='No' />
                </CardBody>
              </Card>
            </GridItem>
            <GridItem sm={12} md={10} lg={5}>
              <Card>
                <CardHeader color='primary'>
                  <h3 className={classes.cardTitleWhite}>Advanced options</h3>
                </CardHeader>
                <CardBody className={classes.center}>
                  <p className={classes.cardDescription}>
                    Do you want to ignore proper address formatting?
                    <MoreInfo note='If enabled, no suggested changes will be shown if the only discrepancies are merely formatting, capitalizations or abbreviations. For example, "20 main st" would be equivalent to "20 Main Street" and no suggested changes will be shown. While proper formatting is not required for accurate delivery, it may be useful for other purposes.' />
                  </p>
                  <Toggle value={this.state.loose_check} onChange={() => this.toggleOption('loose_check')} yes_label='Yes' no_label='No' />
                  <hr />
                  <p className={classes.cardDescription}>
                    Specify list of keywords to skip address validation
                    <MoreInfo note='This allows you to bypass the validation process for test or in-store pickup orders. Whenever the address_1, address_2, or company fields contains any of the keywords, the address validation will be skipped. The keywords are not case sensitive.' />
                  </p>
                  <InputChips list={this.state.skip_keywords} label='Keyword to skip address validation' onChange={(value) => this.changeText(value, 'skip_keywords')}/>
                  {
                    !/thank_you/.test(this.state.promptLocation) && this.state.configured && !this.props.paused
                    ?
                    <div>
                      <hr />
                      <p className={classes.cardDescription}>
                        Do you want to disable address validation after checkout?
                        <MoreInfo note='Orders that bypass the regular checkout process can only be valiated on the Thank You page after checkout. You may want to only validate addresses during checkout if your 3PL or delivery partner cannot receive any order updates.' />
                      </p>
                      <Toggle value={this.state.disable_thank_you_validate} onChange={() => this.toggleOption('disable_thank_you_validate')} yes_label='Yes' no_label='No' />

                    </div>
                    :
                    null
                  }
                  {
                    this.state.config_email_address_incorrect_customer && this.state.disable_thank_you_validate
                    ?
                    <b>Disable customer emails on the <i>Email Notifications</i> tab to prevent customers from updating the shipping address after checkout</b>
                    :
                    null
                  }
                  {
                    this.state.disable_thank_you_validate
                    ?
                    null
                    :
                    <div>
                      <p className={classes.cardDescription}>How much time does the customer have to update the order after the checkout is complete?</p>
                      <Select
                        value={this.state.form_window || 259200}
                        onChange={(event) => this.changeText(event, 'form_window')}
                        margin='dense'
                        fullWidth={true}
                      >
                        {_.map(windowOptions, (obj) => <MenuItem key={obj.value} value={obj.value}>{obj.label}</MenuItem>)}
                      </Select>
                    </div>
                  }
                  {
                    this.props.paused
                    ?
                    ''
                    :
                    <div>
                      <hr />
                      <p className={classes.cardDescription}>
                        Need to pause the app while you configure the settings?
                      </p>
                      <Button variant='contained' size='small' onClick={() => this.pauseApp()}>Pause app</Button>
                    </div>
                  }
                </CardBody>
              </Card>
            </GridItem>
            <GridItem sm={12} md={10}>
              <Card>
                <CardHeader color='primary'>
                  <h3 className={classes.cardTitleWhite}>General customizations</h3>
                </CardHeader>
                <CardBody className={classes.center}>
                  <GridContainer>
                    <GridItem md={12} lg={6}>
                      <GridContainer justifyContent='center'>
                        {
                          (promptsAfterCheckout || isUsingCheckoutLiquid) ?
                            <>
                              {
                                _.map(colors, (colorObj) => (
                                  <GridItem xs={12} md={4} key={colorObj.key}>
                                    <Typography className={classes.cardDescription}>{colorObj.label}</Typography>
                                    <ColorPicker 
                                      color={this.state[colorObj.key]} 
                                      onChangeComplete={ (color) => this.changeColor(color, colorObj.key) } 
                                    />
                                  </GridItem>
                                ))
                              }
                              <GridItem xs={12}></GridItem>
                              {
                                _.map(buttonColors, (colorObj) => (
                                  <GridItem xs={12} md={4} key={colorObj.key}>
                                    <Typography className={classes.cardDescription}>{colorObj.label}</Typography>
                                    <ColorPicker 
                                      color={this.state[colorObj.key]} 
                                      onChangeComplete={ (color) => this.changeColor(color, colorObj.key) } 
                                    />
                                  </GridItem>
                                ))
                              }
                              <br />
                              <GridItem xs={12}></GridItem>
                              <br />
                              <GridItem xs={12} md={4}>
                                <RadioGroup aria-label='Corner Style' name='Corner Style' value={this.state.cornerStyle} onChange={(event) => this.changeText(event, 'cornerStyle')}>
                                  <FormControlLabel value='box' control={<Radio color='primary' />} label='Box Corners' />
                                  <FormControlLabel value='round' control={<Radio color='primary' />} label='Round Corners' />
                                </RadioGroup>
                              </GridItem>
                              <GridItem xs={12} md={4}>
                                <InputLabel id='border-width-selector'>Border width</InputLabel>
                                <Select id='border-width-selector' value={this.state.borderWidth} onChange={(event) => this.changeText(event, 'borderWidth')}>
                                  {_.map(_.range(0, 5.5, 0.5), (val) => <MenuItem key={val} value={val + 'px'}>{val.toFixed(1) + 'px'}</MenuItem>)}
                                </Select>
                              </GridItem>
                              <GridItem xs={12} md={4}>
                                <p className={classes.cardDescription}>
                                  Outline focused elements?
                                  <MoreInfo note='This may be required for ADA compliance and allows customers to easily toggle between options on the keyboard' />
                                </p>
                                <Toggle value={this.state.showFocusOutline === 'show'} onChange={() => this.toggleHideShowOption('showFocusOutline')} yes_label='Yes' no_label='No' />

                              </GridItem>
                            </>
                            :
                            null
                        }
                        {
                          isUsingCheckoutLiquid && <GridItem xs={12}>
                          <p className={classes.cardDescription}>
                            {
                              this.firstStep() && this.state.shopify_plus
                              ?
                              `Do you want to hide messages when a customer either proceeds with an unverifiable address or specifies a correct address?`
                              :
                              `Do you want to hide messages when a customer specifies a correct address?`
                            }
                          </p>
                          <Toggle value={this.state.hide_popup_thanks} onChange={() => this.toggleOption('hide_popup_thanks')} yes_label='Yes' no_label='No' />
                          <hr />
                        </GridItem>
                        }
                        {_.map(_.filter(customText, (text) => text.bucket === 'other'), (data) => this.renderInputOrPreview(data, 'input'))}
                        {
                          (promptsAfterCheckout || isUsingCheckoutLiquid) ?
                            <GridItem xs={12}>
                              <Typography className={classes.cardDescription}>Input custom CSS:</Typography>
                              <TextField
                                rows={5}
                                multiline={true}
                                InputLabelProps={{ shrink: true }}
                                placeholder={`Example: \n#addressValidatorBox {\n  font-family: Arial, Helvetica, sans-serif;\n  color: #32CD32;\n}`}
                                value={this.state.style_css}
                                onChange={(event) => this.changeText(event, 'style_css')}
                                className={this.props.classes.textField}
                                margin='normal'
                                fullWidth={true}
                              />
                            </GridItem>
                            :
                            null
                        }
                      </GridContainer>
                    </GridItem>
                    <GridItem md={12} lg={6}>
                      <GridContainer justifyContent='center'>
                        {
                          this.firstStep()
                          ?
                          (isUsingCheckoutLiquid ?
                            <div style={{ width: '100%' }}>
                              <Typography variant='subtitle1' className={'preview-header-modal'}>Prompt when address is being validated</Typography>
                              {this.modalWrap(<div><p id='loader-text'>{this.state.textLoading}</p><div id='loader-spinner' /></div>)}
                            </div>
                            :
                            <UIExtPromptsPreview label="Prompt when address is being validated" textValue={this.state.textLoading} validating/>
                          )
                          :
                          null
                        }
                        {(promptsAfterCheckout || isUsingCheckoutLiquid) ? _.map(_.filter(customText, (text) => text.bucket === 'other' && text.type === 'prompt'), (data) => this.renderInputOrPreview(data, 'preview')) : <UIExtPromptsPreview label="Message when a correct address is detected" textValue={this.state.textCorrect} successMessage />}
                      </GridContainer>
                    </GridItem>
                  </GridContainer>
                </CardBody>
              </Card>
            </GridItem>
            {
              (this.firstStep() && this.state.shopify_plus && isUsingCheckoutLiquid)
              ?
              <GridItem xs={12} md={10}>
                <Card>
                  <CardHeader color='primary'>
                    <h3 className={classes.cardTitleWhite}>Missing required fields on first step of checkout</h3>
                  </CardHeader>
                  <CardBody>
                    <GridContainer justifyContent='center'>
                      <GridItem xs={12}>
                        <p className={this.props.classes.cardDescription}>Customizations to highlight missing required fields on checkout:</p>
                      </GridItem>
                      <GridItem xs={2}>
                        <InputLabel id='border-width-selector'>Border color</InputLabel>
                        <ColorPicker 
                          color={this.state.checkoutBorderColor || '#FF0000'} 
                          onChangeComplete={ (color) => this.changeColor(color, 'checkoutBorderColor') } 
                        />
                      </GridItem>
                      <GridItem xs={2}>
                        <InputLabel id='border-width-selector'>Border width</InputLabel>
                        <Select id='border-width-selector' value={this.state.checkoutBorderWidth || '2px'} onChange={(event) => this.changeText(event, 'checkoutBorderWidth')}>
                          {_.map(_.range(0, 5.5, 0.5), (val) => <MenuItem key={val} value={val + 'px'}>{val.toFixed(1) + 'px'}</MenuItem>)}
                        </Select>
                      </GridItem>
                      <GridItem xs={4}>
                        <TextField
                          key='textMissingFields'
                          label='Message when some required fields are missing'
                          placeholder={''}
                          value={this.state.textMissingFields}
                          onChange={(event) => this.changeText(event, 'textMissingFields')}
                          margin='normal'
                          fullWidth={true}
                        />
                      </GridItem>
                      <GridItem xs={4}>
                      <p className={classes.cardDescription}>Input CSS applied to missing fields:</p>
                      <TextField
                        rows={3}
                        multiline={true}
                        InputLabelProps={{ shrink: true }}
                        placeholder={`Example: \n font-family: Arial, Helvetica, sans-serif;\n  color: #32CD32;`}
                        value={this.state.checkoutFieldsCSS || ''}
                        onChange={(event) => this.changeText(event, 'checkoutFieldsCSS')}
                        className={this.props.classes.textField}
                        margin='normal'
                        fullWidth={true}
                      />
                    </GridItem>
                    </GridContainer>
                  </CardBody>
                </Card>
              </GridItem>
              :
              null
            }
            <GridItem sm={12} md={10}>
              <Card>
                <CardHeader color='primary'>
                  <h3 className={classes.cardTitleWhite}>Customize suggested corrections prompt</h3>
                </CardHeader>
                <CardBody className={classes.center}>
                  <GridContainer>
                    <GridItem md={12} lg={6}>
                      <GridContainer justifyContent='center'>
                        {
                          (this.firstStep() && this.state.shopify_plus && isUsingCheckoutLiquid) || this.state.promptLocation === 'thank_you_popup'
                          ?
                          <GridItem xs={12} md={5} className={classes.center}>
                            <p className={classes.cardDescription}>
                              Show exit button?
                              <MoreInfo note='If enabled, customers can exit of the prompt without confirming the original address or using the suggested corrections.' />
                            </p>
                            <Toggle value={this.state.allow_popup_edit_button} onChange={() => this.toggleOption('allow_popup_edit_button')} yes_label='Yes' no_label='No' />
                          </GridItem>
                          :
                          null
                        }
                        {
                          (this.firstStep() && this.state.shopify_plus && isUsingCheckoutLiquid) || this.state.promptLocation === 'thank_you_popup'
                          ?
                          <GridItem xs={12} md={7} className={classes.center}>
                            <p className={classes.cardDescription}>
                              Show accept button?
                              <MoreInfo note='If enabled, customers can accept the corrected address via the button or clicking on the suggested address.' />
                            </p>
                            <Toggle value={this.state.popup_accept_button} onChange={() => this.toggleOption('popup_accept_button')} yes_label='Yes' no_label='No' />
                          </GridItem>
                          :
                          null
                        }
                        {
                          (promptsAfterCheckout || isUsingCheckoutLiquid) ?
                            <>
                              <GridItem xs={12} md={4}>
                                <p className={classes.cardDescription}>
                                  Show divider?
                                  <MoreInfo note='If enabled, a divider will be added between the suggested and original address options.' />
                                </p>
                                <Toggle value={this.state.suggestionDivider === 'show'} onChange={() => this.toggleHideShowOption('suggestionDivider')} yes_label='Yes' no_label='No' />
                              </GridItem>
                              <GridItem xs={12} md={4}>
                                <p className={classes.cardDescription}>
                                  Show option borders?
                                  <MoreInfo note='If enabled, borders will be added to the address options.' />
                                </p>
                                <Toggle value={this.state.suggestionOptionBorderDisplay === 'show'} onChange={() => this.toggleHideShowOption('suggestionOptionBorderDisplay')} yes_label='Yes' no_label='No' />
                              </GridItem>
                              <GridItem xs={12} md={4}>
                                <p className={classes.cardDescription}>
                                  Show option buttons?
                                  <MoreInfo note='If enabled, buttons will be added next to the address options.' />
                                </p>
                                <Toggle value={this.state.showSuggestionOptionButtons === 'show'} onChange={() => this.toggleHideShowOption('showSuggestionOptionButtons')} yes_label='Yes' no_label='No' />
                              </GridItem>
                            </>
                            :
                            null
                        }
                        {
                          (promptsAfterCheckout || isUsingCheckoutLiquid) ? _.map(suggestionColors, (colorObj) => (
                            <GridItem xs={12} md={4} key={colorObj.key}>
                              <Typography className={classes.cardDescription}>{colorObj.label}</Typography>
                              <ColorPicker 
                                color={this.state[colorObj.key]} 
                                onChangeComplete={ (color) => this.changeColor(color, colorObj.key) } 
                              />
                            </GridItem>
                          ))
                          :
                          null
                        }
                        {
                          _.map(
                            _.filter(
                              (promptsAfterCheckout || isUsingCheckoutLiquid) ? customText : customTextCheckoutExt,
                              (text) => text.bucket === 'suggestion'),
                            (data) => this.renderInputOrPreview(data, 'input')
                          )
                        }
                      </GridContainer>
                    </GridItem>
                    <GridItem md={12} lg={6}>
                      <GridContainer justifyContent='center'>
                        {(promptsAfterCheckout || isUsingCheckoutLiquid) ? <div style={{ width: '100%' }}>
                          <Typography variant='subtitle1' className={this.popup() ? 'preview-header-modal' : 'preview-header'}>Suggested corrections prompt</Typography>
                          {this.modalWrap(
                          <div id='addressValidatorBox' style={{ backgroundColor: this.state.colorBackground, borderColor: this.state.colorBorder, color: this.state.colorText }}>
                            <h2>{this.state.textSuggest1}</h2>
                            <small>{this.state.textSuggest2}</small>
                            <br /><br />
                            <div id='suggestedAddress'>
                              <button>
                                <b><i>{this.state.textSuggestedAddress}</i></b>
                                <br />
                                <span className='changed' style={{ color: this.state.colorHighlight }}>400 Howard St</span><br /><span className='changed' style={{ color: this.state.colorHighlight }}>San Francisco</span>, <span>CA</span> <span>94105</span><span className='changed' style={{ color: this.state.colorHighlight }}>-2618</span>
                              </button>
                              <div className='break'></div>
                              {
                                this.state.showSuggestionOptionButtons === 'show'
                                ?
                                <div className='option_btns' id='select_suggested_btn'>
                                  <div className='option_btns_text' style={this.promptButtonStyle()}>{this.state.textSuggestionCorrectedButton}</div>
                                </div>
                                :
                                null
                              }
                            </div>
                            <hr />
                            <div id='originalAddress'>
                              <button>
                                <b><i>{this.state.textOriginalAddress}</i></b>
                                <br />
                                <span>400 howard</span><br /><span>san francisco</span>, <span>CA</span> <span>94105</span>
                              </button>
                              <div className='break'></div>
                              {
                                this.state.showSuggestionOptionButtons === 'show'
                                ?
                                <div className='option_btns' id='select_original_btn'>
                                  <div className='option_btns_text' style={this.promptButtonStyle()}>{this.state.textSuggestionOriginalButton}</div>
                                </div>
                                :
                                null
                              }
                            </div>
                            {
                              this.state.allow_popup_edit_button || this.state.popup_accept_button
                              ?
                              <div className='btn-row'>
                                {this.state.allow_popup_edit_button && this.firstStep() ? <button className='av_button' id='btn-edit-address' style={this.promptButtonStyle()}>{this.state.textPopupEdit}</button> : null}
                                {this.state.allow_popup_edit_button && this.state.promptLocation === 'thank_you_popup' ? <button className='av_button' id='btn-edit-address' style={this.promptButtonStyle()}>{this.state.textThankYouPopupCloseButton}</button> : null}
                                {this.state.popup_accept_button ? <button className='av_button' id='btn-accept-address' style={this.promptButtonStyle()}>{this.state.textPopupAccept}</button> : null}
                              </div>
                              :
                              null
                            }
                          </div>
                          )}
                        </div> : <UIExtPromptsPreview label="Suggested corrections prompt" textValue={this.state.textSuggest2} suggestion />}
                        {(promptsAfterCheckout || isUsingCheckoutLiquid) ? _.map(_.filter(customText, (text) => text.bucket === 'suggestion' && text.type === 'prompt'), (data) => this.renderInputOrPreview(data, 'preview')) : null}
                      </GridContainer>
                    </GridItem>
                  </GridContainer>
                </CardBody>
              </Card>
            </GridItem>
            <GridItem sm={12} md={10}>
              <Card>
                <CardHeader color='primary'>
                  <h3 className={classes.cardTitleWhite}>Customize unverifiable addresses prompts</h3>
                </CardHeader>
                <CardBody className={classes.center}>
                  <GridContainer>
                    <GridItem md={12} lg={6}>
                      <GridContainer justifyContent='center'>
                        {_.map(_.filter((promptsAfterCheckout || isUsingCheckoutLiquid) ? customText : customTextCheckoutExt, (text) => text.bucket === 'invalid'), (data) => this.renderInputOrPreview(data, 'input'))}
                      </GridContainer>
                    </GridItem>
                    <GridItem md={12} lg={6}>
                      <GridContainer justifyContent='center'>
                        {_.map(_.filter((promptsAfterCheckout || isUsingCheckoutLiquid) ? customText : customTextCheckoutExt, (text) => text.bucket === 'invalid' && text.type === 'prompt'), (data) => (promptsAfterCheckout || isUsingCheckoutLiquid) ? this.renderInputOrPreview(data, 'preview') : <UIExtPromptsPreview label={data.label} textValue={this.state[data.key]} />)}
                      </GridContainer>
                    </GridItem>
                  </GridContainer>
                </CardBody>
              </Card>
            </GridItem>
            <Notice open={this.state.openDialog} close={() => this.setState({ openDialog: false })} />
          </GridContainer>
        </div>
      );
  }
}

export default withStyles(PromptsStyles)(Prompts);