import AlertIcon from '@material-ui/icons/Warning';
import AnalyticsIcon from '@material-ui/icons/Timeline';
import BillingIcon from '@material-ui/icons/CreditCard';
import EmailsIcon from '@material-ui/icons/Email';
import FormsIcon from '@material-ui/icons/Assignment';
import OrderHistoryIcon from '@material-ui/icons/History';
import OrderTagsIcon from '@material-ui/icons/Label';
import PromptsIcon from '@material-ui/icons/Message';
import ReChargeIcon from '@material-ui/icons/Autorenew';
import SettingsIcon from '@material-ui/icons/AccountCircle';
import AppsIcon from '@material-ui/icons/FlashOn';
import SecurityIcon from '@material-ui/icons/Security';

import ValidationRules from 'views/address_validator/ValidationRules.jsx';
import Analytics from 'views/address_validator/Analytics.jsx';
import Billing from 'views/address_validator/Billing.jsx';
import Emails from 'views/address_validator/Emails.jsx';
import Forms from 'views/address_validator/Forms.jsx';
import OrderHistory from 'views/address_validator/OrderHistory.jsx';
import OrderTags from 'views/address_validator/OrderTags.jsx';
import Prompts from 'views/address_validator/Prompts.jsx';
import ReCharge from 'views/address_validator/ReCharge.jsx';
import Settings from 'views/address_validator/Settings.jsx';
import User from 'views/address_validator/User.jsx';
import PowerUps from 'views/address_validator/PowerUps.jsx';
import OrderProtection from 'views/address_validator/OrderProtection.jsx';

const Routes = [
  {
    path: '/address_prompts',
    sidebarName: 'Address Prompts',
    navbarName: 'Address Prompts',
    icon: PromptsIcon,
    component: Prompts
  },
  {
    path: '/validation_rules',
    sidebarName: 'Validation Rules',
    navbarName: 'Validation Rules',
    icon: AlertIcon,
    component: ValidationRules,
  },
  {
    path: '/email_notifications',
    sidebarName: 'Email Notifications',
    navbarName: 'Email Notifications',
    icon: EmailsIcon,
    component: Emails
  },
  {
    path: '/forms',
    sidebarName: 'Update Forms',
    navbarName: 'Update Forms',
    icon: FormsIcon,
    component: Forms
  },
  {
    path: '/order_tags',
    sidebarName: 'Order Tags',
    navbarName: 'Order Tags',
    icon: OrderTagsIcon,
    component: OrderTags
  },
  {
    path: '/recharge',
    sidebarName: 'ReCharge Orders',
    navbarName: 'ReCharge Orders',
    icon: ReChargeIcon,
    component: ReCharge
  },
  {
    path: '/analytics',
    sidebarName: 'Analytics',
    navbarName: 'Analytics',
    icon: AnalyticsIcon,
    component: Analytics
  },
  {
    path: '/billing',
    sidebarName: 'Billing',
    navbarName: 'Billing',
    icon: BillingIcon,
    component: Billing
  },
  {
    path: '/order_history',
    sidebarName: 'Order History',
    navbarName: 'Order History',
    icon: OrderHistoryIcon,
    component: OrderHistory
  },
  {
    path: '/settings',
    sidebarName: 'Account Settings',
    navbarName: 'Account Settings',
    icon: SettingsIcon,
    component: Settings
  },
  {
    path: '/user',
    sidebarName: 'User',
    component: User
  },
  {
    path: '/order_protection',
    sidebarName: 'Order Protection',
    navbarName: 'Order Protection',
    icon: SecurityIcon,
    component: OrderProtection,
    highlight: true
  },
  {
    path: '/powerups',
    sidebarName: 'Power-Ups',
    navbarName: 'Power-Ups',
    icon: AppsIcon,
    component: PowerUps
  },
  { 
    redirect: true, 
    path: '/', 
    to: '/address_prompts', 
    navbarName: 'Redirect' 
  } 
];

export default Routes;
