import React from "react";
import _ from "lodash";
import { Button, Chip, TextField } from "@material-ui/core/";

class InputChips extends React.Component {
  state = {
    input: "",
    list: [],
  };

  // Runs on both mount and update
  // Used to initiate and update state based on props
  static getDerivedStateFromProps(props, state) {
    const concatState = state.list.join(",");

    if (props.list !== concatState) {
      return {
        list: props.list ? props.list.split(",") : [],
      };
    }

    return null;
  }

  changeText(event) {
    this.setState({ input: event.target.value });
  }

  keyPress(event) {
    if (
      this.state.input &&
      event.key === "Enter" &&
      this.state.list.indexOf(this.state.input) === -1
    ) {
      let newList = this.state.list.concat(this.state.input);
      this.setState({ list: newList, input: "" });
      this.props.onChange({ target: { value: newList.join(",") } });
    }
  }

  removeItem(target) {
    let newList = this.state.list;
    _.remove(newList, (obj) => obj === target);
    this.setState({ list: newList });
    this.props.onChange({ target: { value: newList.join(",") } });
  }

  render() {
    return (
      <div>
        <TextField
          label={this.props.label}
          value={this.state.input}
          onChange={(event) => this.changeText(event)}
          onKeyPress={(event) => this.keyPress(event)}
          margin="normal"
          fullWidth={true}
          InputProps={{
            endAdornment: (
              <Button
                variant="contained"
                color="primary"
                size="small"
                onClick={() => this.keyPress({ key: "Enter" })}
              >
                Add
              </Button>
            ),
          }}
        />
        {_.map(this.state.list, (obj) => (
          <Chip
            size="small"
            variant="outlined"
            key={obj}
            label={obj}
            onDelete={() => this.removeItem(obj)}
            style={{ margin: 2 }}
          />
        ))}
      </div>
    );
  }
}

export default InputChips;
