import { 
  cardCategoryWhite, 
  cardTitleWhite
} from 'assets/jss/theme.jsx';


const ReChargeStyle = theme => ({
  cardCategoryWhite,
  cardTitleWhite,
  cardDescription: {
    fontSize: '16px',
    marginBottom: '0px',
    textAlign: 'center'
  },
  center: {
    textAlign: 'center'
  },
  codeBox: {
    whiteSpace: 'pre-wrap', 
    fontSize: 12, 
    backgroundColor: '#f5f5f5', 
    borderRadius: 5,
    border: '2px solid black', 
    textAlign: 'center', 
    padding: 5,
    wordWrap: 'break-word'
  },
  images: {
    marginBottom: '15px',
    marginTop: '15px',
    textAlign: 'center'
  },
  imageObj: {
    maxWidth: '100%', 
    maxHeight: '500px', 
    height: 'auto'
  },
  sideStepContainer: {
    textAlign: 'center',
    cursor: 'pointer'
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  done_label: {
    color: theme.palette.success.dark,
    float: 'right',
    marginRight: 20
  }
});

export default ReChargeStyle;