import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import withStyles from '@material-ui/core/styles/withStyles';
import { AppBar, Hidden, IconButton, Toolbar } from '@material-ui/core';
import Menu from '@material-ui/icons/Menu';
import HeaderLinks from './headerLinks.jsx';

import headerStyle from 'assets/jss/header.jsx';


class Header extends React.Component {
  render() {
    const { classes, handleDrawerToggle, location, routes } = this.props;

    const generateTitle = () => {
      let route = _.find(routes, (route) => route.path === location.pathname);
      if(route)
        return route.navbarName;
      else
        return null;
    }
    
    return (
      <AppBar className={classes.appBar}>
        <Toolbar className={classes.container}>
          <div className={classes.flex}>
            <span className={classes.title}>
              {generateTitle()}
            </span>
          </div>
          <Hidden smDown implementation='css'>
            <HeaderLinks app={this.props.app} openInstructions={this.props.openInstructions} />
          </Hidden>
          <Hidden mdUp implementation='css'>
            <IconButton className={classes.iconButton} aria-label='open drawer' onClick={handleDrawerToggle}>
              <Menu />
            </IconButton>
          </Hidden>
        </Toolbar>
      </AppBar>
    );
  }
}

Header.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(headerStyle)(Header);