import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import GridContainer from "components/Grid/GridContainer.jsx";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import GridItem from "components/Grid/GridItem.jsx";
import AlertIcon from "@material-ui/icons/Warning";
import PostalCodeChecksIcon from "@material-ui/icons/Map";
import PhoneIcon from "@material-ui/icons/Phone";
import validationRulesStyle from "assets/jss/validationRules";
import AddressRules from "views/address_validator/AddressRules.jsx";
import PostalCodeRules from "views/address_validator/PostalCodeRules.jsx";
import PhoneRules from "views/address_validator/PhoneRules.jsx";

import General from "helper/general.js";

const views = [
  {
    name: "addressRules",
    value: "Address Rules",
    component: AddressRules,
    icon: AlertIcon,
    condition: (brand) => true,
  },
  {
    name: "postalCodeRules",
    value: "Postal Code Rules",
    component: PostalCodeRules,
    icon: PostalCodeChecksIcon,
    condition: (brand) => brand.activated && brand.shopify_plus,
  },
  {
    name: "phoneRules",
    value: "Phone Rules",
    component: PhoneRules,
    icon: PhoneIcon,
    condition: (brand) => brand.shopify_plus,
  },
];

function ValidationRules(props) {
  const [rulesView, setRulesView] = useState(views[0].name);
  const [allowedViews, setAllowedViews] = useState([]);

  const changeRulesView = useCallback(
    (evt, view) => view && setRulesView(view),
    []
  );

  // Get the component to render
  const Cmpt = views.find((v) => v.name === rulesView).component;

  useEffect(() => {
    const brand = General.getBrandData();

    setAllowedViews(views.filter((v) => v.condition(brand)));
  }, []);

  return (
    <GridContainer justifyContent="center">
      {allowedViews.length > 1 && (
        <GridItem xs={12} style={{ marginBottom: 15 }}>
          <GridContainer justifyContent="center">
            <ToggleButtonGroup
              color="primary"
              value={rulesView}
              exclusive
              onChange={changeRulesView}
              aria-label="Rules View"
            >
              {allowedViews.map((v) => (
                <ToggleButton
                  value={v.name}
                  key={v.name}
                  color="primary"
                  classes={
                    v.name === rulesView
                      ? { selected: props.classes.toggleSelected }
                      : { root: props.classes.toggleRoot }
                  }
                >
                  {(() => {
                    const Icon = v.icon;
                    return (
                      <div style={{ marginRight: "10px", display: "flex" }}>
                        <Icon />
                      </div>
                    );
                  })()}
                  {v.value}
                </ToggleButton>
              ))}
            </ToggleButtonGroup>
          </GridContainer>
        </GridItem>
      )}

      <GridItem xs={12}>
        <Cmpt {...props} />
      </GridItem>
    </GridContainer>
  );
}

ValidationRules.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(validationRulesStyle)(ValidationRules);
