import React, { useState, useEffect, useCallback } from 'react';
import _ from 'lodash';
import General from 'helper/general.js';
import GridItem from 'components/Grid/GridItem.jsx';
import GridContainer from 'components/Grid/GridContainer.jsx';
import Card from 'components/Card/Card.jsx';
import CardHeader from 'components/Card/CardHeader.jsx';
import CardBody from 'components/Card/CardBody.jsx';
import {
  Button,
  CardActions,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Fab,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography
} from '@material-ui/core/';
import Loader from 'components/loader.jsx';
import axios from 'axios';
import DefaultCard from '@material-ui/core/Card';
import DescriptionBox from 'components/descriptionBox.jsx';
import Snackbar from '../../components/snackBar.jsx';
//styles
import { makeStyles } from '@material-ui/core/styles';
import { cardCategoryWhite, cardTitleWhite } from 'assets/jss/theme.jsx';

const useStyles = makeStyles((theme) => ({
  cardCategoryWhite,
  cardTitleWhite,
  cardDescription: {
    fontSize: '16px',
    marginBottom: '0px',
    textAlign: 'center'
  },
  center: {
    textAlign: 'center'
  },
  formControl: {
    width: '100%',
    margin: theme.spacing(1),
    minWidth: 120
  },
  warningBox: {
    borderRadius: '5px',
    marginTop: '10px',
    padding: '10px',
    border: '1px solid rgb(229, 148, 0)',
    color: 'rgb(229, 148, 0)',
    backgroundColor: 'rgb(255, 246, 229)'
  },
  root: {
    // ...theme.mixins.gutters(),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2)
  }
}));

const PhoneRules = (props) => {
  const classes = useStyles();

  const [open, setOpen] = useState(false);
  // const [message, setMessage] = useState(null);
  const [loading, setLoading] = useState(true);
  const [ruleFormMode, setRuleFormMode] = useState(null);
  const [showRuleForm, setShowRuleForm] = useState(false);
  const [newCountry, setNewCountry] = useState('');
  const [newOptions, setNewOptions] = useState(1);
  const [newRules, setNewRules] = useState({
    option_1: { length: '', rules: [] }
  });
  const [newWarning, setNewWarning] = useState('');
  const [targetRule, setTargetRule] = useState(null);
  const [deleteRuleForm, setDeleteRuleForm] = useState(false);

  const [country_phone_filters, setCountry_phone_filters] = useState(null);
  const [countriesList, setCountriesList] = useState(null);
  const [alertType, setAlertType] = useState(null);
  const [planName, setPlanName] = useState('');

  const [snackMessage, setSnackMessage] = useState('');

  //Testing Regex
  // const [selectedTestRegex, setSelectedTestRegex] = useState('[0-9]');
  // const [phoneNumber, setPhoneNumber] = useState('1 800 000 0000');
  // const [isValid, setIsValid] = useState(false);


  const loadCountryData = useCallback(() => {
    const brand = General.getBrandData();

    setLoading(true);
    setPlanName(brand.plan_name);
    console.log(planName);

    // Need to encapsulate  if(brand.activated && brand.shopify_plus) {
    axios({
      method: 'GET',
      url: `${General.serverURL}address_validator/api/admin_country_list?shop=${
        General.getBrandData().name
      }`
    })
      .then((result) => {
        let filteredCountries = _.filter(
          result.data,
          (country) =>
            _.map(
              brand.country_phone_filters,
              (string) => string.split(',')[0]
            ).indexOf(country) === -1
        );

        setLoading(false);
        setCountriesList(filteredCountries);
        setCountry_phone_filters(brand.country_phone_filters || []);
      })
      .catch((err) => 'fetch countries error');
  }, [planName]);

  //Load data
  useEffect(() => {
    setLoading(false);
    loadCountryData();
  }, [loadCountryData]);

  useEffect(() => {
    console.log('rules changed!');
    console.log(newRules);
  }, [newRules]);



  //
  // Phone
  //

  // const handleSubmit = (event) => {
  //   event.preventDefault();

  //   const phoneRegex = /^(?:(?:\+?1\s*(?:[.-]\s*)?)?(?:\(\s*([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9])\s*\)|([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9]))\s*(?:[.-]\s*)?)?([2-9]1[02-9]|[2-9][02-9]1|[2-9][02-9]{2})\s*(?:[.-]\s*)?([0-9]{4})(?:\s*(?:#|x\.?|ext\.?|extension)\s*(\d+))?$/;
  //   const re = new RegExp(selectedTestRegex);

  //   //Dynamic Regex depending on which country their users are from
  //   console.log('selectedTestRegex is...', re);
  //   console.log('testing>?', re.test(phoneNumber));

  //   setIsValid(re.test(phoneNumber));
  // };

  //
  // State
  //

  const initiateRules = function (count_new) {
    let rules = newRules;

    const count_current = _.keys(rules).length;

    if (count_new > count_current)
      for (let num = count_current + 1; num <= count_new; num++) {
        if (!rules['option_' + num])
          rules['option_' + num] = { length: '', rules: [] };
      }
    else
      for (let num = count_current; num > count_new; num--) {
        delete rules['option_' + num];
      }

    return rules;
  };

  const updateRuleLength = (index, value) => {
    let rulesObj = newRules;
    let currentRules = rulesObj['option_' + index].rules;
    const currentLength = currentRules.length;

    if (value > currentLength)
      for (let num = 0; value - currentLength > num; num++) {
        currentRules.push('');
      }
    else currentRules = currentRules.slice(0, value);

    rulesObj['option_' + index] = {
      length: value,
      rules: currentRules
    };

    // console.log("rulesObj");
    // console.log(rulesObj);

    setNewRules((currentRules) => ({ ...currentRules, ...rulesObj }));

    // console.log("value being selected)");
    // console.log(newRules["option_" + index]);
    // console.log(newRules["option_" + index].length);
  };

  const updateRule = function (option, index, value) {
    let newFormat = newRules['option_' + option];
    newFormat.rules[index] = value;

    let updatedRulesList = newRules;
    updatedRulesList['option_' + option] = newFormat;

    setNewRules((newRules) => ({ ...newRules, ...updatedRulesList }));
  };

  const editRule = function (obj) {
    const parts = obj.split(',');
    const rules = parts[1].split('|');

    console.log("here?");
    console.log(obj);
    

    let newRules = {};

    for (let count = 1; count <= rules.length; count++) {
      const ruleParts = rules[count - 1].split('_');
      let rulesArray = _.map(
        ruleParts[1]
          .replace(/]/g, '],')
          .replace(/\[/g, ',[')
          .replace(/ /g, ', ,')
          .split(','),
        (data) => {
          if (
            data.indexOf('[') === -1 &&
            data.indexOf(']') === -1 &&
            data.indexOf(' ') === -1
          )
            return data.replace(/-/g, ',-,').split(',');
          else return data;
        }
      );

      console.log("Before");
      console.log(rulesArray);


      rulesArray = rulesArray.filter((rule) => !rule.includes('&&'))
      rulesArray = rulesArray.filter((rule) => rule.includes('[')) //removes empty whitespace one - since regex value will always have a `[`
      
      console.log("after");
      console.log(rulesArray);


      rulesArray = _.filter(_.flatten(rulesArray), (data) => data !== '');

      console.log("after after");
      console.log(rulesArray);

      //Originally Saved as

      //   [
      //     "[a-zA-Z0-9-()\\s]&&",
      //     "[a-zA-Z0-9-()\\s]&&",
      //     "[a-zA-Z0-9-()\\s]&&"
      // ]
      
      //Updated as
      
      // "[a-zA-Z0-9-()\\s]"
      // "[a-zA-Z0-9-()\\s]"
      // "[a-zA-Z0-9-()\\s]"

      //Adding back the values @TODO - fix the RulesArray sort instead
      rulesArray = rulesArray.map(i => i + '&&');

      console.log(rulesArray);

      newRules['option_' + count] = {
        length: parseInt(ruleParts[0]),
        rules: rulesArray
      };
    }

    setShowRuleForm(true);
    setRuleFormMode('edit');
    setTargetRule(obj);
    setNewCountry(parts[0]);
    setNewOptions(rules.length);
    setNewWarning(parts[2]);
    setNewRules(newRules);
  };

  const saveRule = function () {
    setLoading(true);

    let rulesArray = [];
    for (let count = 1; count <= newOptions; count++) {
      const obj = newRules['option_' + count];
      rulesArray.push([obj.length + '_' + obj.rules.join('')]);
    }

    const updatedRules = _.filter(
      country_phone_filters,
      (data) => data.indexOf(newCountry + ',') !== 0
    ).concat(
      [newCountry, rulesArray.join('|'), newWarning.replace(',', '')].join(',')
    );

    const updatedCountries = _.filter(
      countriesList,
      (country) => country !== newCountry
    );

    //
    //XHR request to the server
    //

    General.setBrandValue('country_phone_filters', updatedRules, (type) => {
      setLoading(false);
      setShowRuleForm(false);
      setNewCountry('');
      setNewOptions(1);
      setNewRules({ option_1: { length: '', rules: [] } });
      setNewWarning('');
      setCountriesList(updatedCountries);
      setCountry_phone_filters(updatedRules);

      //Snackbar
      setOpen(true);
      setSnackMessage('Success!');
      setAlertType('success');
    });
  };

  const removeRule = function (rule) {
    setLoading(true);
    const updatedRules = _.filter(
      country_phone_filters,
      (entity) => entity !== rule
    );
    const updatedCountries = countriesList.concat(rule.split(',')[0]);
    General.setBrandValue('country_phone_filters', updatedRules, (type) => {
      setTargetRule(null);
      setDeleteRuleForm(false);
      setLoading(false);
      setCountriesList(updatedCountries);
      setCountry_phone_filters(updatedRules);
      setOpen(true);
      setAlertType(type);
    });
  };

  const allowSave = () => {
    let valid = true;
    for (let count = 1; count <= newOptions; count++) {
      if (
        newRules['option_' + count].rules.length === 0 ||
        newRules['option_' + count].rules.indexOf('') !== -1
      ) {
        valid = false;
        break;
      }
    }
    return valid;
  };

  const showRegex = (rule) => {
    let rules = rule.split('_')[1]
    return rules.replace(/&&/g,'');
  }

  if (loading) return <Loader />;
  else
    return (
      <div>
        <Snackbar
          alert_type={alertType}
          success_msg={snackMessage}
          close={() => {
            setOpen(false);
            setAlertType(null);
          }}
          open={open}
        />
        <GridContainer justifyContent="center">
          <GridItem xs={12} sm={12} md={10}>
            <DescriptionBox
              classes={classes}
              content="These rules ensure the phone number is in the correct format based on the country. For example, you can enforce the format of US phone numbers to be 9 digits long."
              title="What are these Phone Rules?"
            />
          </GridItem>

          {/* Country  */}
          <GridItem xs={12} sm={12} md={10}>
            <Card>
              <CardHeader color="primary">
                <h3 className={classes.cardTitleWhite}>
                  Country - Phone Rules
                </h3>
              </CardHeader>
              <CardBody className={classes.center}>
                <GridContainer justifyContent="center">
                  {country_phone_filters !== null &&
                    _.map(country_phone_filters.sort(), (obj) => (
                      <GridItem
                        xs={12}
                        sm={6}
                        md={4}
                        key={obj}
                        style={{ marginBottom: 10 }}
                      >
                        <DefaultCard className={classes.card}>
                          <CardContent>
                            <Typography variant="h5">
                              {obj.split(',')[0]}
                            </Typography>
                            <Typography component="i">
                              {obj.split(',')[2] ||
                                'Invalid phone number Format'}
                            </Typography>
                            {_.map(
                              obj.split(',')[1].split('|'),
                              (rule, index) => (
                                <div key={rule} style={{ marginTop: 15 }}>
                                  <Typography
                                    className={classes.pos}
                                    component="b"
                                  >
                                    <b>Format #{index + 1}</b>
                                  </Typography>
                                  <br />
                                  <Typography
                                    className={classes.pos}
                                    component="i"
                                  >
                                    {rule.split('_')[0]} characters long
                                  </Typography>
                                  <Typography variant="body1">
                                    {showRegex(rule)}
                                  </Typography>
                                </div>
                              )
                            )}
                          </CardContent>
                          <CardActions>
                            <Button
                              size="small"
                              style={{ margin: '0px auto' }}
                              onClick={() => editRule(obj)}
                            >
                              Edit
                            </Button>
                            <Button
                              size="small"
                              style={{ margin: '0px auto' }}
                              onClick={() => {
                                setDeleteRuleForm(true);
                                setTargetRule(obj);
                              }}
                            >
                              Remove
                            </Button>
                          </CardActions>
                        </DefaultCard>
                      </GridItem>
                    ))}
                </GridContainer>
                <Fab
                  variant="extended"
                  aria-label="Add Country Phone Rule"
                  color="primary"
                  className={classes.button}
                  onClick={() => {
                    setShowRuleForm(true);
                    setRuleFormMode('new');
                    setNewCountry('');
                    setNewWarning('');
                    setNewOptions(1);
                    setNewRules({ option_1: { length: '', rules: [] } });
                  }}
                  style={{ margin: 15 }}
                >
                  Add Country Phone Rule
                </Fab>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>

        <Dialog
          fullWidth={true}
          maxWidth="sm"
          open={deleteRuleForm}
          onClose={() => {
            setDeleteRuleForm(false);
            setTargetRule(null);
          }}
          aria-labelledby="delete-rule-form"
        >
          <DialogTitle id="delete-rule-form">Are you sure?</DialogTitle>
          <DialogContent>
            Do you want to remove the phone rules for{' '}
            <b>{targetRule ? targetRule.split(',')[0] : ''}</b>?
          </DialogContent>
          <DialogActions>
            <Button
              aria-label="Cancel"
              color="default"
              className={classes.button}
              onClick={() => {
                setDeleteRuleForm(false);
                setTargetRule(null);
              }}
              style={{ marginRight: 10 }}
            >
              No, Cancel
            </Button>
            <Fab
              variant="extended"
              aria-label="Remove"
              color="secondary"
              className={classes.button}
              onClick={() => removeRule(targetRule)}
            >
              Yes, Remove Rule
            </Fab>
          </DialogActions>
        </Dialog>
        <Dialog
          fullWidth={true}
          maxWidth="md"
          open={showRuleForm}
          onClose={() => {
            setShowRuleForm(false);
            setNewCountry('');
            setNewOptions(1);
            setNewRules({ option_1: { length: '', rules: [] } });
            setNewWarning('');
          }}
          aria-labelledby="postal-code-check-form"
        >
          <DialogTitle id="postal-code-check-form">
            {ruleFormMode === 'new'
              ? 'Add Phone Rule based on Country'
              : `Edit Phone Rule for ${newCountry} `}
          </DialogTitle>
          <DialogContent>
            <GridContainer justifyContent="center">
              {ruleFormMode === 'new' ? (
                <GridItem xs={6} sm={6} md={4}>
                  <FormControl className={classes.formControl}>
                    <InputLabel htmlFor="newCountry">Country</InputLabel>
                    <Select
                      value={newCountry}
                      onChange={(event) => setNewCountry(event.target.value)}
                    >
                      <MenuItem value={''}>
                        <em>Select Country</em>
                      </MenuItem>
                      {_.map(countriesList.sort(), (country) => (
                        <MenuItem value={country} key={country}>
                          {country}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </GridItem>
              ) : null}
              {newCountry ? (
                <GridItem xs={6} sm={6} md={5}>
                  <FormControl className={classes.formControl}>
                    <InputLabel htmlFor="newCountry">
                      How many formats are valid?
                    </InputLabel>
                    <Select
                      value={newOptions}
                      onChange={(event) => {
                        setNewOptions(event.target.value);
                        setNewRules(initiateRules(event.target.value));
                      }}
                    >
                      {_.map(_.range(1, 10), (count) => (
                        <MenuItem value={count} key={count}>
                          {count}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </GridItem>
              ) : null}
            </GridContainer>
            <GridContainer justifyContent="center">
              {newCountry ? (
                <GridItem xs={6} sm={6} md={6}>
                  <TextField
                    id="newMessage"
                    label={`Customize warning displayed when rule is violated`}
                    value={newWarning || ''}
                    onChange={(event) => setNewWarning(event.target.value)}
                    className={classes.textField}
                    margin="normal"
                    fullWidth={true}
                  />
                </GridItem>
              ) : null}
            </GridContainer>

            <GridContainer justifyContent="center">
              {newCountry
                ? _.map(_.range(1, newOptions + 1), (option) => {
                    return (
                      <GridItem
                        xs={12}
                        sm={12}
                        md={10}
                        key={option}
                        style={{ marginTop: 20 }}
                      >
                        <Typography align="center" variant="subtitle1">
                          Valid format #{option}
                        </Typography>

                        <GridContainer justifyContent="center">
                          <GridItem xs={6} sm={6} md={5}>
                            <FormControl className={classes.formControl}>
                              <InputLabel htmlFor="newLength">
                                What is the required length?
                              </InputLabel>

                              <Select
                                value={
                                  newRules['option_' + option].length || ''
                                }
                                onChange={(event, value) => {
                                  updateRuleLength(option, event.target.value);
                                }}
                              >
                                <MenuItem disabled>
                                  <em>Select Length</em>
                                </MenuItem>
                                {_.map(_.range(1, 21), (num) => (
                                  <MenuItem value={num} key={num}>
                                    {num}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </GridItem>
                          {newRules['option_' + option].length !== '' ? (
                            <GridItem xs={6} sm={6} md={5}>
                              {_.map(
                                _.range(
                                  1,
                                  newRules['option_' + option].length + 1
                                ),
                                (num) => (
                                  <FormControl
                                    className={classes.formControl}
                                    key={num}
                                  >
                                    <InputLabel htmlFor="newRules">
                                      Character #{num} Requirement
                                    </InputLabel>
                                    <Select
                                      value={
                                        newRules['option_' + option].rules[
                                          num - 1
                                        ]
                                      }
                                      onChange={(event) =>
                                        updateRule(
                                          option,
                                          num - 1,
                                          event.target.value
                                        )
                                      }
                                    >
                                      <MenuItem value="" disabled>
                                        <em>Select Rule</em>
                                      </MenuItem>

                                      <MenuItem value="[0-9]&&">Numeric</MenuItem>
                                      <MenuItem value="[-]&&">Dash</MenuItem>
                                      <MenuItem value="[+]&&">Plus</MenuItem>
                                      <MenuItem value="[\(\)]&&">Parentheses</MenuItem>
                                      <MenuItem value="[\s]&&">Space</MenuItem>
                                      <MenuItem value="[0-9-+\(\)\s]&&">
                                        Numeric, Dash, Plus, Parentheses, or Space
                                      </MenuItem>
                                    </Select>
                                  </FormControl>
                                )
                              )}
                            </GridItem>
                          ) : null}
                        </GridContainer>
                      </GridItem>
                    );
                  })
                : null}
            </GridContainer>
          </DialogContent>
          <DialogActions>
            <Button
              aria-label="Cancel"
              color="default"
              className={classes.button}
              onClick={() => setShowRuleForm(false)}
              style={{ marginRight: 10 }}
            >
              Cancel
            </Button>
            <Fab
              variant="extended"
              aria-label="Save"
              color="primary"
              className={classes.button}
              disabled={!allowSave()}
              onClick={() => saveRule()}
            >
              {ruleFormMode === 'new' ? 'Add' : 'Edit'} Phone Rule
            </Fab>
          </DialogActions>
        </Dialog>
      </div>
    );
};

export default PhoneRules;
