import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import _ from 'lodash';
import General from 'helper/general.js';
import GridItem from 'components/Grid/GridItem.jsx';
import GridContainer from 'components/Grid/GridContainer.jsx';
import Card from 'components/Card/Card.jsx';
import CardHeader from 'components/Card/CardHeader.jsx';
import CardBody from 'components/Card/CardBody.jsx';
import { FormControlLabel, Paper, Switch, TextField, Typography } from '@material-ui/core/';
import AlertsStyles from 'assets/jss/alerts.jsx';
import Loader from 'components/loader.jsx';
import DescriptionBox from 'components/descriptionBox.jsx';
import Snackbar from '../../components/snackBar.jsx';

const rules = [
  {
    title: 'Commercial vs Residential',
    description: 'Do you want to apply <i>Commercial</i> / <i>Residential</i> tags to US orders?',
    value: 'commercial_residential_check',
  },
  {
    title: 'PO Box',
    description: 'Do you want to tag PO Box orders as <i>PO Box address</i>?',
    value: 'po_box_tags',
  },
  {
    title: 'Unverifiable addresses',
    description: 'Do you want to tag orders that cannot be verified? Common examples of this include missing apt # or incorrect house number.',
    value: 'order_tag_no_suggestions',
    defaultValue: 'INVALIDADDRESS',
    labelValue: 'textTagNoSuggestions'
  },
  {
    title: 'Address, city, state or zip mismatch',
    description: 'Do you want to tag orders with address, city, state or zip code mismatch?',
    value: 'order_tag_strict_suggestions',
    defaultValue: 'INVALIDADDRESS',
    labelValue: 'textTagStrictSuggestions'
  },
  {
    title: 'Correct address',
    description: 'Do you want to tag orders with correct shipping addresses? This tag is applied when the order is created. Therefore, this tag will not be applied if the customer corrects the shipping address on the Thank You page after checkout.',
    value: 'tag_correct',
    defaultValue: 'CORRECTADDRESS',
    labelValue: 'textTagCorrectAddress'
  },
  {
    title: 'Confirmed addresses',
    description: 'Do you want to tag orders shipping addresses confirmed by the customer after checkout?',
    value: 'tag_confirmed',
    defaultValue: 'CONFIRMEDADDRESS',
    labelValue: 'textTagConfirmedAddress'
  }
];


class OrderTags extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      message: null,
      loading: true
    };
  }

  componentDidMount() {
    const brand = General.getBrandData();

    this.setState(Object.assign(brand.customization, 
      _.pick(brand, ['commercial_residential_check', 'po_box_tags', 'order_tag_no_suggestions', 'order_tag_no_suggestions_ignore_line2', 'order_tag_strict_suggestions', 'remove_tags', 'tag_confirmed', 'tag_correct']), 
      {
        precheckout_validate: brand.validate_pre_checkout || brand.precheckout_popup,
        loading: false
      }
    ));
  }

  updateState(key, value) {
    let newState = this.state;
    newState[key] = value;
    newState.open = false;
    this.setState(newState);
  }

  changeDefaultValue(key, event) {
    this.updateState(key, event.target.value);

    General.changeText(
      event,
      key, 
      (type) => this.setState({ open: true, alert_type: type })
    );
  }

  toggleAlert(key, type) {
    let newState = this.state;
    newState[key] = !newState[key];
    newState['open'] = false;
    this.setState(newState);

    General.setBrandValue(
      key,
      newState[key], 
      (type) => this.setState({ open: true, alert_type: type })
    );
  }

  generateTextField(rule) {
    if(rule.labelValue && this.state[rule.value])
      return (
        <GridItem xs={12}>
          <TextField
            id={rule.labelValue}
            label={rule.title + ' tag'}
            value={this.state[rule.labelValue] || rule.defaultValue}
            onChange={(event) => this.changeDefaultValue(rule.labelValue, event)}
            className={this.props.classes.textField}
            margin='normal'
            fullWidth={true}
            inputProps={{ maxLength: 40 }}
          />
        </GridItem>
      );
    else
      return null;
  }

  generateRuleBox(rule) {
    return (
        <GridItem xs={12} key={rule.title}>
          <Paper style={{ marginTop: 10, padding: 10 }}>
            <GridContainer>
              <GridItem xs={12} md={8}>
                <Typography variant='h6'>{rule.title}</Typography>
              </GridItem>
              <GridItem xs={12} md={4}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={this.state[rule.value]}
                      onChange={(value) => this.toggleAlert(rule.value, rule.title)}
                      color='primary'
                    />
                  }
                  label={this.state[rule.value] ? 'Yes' : 'No'}
                  style={{ float: 'right' }}
                />
              </GridItem>
              <GridItem xs={12}>
                <p 
                  className={this.props.classes.cardDescription} 
                  dangerouslySetInnerHTML={{__html: rule.description }} 
                  style={{ textAlign: 'left' }} 
                />
              </GridItem>
              {this.generateTextField(rule)}
            </GridContainer>
          </Paper>
        </GridItem>
    );
  }


  render() {
    const { classes } = this.props;

    if(this.state.loading)
      return <Loader />;
    else
    return (
      <div>
        <Snackbar
          alert_type={this.state.alert_type}
          close={() => this.setState({ open: false, alert_type: null })}
          open={this.state.open}
        />
        <GridContainer justifyContent='center'>
          <GridItem xs={12} md={10}>
            <DescriptionBox 
              classes={classes}
              content='<i>Address Validator</i> can automatically tag Shopify orders based on different conditions. The tags are applied <i>one minute</i> after an order is created.<br/><b>Please make sure the tags do not contain commas and are less than 40 characters</b>'
              title='What are order tags?'
            />
          </GridItem>
          <GridItem xs={12} md={10}>
            <Card>
              <CardHeader color='primary'>
                <h3 className={classes.cardTitleWhite}>Rules</h3>
              </CardHeader>
              <CardBody>
                <GridContainer justifyContent='center'>
                  {_.map(rules, (rule) => this.generateRuleBox(rule))}
                  <GridItem xs={12}>
                    <Paper style={{ marginTop: 10, padding: 10 }}>
                      <GridContainer>
                        <GridItem xs={12} md={8}>
                          <Typography variant='h6'>Remove invalid address tags after address update</Typography>
                        </GridItem>
                        <GridItem xs={12} md={4}>
                          <FormControlLabel
                            control={
                              <Switch
                                checked={this.state.remove_tags}
                                onChange={(value) => this.toggleAlert('remove_tags', 'remove tags')}
                                color='primary'
                              />
                            }
                            label={this.state.remove_tags ? 'Yes' : 'No'}
                            style={{ float: 'right' }}
                          />
                        </GridItem>
                        <GridItem xs={12}>
                          <p 
                            className={this.props.classes.cardDescription} 
                            dangerouslySetInnerHTML={{__html: 'Remove <i>UNVERIFIABLE ADDRESSES</i> and <i>CITY, STATE OR ZIP MISMATCH</i> tags after customer specifies a new address or confirms existing address.' }} 
                            style={{ textAlign: 'left' }} 
                          />
                        </GridItem>
                      </GridContainer>
                    </Paper>
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

export default withStyles(AlertsStyles)(OrderTags);