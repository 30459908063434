import React from 'react';
import { FormControlLabel, Switch } from '@material-ui/core/';


class Toggle extends React.Component {
  render() {
    return (
      <FormControlLabel
        control={
          <Switch
            checked={this.props.value}
            onChange={(value) => this.props.onChange()}
            color='primary'
          />
        }
        label={this.props.value ? this.props.yes_label : this.props.no_label}
      />
    );
  }
}


export default Toggle;