/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import { Switch, Route, Redirect } from 'react-router-dom';
import withStyles from '@material-ui/core/styles/withStyles';
import Routes from 'routes/festive_decorations.jsx';
import axios from 'axios';
import Header from '../components/header.jsx';
import Loader from '../components/loader.jsx';
import Sidebar from '../components/sidebar.jsx';
import General from '../helper/general.js';
import moment from 'moment';
import mainStyle from 'assets/jss/main.jsx';
import * as FullStory from '@fullstory/browser';

const festiveDecorationsPage = 'https://festivedecorationsapp.com/';

class Main extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      brand: null,
      loading: true,
      mobileOpen: false
    };
    this.resizeFunction = this.resizeFunction.bind(this);
  }
  
  resizeFunction() {
    if(window.innerWidth >= 960) {
      this.setState({ mobileOpen: false });
    }
  }

  pullBrandData(shop_name) {
    axios({
      method: 'GET',
      url: General.serverURL + 'festive_decorations/api/attributes?shop=' + shop_name,
      headers: { Authorization: `Bearer ${General.getJWT()}` }
    })
    .then((result) => {
      const brand = result.data;

      General.storeFDBrandData(brand);

      FullStory.identify(`${brand.name}.myshopify.com_festivedecorations`, {
        displayName: brand.store_name,
        email: brand.email,
        status_str: 'active',
        shopId_str: brand.name,
        planName_str: brand.plan_name,
        platform_str: 'shopify',
        appHubId_str: 'roboturk',
        appName_str: 'festivedecorations'
      });

      this.setState({ loading: false, brand: result.data });
    })
    .catch((err) => {
      console.log(err);
      console.log('brand fetch error');
      window.location = festiveDecorationsPage;
    });
  }

  componentWillMount() {
    const existingBrand = General.getFDBrandData();

    if(process.env.NODE_ENV === 'development') {
      const shop = 'letussellit';
      const jwt = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MzExLCJpYXQiOjE2NzE2NjIzMjQsImV4cCI6MTczNDc3NzUyNH0.PeVFhMuIUEZIkIsRMBDuMiu4sqs2XVhfPK0iLGg5daw'; // jwt for letussellit with 2 year expiry
      General.setJWT(jwt, shop);
      this.pullBrandData(shop);
    }

    else if(this.props.location.search) {
      const searchParams = new URLSearchParams(window.location.search);
      const shop = searchParams.get('shop').replace('.myshopify.com', '');
      const hmac = searchParams.get('hmac');

      axios({
        method: 'GET',
        url: General.serverURL + 'festive_decorations/api/fetch_dashboard_jwt?shop=' + shop + '.myshopify.com&hmac=' + hmac,
      })
      .then((result) => {
        const jwt = result.data;

        if(window.top === window.self && window.location.host !== 'localhost:3001')
          window.location = `https://${shop}.myshopify.com/admin/apps/festive-decorations`;
        else {
          General.setJWT(jwt, shop);
          this.pullBrandData(shop);
        }
      })
      .catch((err) => {
        console.log(err);
        console.log('brand fetch error');
        window.location = festiveDecorationsPage;
      });
    }

    else {
      console.log('no brand data and bad URL');
      window.location = festiveDecorationsPage;
    }
  }

  componentDidMount() {
    window.addEventListener('resize', this.resizeFunction);
  }

  componentDidUpdate(e) {
    if (e.history.location.pathname !== e.location.pathname) {
      this.refs.mainPanel.scrollTop = 0;
      if (this.state.mobileOpen) {
        this.setState({ mobileOpen: false });
      }
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resizeFunction);
  }

  render() {
    const { classes, ...rest } = this.props;

    if(this.state.loading)
      return <Loader />;
    else
      return (
        <div className={classes.wrapper}>
          <Sidebar
            routes={Routes}
            handleDrawerToggle={() => this.setState({ mobileOpen: !this.state.mobileOpen })}
            closeDrawer={() => this.setState({ mobileOpen: false })}
            open={this.state.mobileOpen}
            brand={this.state.brand}
            app='festive_decorations'
            {...rest}
          />
          <div className={classes.mainPanel} ref='mainPanel'>
            <Header
              routes={Routes}
              handleDrawerToggle={() => this.setState({ mobileOpen: !this.state.mobileOpen })}
              brand={this.state.brand}
              app='festive_decorations'
              {...rest}
            />
            <div className={classes.content}>
              <Switch>
                {Routes.map((prop, key) => (
                  (prop.redirect)
                  ?
                  <Redirect from={prop.path} to={prop.to} key={key} />
                  :
                  <Route path={prop.path} component={prop.component} key={key} />
                ))}
              </Switch>
            </div>
          </div>
        </div>
      );
  }
}

Main.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(mainStyle)(Main);
