import { cardCategoryWhite, cardTitleWhite } from 'assets/jss/theme.jsx';


const SettingsStyle = theme => ({
  cardCategoryWhite,
  cardTitleWhite,
  cardDescription: {
    fontSize: '16px',
    marginBottom: '0px'
  },
  center: {
    textAlign: 'center'
  },
  toggleContainer: {
    
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: `${theme.spacing(1)}px 0`,
    background: theme.palette.background.default,
  },
  button: {
    margin: theme.spacing(1),
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
  pausedHero: {
    padding: '20px',
    marginTop: '20px'
  }
});

export default SettingsStyle;