module.exports = (order, suggestion) => {
  const generateSpan = (attr) => order[attr] === suggestion[attr] ? '<span>' : "<span class='changed'>";

  let result = order.company ? order.company + '<br />' : '';

  // ADDRESS LINES
  // Canada: address_2 before address_1
  if(order.country === 'Canada') {
    if(suggestion.address_2)
      result += generateSpan('address_2') + suggestion.address_2 + '</span><br />';

    result += generateSpan('address_1') + suggestion.address_1 + '</span><br />';
  }
  // Other countries: address_1 before address_2
  else {
    result += generateSpan('address_1') + suggestion.address_1 + '</span><br />';

    if(suggestion.address_2)
      result += generateSpan('address_2') + suggestion.address_2 + '</span><br />';
  }

  // FORMAT CITY, STATE, ZIP
  if(['Australia', 'Canada', 'New Zealand', 'South Africa', 'United States'].indexOf(order.country) !== -1) {
    result += generateSpan('city') + suggestion.city + '</span>';
    result += ', ' + (order.state === suggestion.state ? '<span>' : "<span class='changed'>") + suggestion.state + '</span>';
    
    // Separate out 4 digits if US
    if(order.country === 'United States') {
      if(suggestion.zip === order.zip)
        result += ' <span>' + suggestion.zip + '</span>';
      else if(suggestion.zip.indexOf(order.zip) === 0) {
        result += ' <span>' + suggestion.zip.substring(0, order.zip.length) + "</span><span class='changed'>" + suggestion.zip.substring(order.zip.length, suggestion.zip.length) + '</span>';
      }
      else
        result += " <span class='changed'>" + suggestion.zip + '</span>';
    }
    else
      result += ' ' + generateSpan('zip') + suggestion.zip + '</span>';
  }
  else if(order.country === 'Brazil') {
    result += generateSpan('city') + suggestion.city + '</span> - ' + order.state;
    result += '<br />' + generateSpan('zip') + suggestion.zip + '</span>';
  }
  else if(['Austria', 'Belgium', 'Croatia', 'Czech Republic', 'Denmark', 'Estonia', 'Finland', 'France', 'Germany', 'Greece', 'Hungary', 'Netherlands', 'Norway', 'Poland', 'Slovakia', 'Slovenia', 'Sweden', 'Switzerland'].indexOf(order.country) !== -1) {
    result += generateSpan('zip') + suggestion.zip + '</span>';
    result += ' ' + generateSpan('city') + suggestion.city + '</span>';
  }

  else if(['Italy', 'Portugal', 'Spain'].indexOf(order.country) !== -1) {
    result += generateSpan('zip') + suggestion.zip + '</span>';
    result += ' ' + generateSpan('city') + suggestion.city + '</span>';
    result += ' ' + (order.state === suggestion.state ? '<span>' : "<span class='changed'>") + suggestion.state + '</span>'; 
  }

  else if(order.country === 'Mexico') {
    result += generateSpan('zip') + suggestion.zip + '</span>';
    result += ' ' + generateSpan('city') + suggestion.city + '</span>, ';
    result += (order.state === suggestion.state ? '<span>' : "<span class='changed'>") + suggestion.state + '</span>'; 
  }

  else if(['United Kingdom'].indexOf(order.country) !== -1) {
    result += generateSpan('city') + suggestion.city + '</span><br />';
    result += generateSpan('zip') + suggestion.zip + '</span>';
  }

  return result + '<br />' + order.country;
};